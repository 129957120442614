/// all the store logic and listeneres are here. The rest are just dumb Components. Makis was here. 2019
import "./scss/font.scss"
import "./scss/base.scss"
import React, { useState } from "react"
import ReactGA from "react-ga4"
import AppSumoFlow, { clearAppSumoDetails } from "./promos/AppSumoFlow"
import Badge from "@material-ui/core/Badge"
import cx from "classnames"
import Loadable from "react-loadable"
import { toast, ToastContainer } from "react-toastify"
import IconLeave from "./comp/icons/IconLeave"
import flatMap from "lodash/flatMap"
import uniqBy from "lodash/uniqBy"
import { BsCalendar4Week } from "react-icons/bs"
import get from "lodash/get"
import forIn from "lodash/forIn"
import pick from "lodash/pick"
import differenceBy from "lodash/differenceBy"
import BarLoader from "react-spinners/BarLoader"
import update from "immutability-helper"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { eachMonthOfInterval } from "date-fns/eachMonthOfInterval"
import { startOfISOWeek } from "date-fns/startOfISOWeek"
import apiFile from "./services/file/file.api"
import { logError } from "./services/logging.service"
import IconSpeed from "./comp/icons/IconSpeed"
import Home from "./section/Home"
import Board from "./section/Board"
import MxAdmin from "./comp/MxAdmin"
import { BsReceipt } from "react-icons/bs"
import FullScreen from "./comp/FullScreen"

import { motion, AnimatePresence } from "framer-motion"
import LicenseChecker from "./comp/LicenseChecker"
import { CheckBox } from "./comp/CheckBoxes"
import history, { addParam, addParams, removeParams, removeParam, getQ, getHash } from "./comp/History"
import { getStartHour, dateValidationPass, getStaleDate, getCompleteBufferDate } from "./utils/dates"
import { ensureNumber, isMongoId, darkThemes } from "./utils/utils"
import { getSimpleAction } from "./utils/actionItem"
import IconMsgs from "./comp/icons/IconMsgs"
import IconChevron from "./comp/icons/IconChevron"
import IconBoard from "./comp/icons/IconBoard"
import Tip from "./comp/Tip"
import Confirm from "./comp/Confirm"
import Sonar from "./comp/Sonar"
import Card from "./comp/Card"
import BlurHandler from "./comp/BlurHandler"
import isEmail from "validator/lib/isEmail"
import isMobilePhone from "validator/lib/isMobilePhone"

import { startOfMonth } from "date-fns/startOfMonth"
import { isThisWeek } from "date-fns/isThisWeek"

import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { endOfDay } from "date-fns/endOfDay"
import { isBefore } from "date-fns/isBefore"
import { isSaturday } from "date-fns/isSaturday"
import isSunday from "date-fns/isSunday"
import { isSameMonth } from "date-fns/isSameMonth"
import { isAfter } from "date-fns/isAfter"
import startOfDay from "date-fns/startOfDay"
import endOfMonth from "date-fns/endOfMonth"
import addMonths from "date-fns/addMonths"
import addDays from "date-fns/addDays"
import nextMonday from "date-fns/nextMonday"
import nextTuesday from "date-fns/nextTuesday"
import { toZonedTime } from "date-fns-tz"
import { getMissingTimesheets } from "./utils/timesheets"
import { getTimeUtc } from "./utils/dates"
import { parse, stringify } from "flatted"
import { canAccessOrgSpace, assignPersonToRole } from "./comp/OrgUtils"

import { getRole } from "./comp/OrgGetRole"

import authService, { getUserRequestId } from "./services/auth/auth.service"

//IMPORT APIS

import apiAuth from "./services/auth/auth.api"
import apiAction from "./services/action/action.api"
import apiActionItem from "./services/actionItem/actionItem.api"

import apiOrg from "./services/org/org.api"
import apiMission from "./services/mission/mission.api"
import apiPlanItems from "./services/planItems/planItems.api"
import apiNotification from "./services/notification/notification.api"
import apiUnsubscribe from "./services/unsubscribe/unsubscribe.api"

import apiTrello from "./services/trello/trello.api"
import apiTemplate from "./services/template/template.api"

import "react-toastify/dist/ReactToastify.css"
import { IoClose } from "react-icons/io5"

import {
    PiCubeDuotone,
    PiGearDuotone,
    PiCopySimpleDuotone,
    PiShootingStarDuotone,
    PiTriangleDuotone,
    PiHouseLight,
} from "react-icons/pi"

import {
    getActionItemStatus,
    existsTimeTracking,
    getMissionPerson,
    getAllActions,
    getAllActionItems,
} from "./comp/MissionUtils"

import firebase from "./services/firebase.service"

import mojs from "@mojs/core"
import Drive from "./drive/Drive"
import { getObjectId, getRefId } from "./utils/utils"

import { getFullName } from "./comp/PeopleUtils"
import PanelNotification, { checkForClientPrograms } from "./comp/PanelNotification"
import ErrorBoundary from "./ErrorBoundary"
import { hasAppReconnected, unmountAppReconnect } from "./services/app-reconnect.service"
import { subMonths } from "date-fns"

import { v4 as uniqid } from "uuid"
import { quickbooks } from "./services/quickbook"
import { delAllCache, delAllExpiredCache } from "./utils/cache"
import { revokeCsrf, revokeSessionCookie, setCsrf, setSessionCookie } from "./services/session/session.api"

//import { microsoftLoginPopup, microsoftLoginSilent } from "./services/graph"

const axios = require("axios")

//CODE-SPLIT SOME MODULES

const Action = Loadable({
    loader: () => import("./module/Action.jsx"),
    loading: () => <span />,
})
const ActionItem = Loadable({
    loader: () => import("./comp/ActionItem"),
    loading: () => <span />,
})
const CrudMission = Loadable({
    loader: () => import("./module/CrudMission"),
    loading: () => <span />,
})
const CreateMission = Loadable({
    loader: () => import("./comp/CreateMission"),
    loading: () => <span />,
})
const CreateBid = Loadable({
    loader: () => import("./comp/CreateBid"),
    loading: () => <span />,
})
const Power = Loadable({
    loader: () => import("./section/Power"),
    loading: () => <span />,
})

const SimplyX = Loadable({
    loader: () => import("./section/SimplyX"),
    loading: () => <span />,
})

const MxGanttMission = Loadable({
    loader: () => import("./section/MxGanttMission"),
    loading: () => <span />,
})

const BudgetPro = Loadable({
    loader: () => import("./comp/BudgetPro"),
    loading: () => <span />,
})

const Calendar = Loadable({ loader: () => import("./comp/Calendar"), loading: () => <span /> })

const MySpace = Loadable({
    loader: () => import("./section/MySpace"),
    loading: () => <span />,
})
const Phase = Loadable({
    loader: () => import("./module/Phase"),
    loading: () => <span />,
})

const BudgetSetup = Loadable({
    loader: () => import("./comp/BudgetSetup"),
    loading: () => <span />,
})

const Org = Loadable({
    loader: () => import("./module/Org"),
    loading: () => <span />,
})
const CrudOrg = Loadable({
    loader: () => import("./module/CrudOrg"),
    loading: () => <span />,
})

const Login = Loadable({
    loader: () => import("./section/Login"),
    loading: () => <span />,
})
const ControlPanel = Loadable({
    loader: () => import("./comp/ControlPanel"),
    loading: () => <span />,
})

const Navigator = Loadable({
    loader: () => import("./comp/Navigator"),
    loading: () => <span />,
})

const TimeTracking = Loadable({ loader: () => import("./section/TimeTracking"), loading: () => <span /> })

const CreateTemplate = Loadable({
    loader: () => import("./comp/CreateTemplate"),
    loading: () => <span />,
})

const LeaveTracker = Loadable({ loader: () => import("./section/LeaveTracker"), loading: () => <span /> })

const ExpenseTracker = Loadable({ loader: () => import("./comp/ExpenseTracker"), loading: () => <span /> })
const Wfh = Loadable({
    loader: () => import("./comp/Wfh"),
    loading: () => <span />,
})
const CIRCLE_OPTS = {
    fill: "none",
    className: "mo-js-shape",
    radius: 25,
    strokeWidth: { 50: 0 },
    scale: { 0: 1 },
    duration: 500,
    left: 0,
    top: 0,
    easing: "cubic.out",
}

const missionTreeConfig = { isActive: true, staleDate: getStaleDate(), completedBufferDate: getCompleteBufferDate() }

class App extends React.Component {
    constructor(props) {
        super(props)
        const me = this

        this.msgBeep = React.createRef()

        const qHash = getHash()
        const q = {
            ...getQ(),
            ...qHash,
        }

        this.fetchedOrgs = {}
        this.isLaunched = true

        // Hygiene

        delAllExpiredCache().catch(() => {
            // Nothing
        })

        removeParams(
            "hq,mc-org,diagnostic,mc-org-goal,make-template,create-proposal,myspace,mc,change-date,change-domains,hq-creating,mc-prop,org-cfg,oc-domains,create-mission,leave-notice,invite-notice,"
        )

        // END Hygiene

        this.hasUnmounted = false
        this.hasAppReconnectedSocket = null

        this.state = {
            avKey: uniqid(),

            orgsFetchedData: [],
            person: {},
            orgs: [],
            missions: [],
            showLeaveTracker: false,
            showExpenseTracker: false,
            phases: [],
            actions: [],

            missionChange: {},
            orgChange: {},
            fileSelected: null,
            fileSelectedList: null,
            notificationList: [],
            notificationUnread: 0,
            notificationOpen: false,
            mfaDisabledMissions: [],
            mfaDisabledOrgs: [],
            mfaRequiredMsg: false,
            activityList: [],
            helpIndex: 1,
        }

        switch (q["mode"]) {
            // Firebase
            case "resetPassword":
                me.flowResetPassword()
                break
            case "recoverEmail":
                me.flowRecoverEmail()
                break
        }

        this.actionItemTO = 0

        firebase.auth().onIdTokenChanged(async (user) => {
            // User is signed in, signed out or token was refreshed.
            try {
                if (user) {
                    await setCsrf()
                    await setSessionCookie()
                } else {
                    // Cannot revoke, user already logged out
                }
            } catch (err) {}
        })

        firebase.auth().onAuthStateChanged(async (authorized) => {
            if (authorized) {
                setCookie("mission-x-active-login", "true", 60)
                if (me.preAuthHistoryListener) me.preAuthHistoryListener()
                if (!this.hasAppReconnectedSocket) {
                    // Listen only once
                    this.hasAppReconnectedSocket = hasAppReconnected((offlineMs) => {
                        // Don't run the callback if component has been unmounted
                        if (!this.hasUnmounted) {
                            try {
                                if (offlineMs >= 300000) {
                                    console.log("Sockets were disconnected for about " + offlineMs / 1000 + "s.")
                                    me.reBuildState()
                                }
                            } catch (err) {
                                console.error("Failed to update state", err)
                            }
                        }
                    })
                }

                if (process.env.REACT_APP_ENV === "prod") {
                    ReactGA.initialize("G-GNWFCEYWW1")
                }

                this.setState({
                    multiFactor: authorized.multiFactor.enrolledFactors[0],
                })

                switch (q["mode"]) {
                    // Firebase
                    case "verifyEmail":
                        me.flowVerifyEmail()
                        break
                    case "verifyAndChangeEmail":
                        me.flowVerifyAndChangeEmail()
                        break
                    case "verifyEmailSecondary":
                        me.flowVerifyEmailSecondary()
                        break
                    case "unsubscribe":
                        me.flowUnsubscribe()
                        break
                }

                if (q["diagnostic"]) {
                    this.setState(
                        {
                            error: "true",
                        },
                        () => {}
                    )
                }

                if (q.intuit === "authCallback") {
                    if (q.error) {
                        switch (q.error) {
                            case "access_denied":
                                toast.error(q.error)
                                break
                            default:
                                toast.error(q.error)
                        }
                    } else {
                        if (q.product === "online") {
                            await quickbooks
                                .api(JSON.parse(decodeURIComponent(q.state)).orgId)
                                .online.authToken({
                                    code: q.code,
                                    realmId: q.realmId,
                                })
                                .then(() => {
                                    window.location.replace(
                                        `/board?org=${JSON.parse(decodeURIComponent(q.state)).orgId}`
                                    )
                                    toast.success("Quickbooks has been setup successfully")
                                })
                                .catch((err) => {
                                    window.location.replace(
                                        `/board?org=${JSON.parse(decodeURIComponent(q.state)).orgId}`
                                    )
                                    toast.error(err.message, { autoClose: 6000 })
                                })
                        } else {
                            toast.error(`Quickbooks product ${q.product ?? ""} is not supported. Please contact us.`)
                        }
                    }
                } else if (q.trello === "authCallback" && q.token) {
                    // Leave "q.trello" to open Kanban dialog
                    removeParams("expiration,token", true)
                    await apiTrello
                        .handleAuth(q.mission, q.token, q.expiration)
                        .finally(() => me.personAuthInit())
                        .catch((err) => toast.warn(err.message))
                } else if (q.token && q.from === "orgInvite") {
                    await apiOrg
                        .handleInvite(q.token)
                        .finally(() => me.personAuthInit())
                        .catch((err) => {
                            if (err.code !== "invite/active-directory") {
                                toast.warn(err.message)
                            }
                        })
                } else if (q.token && q.from === "invite") {
                    await apiMission
                        .handleInvite(q.token)
                        .finally(() => me.personAuthInit())
                        .catch((err) => {
                            if (err.code !== "invite/active-directory") {
                                toast.warn(err.message)
                            }
                        })
                } else {
                    await me.personAuthInit()
                }

                if (q["from"] === "mentionEmail" && !!q["room"] && !!q["roomName"]) {
                    const params = []
                    if (q["roomName"] === "ActionItem") {
                        params.push({ param: "action-item", value: q["room"] })
                    }
                    removeParams(["from", "room", "roomName"].join(","))
                    addParams(params)
                }

                me.loadAll()
                me.authHistoryListener = history.listen((location, action) => me.routing())
            } else {
                if (!window.location.pathname.includes("/login")) {
                    window.location = "/login" + window.location.search
                }

                delAllCache().catch(() => {
                    // Nothing
                })

                if (me.authHistoryListener) me.authHistoryListener()

                unmountAppReconnect()
                this.hasAppReconnectedSocket = null

                me.preAuthHistoryListener = history.listen((location, action) => me.preAuthRouting())

                me.setState({
                    login: true,
                })
                if (q["mode"] && (q.mode === "verifyEmail" || q.mode === "verifyEmailSecondary")) {
                    this.xqTO = setTimeout(() => {
                        toast("Awesome! Let's login and get you verified. 🦋", {
                            autoClose: 8000,
                        })
                    }, 600)
                }
            }
        })

        //Refs for the AI
    }

    setLocal = () => {
        const me = this

        axios
            .get("https://api.bigdatacloud.net/data/ip-geolocation?key=759b615e643048409fc401ffc9a67b46")
            .then((res) => {
                const d = res.data
                const p = me.state.person

                if (
                    d.country.isoAlpha2 === p.country &&
                    d.location.city === p.city &&
                    d.location.principalSubdivision === p.state
                ) {
                    toast("Your location appears the same. Please make sure you are not using a VPN.")
                    return
                }

                me.personUpdate({
                    country: d.country.isoAlpha2,
                    city: d.location.city,
                    state: d.location.principalSubdivision,
                    timezone: d.location.timeZone.ianaTimeId,
                }).then((r) => {})
            })
    }
    silentFail(error) {
        clearTimeout(this.silentFailTo)
        this.silentFailTo = setTimeout(() => {
            logError(error)
        }, 100)
    }
    loadLatestNotifications() {
        const orpahnCheck = checkForClientPrograms(this)
        apiNotification
            .getNotificationUnreadCount()
            .then((res) => {
                apiNotification.getNotificationList({ limit: Math.max(res.count, 300) }).then((list) => {
                    this.setState({
                        notificationList: list,
                        notificationUnread:
                            (res?.count || 0) + orpahnCheck.orphans + ensureNumber(this.state.missingTimesheets),
                        theNotificationsHaveLanded: true,
                    })
                })
            })
            .catch(() => {
                this.setState({
                    theNotificationsHaveLanded: true,
                })
            })
    }
    refuseCookies() {
        this.setState({
            showCookies: false, //this is just to show the cookie banner
        })
        localStorage.setItem(
            "dna-accept-cookie",
            JSON.stringify({
                rejected: true,
            })
        )
    }
    acceptCookies() {
        this.setState({
            showCookies: false,
        })

        localStorage.setItem(
            "dna-accept-cookie",
            JSON.stringify({
                accepted: true,
            })
        )
    }
    openExpensePanel() {
        this.setState({
            showExpenses: true,
        })
    }
    initialStateLogic(q) {
        const me = this

        let state = {
            missions: [],
            phases: [],
            actions: [],
            person: {},
        }

        return state
    }
    flowResetPassword() {
        const q = getQ()
        const me = this

        apiAuth
            .signOut()
            .then(() => {
                apiAuth
                    .verifyPasswordResetCode(q.oobCode)
                    .then((res) => {
                        me.tempResetEmail = res
                        me.tempResetCode = q.oobCode

                        me.setState({
                            login: true,
                        })
                    })
                    .catch((err) => {
                        toast.error("🧐 So sorry but: " + err.message, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 6000,
                        })

                        history.push("/?login=open")

                        me.setState({
                            resetLoginStates: new Date().getTime(),
                            login: true,
                        })

                        me.forceUpdate()
                    })
            })
            .catch((err) => {
                throw err
            })
    }
    flowVerifyEmail() {
        const q = getQ()
        const { oobCode } = q

        if (!oobCode) {
            return
        }

        const me = this

        apiAuth
            .verifyEmail(oobCode)
            .then((person) => {
                toast.success("Congratulations. You're now verified!", {
                    autoClose: 5000,
                })

                me.setState({
                    person: update(this.state.person, {
                        emailVerified: { $set: person.emailVerified },
                        emailList: { $set: person.emailList },
                    }),
                })

                let toInvite = localStorage.getItem("dna-to-invite")

                if (toInvite) {
                    toInvite = JSON.parse(toInvite)?.toInvite || []
                } else {
                    toInvite = []
                }

                if (toInvite.length) {
                    localStorage.setItem(
                        "dna-to-invite",
                        JSON.stringify({
                            toInvite: [],
                        })
                    )

                    const metadata = {
                        config: {
                            permission: 2,
                        },
                        message: "Help manage our missionX workspace.",
                    }

                    toInvite.forEach((ti, i) => {
                        if (this.state.orgs.find((o) => o._id === ti.org))
                            this.orgInvitePerson(ti.org, ti.email, metadata, null, true)
                    })
                }
            })
            .finally(() => removeParams("oobCode,apiKey,mode,lang"))
            .catch((err) => {
                setTimeout(() => {
                    switch (err.code) {
                        case "auth/invalid-action-code":
                            return toast.error(
                                "Sorry this validation link is no longer valid. Please check your email for a more recent link.",
                                { autoClose: 6000 }
                            )
                        case "auth/expired-action-code":
                            return toast.error(
                                "The verification email has expired. Sorry please request a new one. My Space... icon top right.",
                                { autoClose: 6000 }
                            )
                        case "email_verify_unexpected":
                            return toast.error(err.message)
                        case "auth/argument-error":
                            return
                        default:
                            toast.error(
                                "Failed to verify email. The code may be invalid (new one sent) Contact us if you did not receive a new email: help@missionx.ai",
                                {
                                    autoClose: 8000,
                                }
                            )
                    }
                }, 1000)
            })
    }
    flowVerifyAndChangeEmail() {
        const q = getQ()
        const { oobCode } = q

        if (!oobCode) {
            return
        }

        const me = this

        apiAuth
            .verifyAndUpdateEmail(oobCode)
            .then(({ previousEmail, newEmail }) => {
                me.setState({
                    person: update(this.state.person, {
                        email: newEmail,
                        emailVerified: { $set: true },
                    }),
                })

                addParam("myspace", "open")
                toast.success(
                    `🚀 Congratulations. Your email was verified and successfully changed from "${previousEmail}" to "${newEmail}"!`,
                    {
                        autoClose: 8000,
                    }
                )
            })
            .finally(() => removeParams("oobCode,apiKey,mode,lang"))
            .catch((err) => {
                switch (err.code) {
                    case "auth/expired-action-code":
                        return toast.error(
                            "The verification email has expired. Sorry please try changing your email again from My Space...",
                            { autoClose: 6000 }
                        )
                    case "auth/user-token-expired":
                        return setTimeout(() => {
                            toast("Redirecting you to re-authenticate", {
                                autoClose: 2000,
                            })
                            setTimeout(() => {
                                window.location.href = "/"
                            }, 2000)
                        }, 1000)
                    default:
                        toast.error(`${err.message}. Maybe try again or contact us: help@missionx.ai`, {
                            autoClose: 10000,
                        })
                }
            })
    }
    flowVerifyEmailSecondary() {
        const q = getQ()
        const { oobCode } = q

        if (!oobCode) {
            return
        }

        apiAuth
            .emailListVerify(oobCode)
            .finally(() => removeParams("oobCode,mode,apiKey,lang"))
            .then((profile) => {
                toast.success("🚀 Nicely done Your email has been verified!", {
                    autoClose: 5000,
                })

                this.setState({
                    person: update(this.state.person, {
                        emailList: { $set: profile.emailList },
                    }),
                })
            })
            .catch((err) => {
                toast.error(err.message, {
                    autoClose: 6000,
                })
            })
    }
    flowRecoverEmail() {
        const q = getQ()
        const { oobCode } = q

        if (!oobCode) {
            return
        }

        apiAuth
            .recoverEmail(oobCode)
            .finally(() => removeParams("oobCode,apiKey,mode,lang"))
            .then(({ recoveryEmail }) => {
                if (recoveryEmail) {
                    this.setState({
                        person: update(this.state.person, {
                            email: { $set: recoveryEmail },
                        }),
                    })
                }
                toast.success(`🚀 Yeahy! Your email "${recoveryEmail || ""}" has been recovered!`, {
                    autoClose: 5000,
                })
            })
            .catch((err) => {
                switch (err.code) {
                    case "auth/user-token-expired":
                        history.push("/?login=open")
                        this.setState({
                            login: true,
                        })
                        return toast.success(
                            "🚀 Yeahy! Your email has been recovered! Please login again to continue.",
                            {
                                autoClose: 6000,
                            }
                        )
                    default:
                        toast.error(err.message, {
                            autoClose: 6000,
                        })
                }
            })
    }
    /**
     * TODO: Make email unsubscribe more robust.
     * Maybe confirm and provide a way to subscribe back.
     */
    flowUnsubscribe() {
        const q = getQ()
        if (apiUnsubscribe[q.from] && q.token) {
            apiUnsubscribe[q.from](q.token)
                .finally(() => history.push("/"))
                .then(() => toast.success("Unsubscribed successfully!", { autoClose: 5000 }))
                .catch((err) => toast.error(err.message, { autoClose: 6000 }))
        }
    }
    claimAppSumo() {
        const flowOkToStart = ({ code, res }) => {
            this.setState({
                appsumo: res,
                appSumoOobCode: code,
            })
        }

        const stachedCode = localStorage.getItem("mx-app-sumo-oobCode-" + this.state.person._id)

        const oobCode = JSON.parse(stachedCode)?.oobCode

        if (oobCode) {
            apiOrg
                .getAppSumoByOobCode(oobCode)
                .then((res) => {
                    if (!this.state.person.emailList.find((ob) => ob.email === res.activation_email)) {
                        apiAuth
                            .emailListAdd(res.activation_email)
                            .then((updatedProfile) => {
                                toast.success(`Great! Please check your email to verify ${res.activation_email}`)
                                this.personUpdate({
                                    emailList: updatedProfile.emailList,
                                })

                                flowOkToStart({ oobCode, res })
                            })
                            .catch((err) => {
                                toast(
                                    `So sorry, but the AppSumo activation email account ${res.activation_email} appears to be in use. Please logout and login using that email to claim your package!`,
                                    { autoClose: false }
                                )

                                clearAppSumoDetails({ app: this })
                            })
                    } else {
                        flowOkToStart({ oobCode, res })
                    }
                })
                .catch((err) => {
                    removeParams("from,oobCode")
                    clearAppSumoDetails({ app: this })

                    this.silentFail("oobCOde::" + (err?.message + " - " + err?.toString()))

                    if (!toast.isActive("summoKiller1")) {
                        toast.warning(
                            "🐋 Oh no! Terribly sorry but there was an issue verifying your AppSumo package. Please try the link again from AppSumo or contact us: help@missionx.ai",
                            {
                                autoClose: false,
                                toastId: "summoKiller1",
                            }
                        )
                    }
                })
        } else {
            this.silentFail("oobCOdeStache" + stachedCode)
            clearAppSumoDetails({ app: this })
            toast.warning(
                "🐳 Sorry but it appears that I cannot authorize this actvation. Please try following your AppSumo activation link again and please let us know in the chat or email us help@missionx.ai",
                {
                    autoClose: false,
                }
            )
        }
    }
    setPersonalSetting(obj) {
        let d = this.state.person.appSettings || {}
        d = { ...d, ...obj }

        this.personUpdate({ appSettings: d })
    }
    setUiSeenFlags(param, val, local) {
        let newArr = this.state.person.uiMessageFlags || []
        if (!newArr.includes(param)) {
            if (val) {
                newArr.push(param + "-" + val)
            } else {
                newArr.push(param)
            }
            this.personUpdate({ uiMessageFlags: newArr })
        }
        if (local) localStorage.setItem(param, true)
    }
    hasSeenMsg(param) {
        return this.state.person.uiMessageFlags?.find((d) => d === param) || localStorage.getItem(param)
    }
    storeAppSumoDetails() {
        const q = getQ()
        let code = q.oobCode
        let newArr = this.state.person.uiMessageFlags || []

        if (!newArr.includes("appsumo")) {
            newArr.push("appsumo")

            this.personUpdate({ uiMessageFlags: newArr })
        }

        localStorage.setItem(
            "mx-app-sumo-oobCode-" + this.state.person._id,
            JSON.stringify({
                oobCode: code,
            })
        )
    }

    inLogic() {
        //Where to go after signin logic

        const section = history.location.pathname.replace("/", "")
        const q = getQ()

        if (q.from === "appsumo") this.storeAppSumoDetails()

        this.setState({
            login: false,
        })

        if (
            this.state.person._id &&
            (!this.state.person.avatar || !this.state.person.firstName || !this.state.person.lastName)
        ) {
            history.push("/home")
            addParam("myspace", "open")

            this.setState({
                myspace: true,
                login: false,
            })
        } else if (section === "" || section === "login") {
            history.push("/home")
        } else {
            removeParam("login")
        }

        if (!this.state.person.city || !this.state.person.country || !this.state.person.state) {
            this.setLocal()
        }
    }
    loadAll() {
        SimplyX.preload()
        Power.preload()
        MxGanttMission.preload()
        this.setState({
            showAll: true,
        })
    }
    resetEntryStates() {
        setTimeout(() => {
            this.setState({
                resetLoginStates: new Date().getTime(),
            })
        }, 500)
    }

    componentWillUnmount() {
        clearInterval(this.activityInterval)
        unmountAppReconnect()

        this.hasUnmounted = true
        this.hasAppReconnectedSocket = null
    }
    cookieLogic() {
        let accpt = JSON.parse(localStorage.getItem("dna-accept-cookie"))

        if (accpt && accpt.accepted) {
            if (!window.location.href.includes("localhost")) {
                ReactGA.initialize("G-GNWFCEYWW1")
            }
        } else {
            axios
                .get("https://api.bigdatacloud.net/data/ip-geolocation?key=759b615e643048409fc401ffc9a67b46")
                .then((res) => {
                    if (res.data.location.continentCode === "EU") {
                        this.setState({
                            showCookies: true,
                        })
                    } else {
                        this.acceptCookies()
                        ReactGA.initialize("G-GNWFCEYWW1")
                    }
                })
                .catch((msg) => {
                    this.setState({
                        showCookies: true,
                    })
                })
        }
    }
    componentDidMount() {


        this.mainCircle = new mojs.Shape({
            ...CIRCLE_OPTS,
            stroke: "orange",
        })

        this.xBurstShape = new mojs.Burst({
            radius: { 0: 120 },
            count: 5,
            duration: 100,
            className: "mo-js-shape",
            strokeWidth: 1,
            left: 0,
            top: 0,
            children: {
                fill: { cyan: "yellow" },
            },
        })

        this.smallCircles = []
        const colors = ["orange", "red", "gold", "#cc0000"]

        for (let i = 0; i < 4; i++) {
            this.smallCircles.push(
                new mojs.Shape({
                    ...CIRCLE_OPTS,
                    parent: this.mainCircle.el,
                    className: "mo-js-c",
                    strokeWidth: { 30: 0 },
                    left: 0,
                    top: 0,
                    stroke: colors[i % colors.length],
                    delay: "rand(0, 350)",
                    x: "rand(-50, 150)",
                    y: "rand(-50, 150)",
                    radius: "rand(5, 20)",
                })
            )
        }

        this.doneBurst = new mojs.Burst({
            radius: { 0: 500 },
            //parent : this.element,
            left: 0,
            top: 0,
            className: "mo-js-shape",
            count: 20,
            children: {
                scale: { 12: 0 },
                shape: "polygon",
                points: 6,
                fill: { lime: "cyan" },
                angle: { 360: 0 },
                duration: 900,
                delay: "stagger( rand(0, 50) )",
            },
        })

        Login.preload()


    }
    circleBurst(left, top) {
        this.mainCircle.tune({ x: left, y: top }).replay()

        for (let i = 0; i < this.smallCircles.length; i++) {
            this.smallCircles[i].generate().replay()
        }
    }
    xBurst(left, top) {
        this.xBurstShape.tune({ x: left, y: top }).replay()
    }
    preAuthRouting() {
        const q = getQ()

        const section = window.location.pathname.split("/")[1]

        if (q.from === "appsumo" && !this.state.login) {
            this.setState({
                login: true,
            })
        } else if ((section === "board" || section === "home" || q.mission) && !q.login) {
            history.push("/")

            addParam("login", "open")

            this.setState({
                login: true,
            })
        } else if (q.login || q.mode) {
            this.setState({
                login: true,
            })
        } else if (q.mode === "verifyEmail") {
            this.setState({
                login: true,
            })
        } else {
            this.setState({
                login: undefined,
            })
        }
    }
    routing() {
        const me = this
        const q = getQ()

        const loc = history.location
        let section =
            loc && loc.pathname
                ? loc.pathname.replace("/", "") === ""
                    ? "login"
                    : loc.pathname.replace("/", "")
                : "login"

        if (
            this.state.person._id &&
            (!this.state.person.avatar || !this.state.person.firstName || !this.state.person.lastName)
        ) {
            return
        }

        if (!q["action-item"] && this.state.showDetailsData) {
            this.setState({
                showDetailsData: null,
                showDetailsDataOptions: null,
            })
        } else if (
            q["action-item"] &&
            this.state.showDetailsData &&
            q["action-item"] !== this.state.showDetailsData._id
        ) {
            this.showActionItemDetails(q["action-item"])
        }

        if (this.theMissionsHaveLanded) {
            const missionIndex = q.mission ? me.state.missions.findIndex((m) => m._id === q.mission) : -1
            const myMission = me.state.missions[missionIndex]

            const phaseObj = q.phase ? myMission.planItems.find((p) => p._id === q.phase) : null
            let actionObj
            if (q.action) {
                actionObj = getAllActions(myMission).find((a) => a._id === q.action)
            }

            const orgIndex = q.org ? this.state.orgs.findIndex((o) => getObjectId(o) === q.org) : -1

            if (q.action && !actionObj) {
                toast.error("Sorry that action wasn't found. Maybe removed?")
                removeParam("action")
                return
            }
            if (q.phase && !phaseObj) {
                toast.error("Sorry that backlog wasn't found. Maybe removed?")
                removeParam("phase")
                return
            }

            if (q.mission && missionIndex === -1) {
                this.dblCheck = true
                this.setState(
                    {
                        avKey: uniqid(),
                    },
                    () => {
                        this.forceUpdate()
                        const missionIndex = q.mission ? me.state.missions.findIndex((m) => m._id === q.mission) : -1
                        if (missionIndex === -1) {
                            this.dblCheck = false
                            if (!this.state.org) {
                                toast.error("Sorry that mission wasn't found. Maybe removed?")
                            }
                            removeParams("mission,mission-tab,mc")
                            return
                        } else {
                            this.dblCheck = true
                        }
                    }
                )

                if (!this.dblCheck) return
            }

            if (q.org && orgIndex === -1 && !q.hq) {
                toast.error("Sorry that Organization wasn't found. Maybe removed?")
                removeParams("org,org-tab,hq")
                return
            }

            this.setState({
                action: q.action,
                org: q.org,
                mission: q.hq || q["create-mission"] ? null : q.mission, //&& !q.action ? q.mission: undefined,
                phase: q.hq || q["create-mission"] ? null : q.phase, //&& !q.action ? q.phase: undefined,
                missionIndex: missionIndex !== -1 ? missionIndex : undefined,
                phaseObj: phaseObj,
                actionObj: actionObj,
                orgIndex: orgIndex !== -1 ? orgIndex : undefined,
                missionCrud: q.mc,
                createMission: q["create-mission"],
                createProposal: q["create-proposal"],
                orgCrud: q.hq,
                myspace: q.myspace || q.invite,
                board: section === "board" ? true : undefined,
                home: section === "home" ? true : undefined,
                login: undefined,
                timeTracking: q["time-tracking"] && !q.mission,
                controlPanel: false,
                error: q.diagnostic,
                powerTab: q.powerTab,
            })
        } else {
            this.setState({
                myspace: q.myspace || q.invite,
                board: section === "board" ? true : undefined,
                home: section === "home" ? true : undefined,
                controlPanel: false,
            })
        }
    }
    personUploadAvatar(file, base64) {
        if (!file || !base64) return

        if (base64) {
            const mergedChange = {
                ...this.state.person,
                avatar: base64,
            }

            this.setState({
                person: update(this.state.person, { $set: mergedChange }),
            })
        }
        return apiAuth.uploadAvatar(file).then((res) => {
            if (base64) {
                const newAvatar = {
                    ...this.state.person,
                    avatar: res.avatar,
                }

                this.setState(
                    {
                        person: update(this.state.person, { $set: newAvatar }),
                    },
                    () => {
                        this.updateStateForPersonAvatar(this.state.person, true)
                    }
                )
            }
        })
    }
    personUpdate(newData) {
        const me = this

        const mergedChange = { ...this.state.person, ...newData }

        this.setState({
            person: update(this.state.person, { $set: mergedChange }),
        })

        return apiAuth
            .updateProfile(newData)
            .then((res) => {
                me.updateStateForPersonAvatar(res)
            })
            .catch((err) => {
                this.silentFail("wkje93--w--wkskwiw" + (err?.message + " - " + err?.toString()))
            })
    }

    showSignInError(msg, options) {
        toast.warning(msg, options)
        this.setState({
            resetLoginStates: new Date().getTime(),
        })
    }
    doesEmailExist(email) {
        return apiAuth.existsPersonWithEmail(email)
    }
    async signInWithMicrosoft() {
        try {
            const { profile } = await apiAuth.microsoftSignInWithPopup(["User.Read"])

            // const resp = await microsoftLoginPopup(['User.Read']);
            // const email = resp.account.username;
            // const accessToken = resp.accessToken;
            // const idToken = resp.idToken;
            // const {data} = await axios({
            //     url: 'https://graph.microsoft.com/v1.0/me',
            //     method: 'GET',
            //     headers: {
            //         Authorization: `Bearer ${accessToken}`
            //     }
            // });
            //
            // if (!data.mail) {
            //     // User does not have an Exchange Online license
            //     // https://stackoverflow.com/questions/46234646/azure-ad-property-mail-is-read-only-and-cannot-be-set/51185810#51185810
            //     return toast.error(`An Exchange Online license is required and was not found for this user. Either enable one or contact your company's administrator.`, {
            //         autoClose: 8000
            //     });
            // }

            // const [microsoftProfile, microsoftPhoto] = await Promise.all([
            //     axios({
            //         url: 'https://graph.microsoft.com/v1.0/me',
            //         method: 'GET',
            //         headers: {
            //             Authorization: `Bearer ${accessToken}`
            //         }
            //     }),
            //     axios({
            //         url: 'https://graph.microsoft.com/v1.0/me/photo/$value',
            //         method: 'GET',
            //         headers: {
            //             Authorization: `Bearer ${accessToken}`
            //         }
            //     })
            // ]);
        } catch (err) {
            if (
                ["auth/account-exists-with-different-credential", "auth/credential-already-in-use"].includes(err?.code)
            ) {
                // An account already exists with the same email address but different sign-in credentials.
                // Sign in using a provider associated with this email address.
                // https://firebase.google.com/docs/auth/web/microsoft-oauth#expandable-1
                // How do we handle this?
                // Maybe, prompt the user to set his personal email as the primary email and try again?
                // Or force him to set a personal email to distinguish the two accounts (no linking)?
                // In either case we need to give the option to the user, what they would like to do.
            } else if (err?.code === "auth/multi-factor-auth-required") {
                // Proof of ownership of a second factor is required to complete sign-in.
                // Happens when user haven't gone through the Microsoft MFA steps (email + Phone)
            } else if (err?.code === "auth/provider-already-linked") {
                // User can only be linked to one identity for the given provider.
            }

            throw err
        }
    }
    signIn(email, password, errorCb) {
        return apiAuth
            .signInWithEmailAndPassword(email, password, true)
            .then((res) => {
                //The auth change listener catches this
            })
            .catch((err) => {
                if (err.code === "auth/wrong-password") {
                    this.showSignInError("Ooops. Please check that your email and password are correct.")
                    if (errorCb) errorCb()
                } else if (err.code === "auth/user-not-found") {
                    if (errorCb) errorCb()

                    return apiAuth.existsPersonWithEmail(email).then((res) => {
                        if (res.exists) {
                            if (res.primaryEmail) {
                                this.showSignInError(
                                    `👋 Hello! The email you just tried is a secondary account linked to another email address. Please use your primary email ${res.primaryEmail} to login.`,
                                    { autoClose: false }
                                )
                            } else {
                                this.showSignInError(
                                    "The email you just tried is likely a secondary email on one of your projects. Please use your primary email to login.",
                                    { autoClose: false }
                                )
                            }
                        } else {
                            this.showSignInError(
                                "Sorry the username and password we're either not found or do not match"
                            )
                        }
                    })
                }

                if (errorCb) errorCb()

                throw err
            })
    }
    signUp(email, password, cb) {
        return apiAuth
            .signUp(email, password)
            .then(() => {
                const q = getQ()



                return apiAuth
                    .signInWithEmailAndPassword(email, password, true)
                    .then(() => apiAuth.getCurrentUser())
                    .then(() =>
                        !["invite", "orgInvite"].includes(q.from) ? apiAuth.sendEmailVerification() : Promise.resolve()
                    ).catch(err=>{

                    })
            })
            .catch((err) => {
                if(err==="Unauthorized"){
                    return
                }
                if(cb)
                cb(err)
            })
    }
    enterTest(email, password, rememberMe) {
        const q = getQ()

        this.tooLong1 = setTimeout(() => {
            toast.error(
                "This is taking way too long. Somethings not right with the connection. Maybe try refreshing the page and we'll check on our side.",
                {
                    duration: 7000,
                }
            )

            me.resetEntryStates()
        }, 10000)

        if (!email || !password) {
            return this.showSignInError("Somethings missing. Please check that your email and password are ok.")
        }

        const me = this

        apiAuth
            .signInWithEmailAndPassword(email, password, rememberMe)
            .then((res) => {
                clearTimeout(this.tooLong1)

                //The auth change listener catches this
            })
            .catch((err) => {
                if (err.code === "auth/wrong-password") {
                    this.showSignInError("Ooops. Please check that your email and password are correct.")
                } else if (err.code === "auth/user-not-found") {
                    apiAuth
                        .existsPersonWithEmail(email)
                        .then((res) => {
                            if (res.exists) {
                                clearTimeout(this.tooLong1)
                                toast.warning(
                                    "Hi. That email you just tried is likely a secondary email on one of your missions. Try your primary email to login.",
                                    { autoClose: false }
                                )
                                me.resetEntryStates()
                            } else {
                                //OK!

                                clearTimeout(this.tooLong1)

                                apiAuth
                                    .signUp(email, password)
                                    .then(() => {
                                        apiAuth
                                            .signInWithEmailAndPassword(email, password, rememberMe)
                                            .then(() => apiAuth.getCurrentUser())
                                            .then(() =>
                                                !["invite", "orgInvite"].includes(q.from)
                                                    ? apiAuth.sendEmailVerification()
                                                    : Promise.resolve()
                                            )
                                            .then(() => {})
                                            .catch((err) => {
                                                me.resetEntryStates()
                                                toast.error("Sorry.  " + err.message)
                                            })
                                    })
                                    .catch((err) => {
                                        history.push("/login")

                                        me.resetEntryStates()
                                        toast.warning("Sorry but there was an issue: " + err.message, {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 6000,
                                        })
                                    })
                            } //End else
                        })
                        .catch((err) => {
                            clearTimeout(this.tooLong1)
                            toast.error("Failed at log in while checking if email exist. I mean c'mon us....")
                            me.resetEntryStates()
                        })
                } else if (err.code === "auth/too-many-requests") {
                    /**
                     * @makis This error could be caused by bots or malicious users.
                     * TODO: Blacklist IP and require captcha to continue.
                     */
                    toast.error("Too many attempts. Please try again later or reset your password.")
                    me.resetEntryStates()
                    clearTimeout(this.tooLong1)
                } else {
                    toast.error("Sorry. Please try again later or contact us if the issue persists.")
                    me.resetEntryStates()
                    clearTimeout(this.tooLong1)
                }
            })
    }

    personSignOut(goToLogin) {
        const me = this

        try {
            // CLEAR local storage
            forIn(localStorage, (value, key) => (key.startsWith("missionx-") ? localStorage.removeItem(key) : null))
        } catch (err) {
            // nothing
        }

        this.theMissionsHaveLanded = false

        document.getElementById("dna-body").className = ""

        this.isActiveUser = false

        me?.block("One moment please...")

        apiAuth
            .signOut()
            .then(function () {
                setCookie("mission-x-active-login", false, -365)

                if (window.location.href.includes("localhost:")) {
                    window.location = "/login"
                } else {
                    window.location = "http://missionx.ai"
                }
            })
            .catch(function (error) {
                toast.error("An issue occured: " + error.message)
                me?.unBlock()
            })
    }
    personResetPasswordRequest(emailOrNumber) {
        if (isEmail(emailOrNumber)) {
            apiAuth
                .sendPasswordResetEmail(emailOrNumber)
                .then((res) => {
                    toast.warning("Ok sent.‍ Please check your email and follow the link.", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    })
                })
                .catch((err) => {
                    toast.error(err?.message || "I'm sorry there was an issue. Please contact help@missionx.ai.", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 6000,
                    })
                })
        } else if (isMobilePhone(emailOrNumber)) {
            //  https://firebase.google.com/docs/auth/web/phone-auth
            //  3. Send a verification code to the user's phone
        } else {
            toast.error("Sorry. I didn't recoginize where you want me to send the reset details.")
        }
    }
    personConfirmPasswordReset(newPassword, cbError) {
        const me = this

        apiAuth
            .confirmPasswordReset(this.tempResetCode, newPassword)
            .then((res) => {
                toast.success("You did it! Your'e the best.", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                })

                apiAuth
                    .signInWithEmailAndPassword(me.tempResetEmail, newPassword, true)
                    .then(() => {
                        me.resetEntryStates()
                        history.push("/home")
                    })
                    .catch((err) => {
                        cbError(err)
                        me.resetEntryStates()
                    })
            })
            .catch((err) => {
                toast.error("An issue occurred: " + err.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 6000,
                })
            })
    }
    personActivateCode(code) {
        //The scenario we texted a code to their phone
        //  https://firebase.google.com/docs/auth/web/phone-auth
        //Here i will send you an email or phone number plus the code...
        /*
        MIKE CODE
        authApi.validateResetCode(code,emailOrNumber).then(res=>{
            //MIKE CODE signperson automatically
            addParam('password-reset')
        }).catch(err=>{
        //Makis here
      })
        */
    }
    updateTimeTracking() {
        this.setState({
            hasTimeTracking: existsTimeTracking(this.state.missions) || this.state.orgs.find((o) => o.isLicensedActive),
        })
    }
    missionSetupBudget(obj) {
        this.setState({
            missionSetupBudget: obj,
        })
    }

    block(msg, to) {
        if (this.state.loadingBlocker) {
            return
        }
        if (to === 0) {
            this.setState({
                loadingBlocker: true,
                loadingBlockerMessage: msg || "Please stand by...",
            })
        } else {
            clearTimeout(this.blockTO)
            this.blockTO = setTimeout(() => {
                this.setState({
                    loadingBlocker: true,
                    loadingBlockerMessage: msg || "Please stand by...",
                })
            }, to || 300)
        }
    }
    unBlock() {
        clearTimeout(this.blockTO)
        this.setState({
            loadingBlocker: false,
            loadingBlockerMessage: null,
        })
    }
    hideAppElements() {
        this.setState({
            hideDamKnob: true,
            hideAvatar: true,
            hideExtras: true,
        })
    }

    showAppElements() {
        this.setState({
            hideDamKnob: false,
            hideAvatar: false,
            hideExtras: false,
        })
    }
    hideNavigator() {
        this.hideKnobs()
    }
    showNavigator() {
        this.showKnobs()
    }
    hideKnobs() {
        this.setState({
            hideDamKnob: true,
        })
    }

    showKnobs() {
        this.setState({
            hideDamKnob: false,
        })
    }
    hideAppAdminTools() {
        if (this.state.hideAdminTools !== true)
            this.setState({
                hideAdminTools: true,
            })
    }
    showAppAdminTools() {
        if (this.state.hideAdminTools === true)
            this.setState({
                hideAdminTools: false,
            })
    }
    reBuildState() {
        const me = this

        this.setState({
            syncBlocker: true,
        })

        console.log("missionX: State rebuild request")

        const q = new URLSearchParams(window.location.search)
        let og = q.get("org")

        if (og) {
            removeParam("org")
        }

        Promise.all([apiOrg.getOrgList(), apiMission.getMissionListTree(missionTreeConfig)])
            .finally(() => {
                this.setState({
                    syncBlocker: false,
                })
            })
            .then((results) => {
                const orgList = results[0] || []
                let missions = results[1] || []

                missions = missions.filter((m) => !!m && m.type !== "classic-gantt" && m.type !== "gantt")

                missions.forEach((m, i) => {
                    missions[i] = this.cleanBadData(m)
                })

                const allPlanItems = flatMap(missions, "planItems")

                let mfaDisabledMissions = []
                let mfaDisabledOrgs = []

                if (!this.state.multiFactor) {
                    mfaDisabledOrgs = orgList.filter((o) => {
                        return o.isMFARequired
                    })

                    mfaDisabledMissions = missions.filter((m) => {
                        return m.org?.isMFARequired
                    })

                    if (mfaDisabledMissions.length) {
                        missions = differenceBy(missions, mfaDisabledMissions, "_id")
                    }
                }

                const phases = allPlanItems.filter((item) => item.type === "phase")

                const actions = flatMap(phases, "actions")

                missions.forEach((m) => {
                    if (m.org && m.org._id && orgList.findIndex((o) => o._id === m.org._id) === -1) {
                        orgList.push(m.org)
                    }
                })

                this.fetchedOrgs = {}

                me.setState(
                    {
                        missions,
                        mfaDisabledMissions,
                        mfaDisabledOrgs,
                        phases,
                        actions,
                        orgs: orgList,
                        //avKey: uniqid(), //ALWAYS FORCE UPDATE, STUPID SHIT NOT WORKING
                    },
                    () => {
                        console.log("missionX: State rebuilt at " + new Date())
                        this.loadLatestNotifications()

                        this.setState({
                            missionChange: {
                                stateRebuild: uniqid(),
                            },
                        })

                        addParam("org", og)
                    }
                )
            })
            .catch((err) => {
                toast.error(err.message)
            })
    }
    cleanBadData(mission) {
        try {
            mission = { ...mission }

            if (mission?.org && mission.org?.isMFARequired && !this.state.multiFactor) {
                return mission
            }

            if (!mission.planStartDate || !mission.planEndDate || mission.planStartDate > mission.planEndDate) {
                if (!mission.planStartDate && !mission.planEndDate) {
                    const d = getTimeUtc(startOfDay(new Date()))

                    mission.planStartDate = d
                    mission.planEndDate = getTimeUtc(addMonths(d, 3))
                    this.block()
                    setTimeout(() => {
                        this.missionUpdate(mission._id, {
                            planStartDate: mission.planStartDate,
                            planEndDate: mission.planEndDate,
                        })

                        this.unBlock()
                    }, 1000)
                }
                if (!mission.planStartDate && mission.planEndDate) {
                    const d = getTimeUtc(startOfDay(subMonths(toZonedTime(mission.planEndDate), 2)))

                    mission.planStartDate = d
                    this.block()
                    setTimeout(() => {
                        this.missionUpdate(mission._id, {
                            planStartDate: d,
                        })

                        this.unBlock()
                    }, 1000)
                } else if (mission.planStartDate && !mission.planEndDate) {
                    const d = getTimeUtc(endOfDay(addMonths(toZonedTime(mission.planStartDate), 2)))

                    mission.planEndDate = d

                    this.block()
                    setTimeout(() => {
                        this.missionUpdate(mission._id, {
                            planEndDate: d,
                        })

                        this.unBlock()
                    }, 1000)
                } else if (mission.planStartDate > mission.planEndDate) {
                    const d = getTimeUtc(endOfDay(addMonths(toZonedTime(mission.planStartDate), 3)))

                    mission.planEndDate = d

                    this.block()
                    setTimeout(() => {
                        this.missionUpdate(mission._id, {
                            planEndDate: getTimeUtc(endOfDay(addMonths(toZonedTime(mission.planStartDate), 3))),
                        })

                        this.unBlock()
                    }, 1000)
                }
            }

            let newPlanItems =
                mission?.planItems?.slice() ||
                [](mission?.planItems?.slice() || []).forEach((planItem, piIndex) => {
                    if (planItem.startDate > planItem.endDate || !planItem.startDate || !planItem.endDate) {
                        const myIndex = newPlanItems.findIndex((np) => np._id === planItem._id)
                        newPlanItems.splice(myIndex, 1)
                    }
                    planItem.actions?.forEach((action, aIndex) => {
                        if (action.startDate > action.endDate || !action.startDate || !action.endDate) {
                            mission.planItems[piIndex].actions.splice(aIndex, 1)
                        }

                        if (!action?.actionItems) {
                            mission.planItems[piIndex].actions[aIndex].actionItems = []
                        }

                        if (mission.projectType === "mx-gantt") {
                            action?.actionItems?.forEach((ai, aiIndex) => {
                                const path = mission.planItems[piIndex].actions[aIndex]?.actionItems

                                if (ai.startDate > ai.endDate || !ai.startDate || !ai.endDate) {
                                    const myAiIndex = path.findIndex((a) => a._id === ai._id)
                                    path.splice(myAiIndex, 1)
                                }
                            })
                        }
                    })
                })

            mission.planItems = newPlanItems
        } catch (err) {
            this.silentFail("wlkejwiuyeoe " + (err?.message + " - " + err?.toString()))
        }

        return mission
    }
    onAsyncMissionsLoaded({ missions, orgList }) {
        missions = missions.filter((m) => !!m && m.type !== "classic-gantt" && m.type !== "gantt")

        missions.forEach((m, i) => {
            missions[i] = this.cleanBadData(m)
        })

        let mfaDisabledMissions = []

        if (!this.state.multiFactor) {
            mfaDisabledMissions = missions.filter((m) => {
                return m.org?.isMFARequired
            })

            if (mfaDisabledMissions.length) {
                missions = differenceBy(missions, mfaDisabledMissions, "_id")
            }
        }

        //Check for missing orgs in case user is not org authed.
        missions.forEach((m) => {
            if (m.org && m.org._id && orgList.findIndex((o) => o._id === m.org._id) === -1) {
                orgList.push(m.org)
            }
        })

        const orgLincesed = orgList.find((o) => o.isLicensedActive)

        const tt = orgLincesed || existsTimeTracking(missions)

        this.setState(
            {
                waiting: false,
                missions: missions,
                mfaDisabledMissions: mfaDisabledMissions,
                orgs: orgList,
                hasTimeTracking: tt,
                theMissionsHaveLanded: true,
            },
            async () => {
                this.theMissionsHaveLanded = true

                this.loadLatestNotifications()

                this.routing()

                const q = getQ()

                if (q["action-item"]) {
                    this.showActionItemDetails(getQ()["action-item"])
                }

                this.changeListener()

                let missing = []

                try {
                    missing = await getMissingTimesheets(this)
                    this.setState({
                        missingTimesheets: missing.length,
                    })
                } catch (e) {}
            }
        )
    }
    buildOrgMissionTree(orgData, cb) {
        const me = this
        if (!this.fetchedOrgs[orgData._id]) {
            me.block()

            apiMission.getMissionListTree({ org: orgData._id, myMissions: false }).then((res) => {
                this.fetchedOrgs[orgData._id] = true

                const miss = Object.fromEntries(me.state.missions.map((item) => [item._id, item]))

                res = res.filter((m) => !miss[m._id])

                res = res.map((m) => me.cleanBadData(m))

                const newMissions = [...this.state.missions, ...res]

                me.unBlock()

                this.setState({ missions: newMissions })

                cb(res)
            })
        } else {
            cb(null)
        }
    }

    async buildMissionTree(orgList) {
        let mfaDisabledOrgs = []

        if (!this.state.multiFactor) {
            mfaDisabledOrgs = orgList.filter((o) => {
                return o.isMFARequired
            })

            this.setState({
                mfaDisabledOrgs: mfaDisabledOrgs,
            })
        }

        if (!this.state.missions?.length) {
            // Load from cache until is loaded from API
            try {
                const data = await apiMission.getMissionListTree(missionTreeConfig, { cached: true })

                if (data) {
                    this.onAsyncMissionsLoaded({ missions: data, orgList })
                }
            } catch (err) {
                // Nothing
            }
        }

        if (mfaDisabledOrgs?.length && !this.state.multiFactor && !this.hasSeenMsg("org-2fa-must")) {
            this.confirm({
                severe: true,
                comp: (
                    <>
                        <p>Hey {this.state.person.firstName}👋</p>
                        <p>
                            {mfaDisabledOrgs.length === 1 ? "An organization requires" : "Some organizations require"}{" "}
                            you to setup two-factor authentication for extra account security at login.{" "}
                        </p>
                        <p>You will not be able to access projects and data until you do so.</p>
                    </>
                ),
                yesText: "Setup up 2FA",
                noText: "I'll do it later",
                singleClick: true,
                noCancel: true,
                onNo: () => {
                    this.setUiSeenFlags("org-2fa-must")
                },
                onYes: () => {
                    this.setUiSeenFlags("org-2fa-must")
                    removeParams("org,mission,phase,action")
                    addParam("myspace", "2fa")
                },
            })
        }

        apiMission
            .getMissionListTree(missionTreeConfig)
            .then((missions) => this.onAsyncMissionsLoaded({ missions, orgList }))
            .catch((err) => {
                this.setState(
                    {
                        waiting: false,
                        theMissionsHaveLanded: true,
                        orgs: orgList,
                    },
                    () => {
                        this.loadLatestNotifications()
                    }
                )

                throw err
            })
    }
    getUserDomain(em) {
        const email = em || this.state.person?.email

        if (!email) return

        return email.slice(email.indexOf("@") + 1, email.length)
    }
    async personAuthInit() {
        const me = this
        await apiAuth
            .getProfile()
            .then(async (personRes) => {
                if (typeof personRes.theme === "object") {
                    personRes.theme = "empty"
                }

                this.setState(
                    {
                        person: personRes,
                    },
                    () => {
                        me.inLogic()

                        apiOrg
                            .getOrgList()
                            .then((orgList) => {
                                this.buildMissionTree(orgList)
                            })
                            .catch((err) => {
                                toast.error("There was an error getting Orgs. Notifcations sent.")
                            })

                        if (personRes.mode === "dark") {
                            document.getElementById("dna-body").className = "dna-dark dna-dark-bg"
                        } else {
                            document.getElementById("dna-body").className = ""
                        }
                    }
                )
            })
            .catch((err) => {
                if (err.code === "auth/gt-inactive") {
                    return this.personSignOut(true)
                }
            })
    }
    /*    personMassageToDoData(obj) {
        const allActionItems = flatMap(flatMap(flatMap(this.state.missions, "planItems"), "actions"), "actionItems")
        //Fl

        const actionItems = allActionItems

        let action = actionItems.filter((ai) => !!ai).find((ai) => ai._id === obj._id)

        if (!action) {
            return
        }

        action = { ...action }

        action.missionData = this.state.missions.find((m) => m._id === action.missionId)
        action.phaseData = action.missionData.planItems.find((m) => m._id === action.missionPlanId)
        action.actionData = action.phaseData.actions.find((m) => m._id === action.actionId)

        const status = getActionItemStatus(action, action.missionData)

        action.orgData = this.state.orgs.find((o) => o._id === getObjectId(action.missionData.org))

        action.showAction =
            action.missionData.projectType === "kanban" || action.missionData.projectType === "simple" ? false : true

        action.comp =
            action.source === "ai" && action.title !== "Complete" && action.sourceAction !== "actionManager"
                ? ActionTile
                : ActionItemTile
        action.showDuration = true
        action.openMission = true
        action.hideList = action.sourceAction === "actionManager"
        action.app = this
        action.overdue = status.isOverDue

        return action
    }*/
    personAcceptInvite(inviteId, noticeId, infoObj) {
        const me = this

        const noticeIndex = me.state.notificationList.findIndex((n) => n?._id === noticeId)

        me.setState({
            notificationList: update(me.state.notificationList, {
                $splice: [[noticeIndex, 1]],
            }),
        })

        const acceptMethod = infoObj?.informationalOnly === true ? apiMission.confirmInvite : apiMission.acceptInvite

        acceptMethod(inviteId)
            .then((res) => {
                toast.success(
                    "Welcome aboard! 🚀 Check your Control panel or Mission. Vision. Board. from the project management workspace."
                )

                if (res.org) {
                    apiOrg
                        .getOrgList()
                        .then((orgList) => {
                            this.buildMissionTree(orgList)
                        })
                        .catch((err) => {
                            toast.error("There was an error getting Orgs. Notifcations sent.")
                        })
                } else {
                    if (res._id) {
                        me.missionLoad(res)
                    }
                }
            })
            .catch((err) => {
                toast.error("Sorry that invite is longer valid.")
            })
    }
    personAcceptInviteOrg(inviteId, noticeId) {
        apiOrg
            .acceptInvite(inviteId)
            .then((org) => {
                toast.success("Welcome to the organization!")

                const newNotificationList = this.state.notificationList.slice()
                const notificationIndex = newNotificationList.findIndex((n) => n._id === noticeId)

                this.setState(
                    {
                        notificationList: update(this.state.notificationList, {
                            $splice: [[notificationIndex, 1]],
                        }),
                    },
                    () => {
                        if (!this.state.orgs.find((orggg) => orggg._id === org._id))
                            this.setState(
                                {
                                    orgs: update(this.state.orgs, { $push: [org] }),
                                },
                                () => {
                                    if (
                                        org.title.includes("missionX Trial") &&
                                        canAccessOrgSpace(this.state.person, org)
                                    ) {
                                        this.setState({
                                            controlPanel: true,
                                        })
                                    }
                                    this.buildMissionTree(this.state.orgs)
                                }
                            )
                    }
                )
            })
            .catch((err) => {
                toast.error(get(err, "message") || "Sorry that org invite is longer valid")
            })
    }
    personRejectInvite(inviteId, org, noticeId) {
        const apiCall = org ? apiOrg : apiMission
        apiCall
            .rejectInvite(inviteId)
            .then((res) => {
                let newNotificationList = this.state.notificationList.slice()
                const notificationIndex = newNotificationList.findIndex((n) => n._id === noticeId)
                newNotificationList.splice(notificationIndex, 1)
                this.setState({
                    notificationList: newNotificationList,
                })
                toast("No problem. We'll pass the msg along. Thanks!")
            })
            .catch((err) => {
                console.error("Sorry that invite is longer valid or an error has occured.")
            })
    }
    missionLoad(mission) {
        const me = this

        me.setState({
            missions: update(this.state.missions, { $push: [mission] }),
        })
    }

    missionCreate(data, org, cb, options = {}, dontOpenMission) {
        apiMission
            .createMission(data, options)
            .then((mission) => {
                if (data.proposalLinkId) {
                    let proposalParent = this.state.missions.find((mmp) => mmp._id === data.proposalLinkId)

                    if (proposalParent) {
                        this.missionUpdate(proposalParent._id, {
                            linkedProject: mission._id,
                        })
                    }
                }

                if (mission.projectType === "power") {
                    this.planItemCreateX(mission, {
                        type: "phase",
                        startDate: mission.startDate,
                        endDate: mission.endDate,
                    })
                }

                this.updateStateForMissionCreate(mission, () => {
                    if (!dontOpenMission) {
                        if (mission.type === "power") {
                            addParams([{ param: "mission", value: mission._id }])
                        } else {
                            addParam("mission", mission._id)
                            removeParams("mc,mc-org,mc-org-goal,mc-prop,create-proposal")
                        }
                    }
                    let meInOrg = {}
                    let orgRole
                    if (org) {
                        meInOrg = (org.people || []).find((p) => getRefId(p) === mission.createdBy._id)
                        if (meInOrg) {
                            orgRole = getRole(meInOrg.role, org)
                        }
                    }

                    let roleDetailsObj = {}

                    if (!org || !org.isLicensedActive || (org && !org.projectCreationRestricted)) {
                        roleDetailsObj = {
                            role: orgRole ? orgRole._id : "Project manager",
                            rate: orgRole ? orgRole.cost : 0,
                            rateTime: orgRole?.rateTime || "Monthly",
                            billRate: orgRole ? orgRole.billRate : 0,
                            billUnit: orgRole && orgRole.billUnit ? orgRole.billUnit : "Hourly",
                            person: mission.createdBy._id, //catch flow below
                        }
                    } else if (org && org.projectCreationRestricted) {
                        roleDetailsObj = {
                            role: "Project manager",
                            rate: 0,
                            rateTime: org?.roleDefaultRateTime || "Hourly",
                            billRate: 0,
                            billUnit: "Hourly",
                            person: null,
                        }
                    }

                    //Create default role for admin / pm unless the org requires approvals
                    const createConditions =
                        mission.projectType !== "mx-gantt" &&
                        !data.isTemplate &&
                        !mission.isModel &&
                        !options?.template &&
                        !org?.projectCreationRestricted &&
                        !org?.isLicensedActive

                    if (createConditions || !org) {
                        this.planItemCreateX(
                            mission._id,
                            {
                                title: "Project manager",
                                role: roleDetailsObj.role,
                                allocation: 100,
                                rateTime: roleDetailsObj?.rateTime || "Monthly",
                                type: "person",
                                pinnedTo: "entireMission",
                                rate: roleDetailsObj.rate || 0,
                                billRate: roleDetailsObj.billRate || 0,
                                billUnit: roleDetailsObj.billUnit || "Hourly",
                                y: 0,
                                person: roleDetailsObj.person,
                                startDate: mission.planStartDate
                                    ? mission.planStartDate
                                    : getTimeUtc(startOfDay(new Date())),
                                endDate: mission.planEndDate
                                    ? mission.planEndDate
                                    : getTimeUtc(endOfMonth(addMonths(startOfDay(new Date()), 4))),
                            },
                            (res) => {
                                //check if we can approve this role and assign person

                                //If PM

                                if (org && meInOrg) {
                                    if (
                                        org.rolesNeedApproval &&
                                        (meInOrg.canApproveRoles || meInOrg.permission === 2)
                                    ) {
                                        assignPersonToRole(meInOrg, res, meInOrg.role, org, mission, this, null, true)
                                    }
                                }
                            }
                        )
                    }

                    if (cb) cb(mission)
                })
            })
            .catch((err) => {
                toast.error(err?.msg || err || "Problem creating project please refresh and try again. We're sorry.")
                console.error(err)

                switch (err.code) {
                    case "account_limit":
                        return toast("Hello! Sorry there is a limit for now. Please contact us help@missionx.ai", {
                            autoClose: 7000,
                        })
                }

                this.unBlock()

                removeParam("create-mission")

                return toast.error(err.message)
            })
    }

    missionAddSectionTag(missionId, tag, cb) {
        const me = this

        apiMission
            .addMissionSectionTag(missionId, tag)
            .then((res) => {
                me.missionUpdateState(missionId, { sectionTags: res.sectionTags })

                if (cb && res.sectionTags) cb(res.sectionTags[res.sectionTags.length - 1])

                const myMission = this.state.missions.find((m) => m._id === missionId)
                if (myMission.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                toast.error(
                    get(err, "message") ||
                        "Sorry an error occured. Please refresh and try again. We are also investigating."
                )
            })
    }
    missionUpdateSectionTag(mission, tag, change, cb) {
        if (tag._id === "catch-all") {
            return
        }

        const tIndex = mission.sectionTags.findIndex((t) => t._id === tag._id)
        const oldTags = mission.sectionTags.slice()

        if (tIndex !== -1) {
            let newTags = mission.sectionTags.slice()
            newTags[tIndex] = { ...newTags[tIndex], ...change }

            this.missionUpdateState(mission._id, { sectionTags: newTags })
        }

        apiMission
            .updateMissionSectionTag(mission._id, tag._id, change)
            .then((res) => {
                this.missionUpdateState(mission._id, { sectionTags: res.sectionTags })
                this.missionUpdateSectionTagDependents(tag, change, mission)
                if (mission.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(mission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
                if (cb) {
                    cb(res)
                }
            })
            .catch((err) => {
                toast.error(
                    get(err, "message") ||
                        "Sorry an error occured. Please refresh and try again. We are also investigating."
                )

                this.missionUpdateState(mission._id, { sectionTags: oldTags })
            })
    }
    missionDeleteSectionTag(missionId, tag, cb) {
        apiMission
            .deleteMissionSectionTag(missionId, tag._id)
            .then((res) => {
                this.missionUpdateState(missionId, { sectionTags: res.sectionTags })
                if (cb) cb(res)

                const myMission = this.state.missions.find((m) => m._id === missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                toast.error(
                    get(err, "message") ||
                        "Sorry an error occured. Please refresh and try again. We are also investigating."
                )
            })
    }

    simpleMissionsAddRoleForNewPerson(person, mission) {
        if (this.doubleClickProtectForsimpleMissionsAddRoleForNewPerson) return

        this.doubleClickProtectForsimpleMissionsAddRoleForNewPerson = true

        let org, orgRole, meInOrg

        if (!mission || mission.projectType === "power") return

        if (mission.org) {
            org = this.state.orgs.find((o) => o._id === getObjectId(mission.org))

            meInOrg = org?.people.find((op) => getRefId(op) === this.state.person._id)

            if (org && (!org.rolesNeedApproval || person.autoCreateRole)) {
                const personInOrg = (org.people || []).find((p) => getRefId(p) === getRefId(person))
                if (personInOrg) {
                    orgRole = getRole(personInOrg.role, org)
                }
            } else {
                return false
            }
        }

        //Catches the roles need approval scenario and the person can approve override (CheckBoxes from mission invite)
        if (org && org.rolesNeedApproval && !person.autoCreateRole) return

        const theID = person.inviteeRef || getRefId(person)

        if (getQ()["invite-to-phase"]) {
            const pi = mission.planItems.find((pi) => pi._id === getQ()["invite-to-phase"])

            if (pi) {
                this.planItemUpdateX(
                    pi,
                    {
                        person: theID,
                    },
                    () => {
                        toast("Person was added to the role.")
                    }
                )
            }

            removeParam("invite-to-phase")
            return
        }

        const planItemAlreadyExists = mission.planItems.find((pi) => pi.person === theID && !pi.personLeftRole)

        if (planItemAlreadyExists) {
            toast.error(
                'Sorry we found a potential conflict with another role. Please assign manually in the projects "Budget" panel',
                {
                    autoClose: false,
                }
            )
            return
        }

        const catchStd =
            isAfter(startOfDay(new Date()), toZonedTime(mission.planEndDate)) ||
            isBefore(startOfDay(new Date()), toZonedTime(mission.planStartDate))
                ? toZonedTime(mission.planStartDate)
                : startOfDay(new Date())

        const cathEnd = toZonedTime(mission.planEndDate)

        this.planItemCreateX(
            mission._id,
            {
                title: orgRole ? orgRole.title : person.config ? person.config.role : "Team member",
                role: orgRole ? orgRole._id : null,
                allocation: 100,
                rateTime: orgRole?.rateTime || org?.roleDefaultRateTime || "Hourly",
                type: "person",
                pinnedTo: "entireMission",
                rate: orgRole ? orgRole.cost : 0,
                billRate: orgRole ? orgRole.billRate : 0,
                billUnit: orgRole?.billUnit || org?.roleDefaultRateTime || "Hourly",
                y: 0,
                person: theID,
                startDate: getTimeUtc(catchStd),
                endDate: getTimeUtc(cathEnd),
            },
            (piRes) => {
                if (org && meInOrg) {
                    if (org.rolesNeedApproval && (meInOrg.canApproveRoles || meInOrg.permission === 2)) {
                        assignPersonToRole(theID, piRes, orgRole ? orgRole._id : null, org, mission, this, null, true)
                    }
                }

                this.doubleClickProtectForsimpleMissionsAddRoleForNewPerson = undefined
            }
        )
    }
    profileUpdateState(changeObj) {
        this.setState({
            person: update(this.state.person, {
                $merge: changeObj,
            }),
        })
    }

    /**
     * Fetch one or more mission trees and update state.
     * @param mid {string|string[]} One or more mission ids
     */
    missionGetTree(mid, cb) {
        mid = !Array.isArray(mid) ? [mid] : mid
        const me = this

        return apiMission
            .getMissionListTree({
                mission: mid,
            })
            .then((mList) => {
                let orgList = this.state.orgs.slice()
                let uMissions = me.state.missions.slice()

                if (mid.length === 1 && !mList?.length) {
                    this.missionCleanUpDeleted(mid[0])

                    return
                } else {
                    mList.forEach((m, i) => {
                        mList[i] = this.cleanBadData(m)
                    })

                    mList.forEach((m) => {
                        if (m.org && m.org._id && orgList.findIndex((o) => o._id === m.org._id) === -1) {
                            orgList.push(m.org)
                        }

                        let mIndex = this.state.missions.findIndex((p) => p._id === m._id)

                        if (mIndex === -1) {
                            uMissions.push(m)
                        } else {
                            uMissions = update(this.state.missions, {
                                [mIndex]: { $set: m },
                            })
                        }
                    })

                    this.setState(
                        {
                            missions: uMissions.slice(),
                            orgs: orgList.slice(),
                        },
                        () => {
                            if (cb) {
                                cb(uMissions)
                            }
                            this.unBlock()
                        }
                    )
                }
            })
            .catch((err) => {
                toast.error("An error occured. We are looking into it asap. " + err.message)
                this.silentFail("i2323ei3e" + (err?.message + " - " + err?.toString()))
                this.unBlock()
            })
    }
    missionUpdateState(id, change, cb) {
        const changedIndex = this.state.missions.findIndex((mission) => mission._id === id)
        let preChange
        if (changedIndex !== -1) {
            try {
                preChange = stringify(this.state.missions[changedIndex])
            } catch (err) {
                preChange = { ...this.state.missions[changedIndex] }

                this.silentFail("wewuehiowe2723783838" + (err?.message + " - " + err?.toString()))
            }

            let missionData = { ...this.state.missions[changedIndex], ...change }

            this.setState(
                {
                    missions: update(this.state.missions, {
                        [changedIndex]: { $set: missionData },
                    }),
                },
                () => {
                    let pc = {}
                    try {
                        pc = preChange ? parse(preChange) : {}
                    } catch (e) {
                        pc = {}
                    }
                    this.setState(
                        {
                            missionChange: {
                                for: "mission",
                                change: change,
                                preChange: pc,
                                timestamp: uniqid(),
                                obj: this.state.missions[changedIndex],
                                missionId: this.state.missions[changedIndex]._id,
                            },
                        },
                        () => {
                            if (cb) {
                                cb(this.state.missions[changedIndex])
                            }
                        }
                    )
                }
            )
        }
    }
    missionUpdate(id, change, cb) {
        debugger
        const changedIndex = this.state.missions.findIndex((mission) => mission._id === id)

        if (changedIndex === -1) {
            toast.warning(
                "An update was triggered but I couldn't find the mission you are trying to update. Please email us at help@missionx.ai if the problem persists",
                {
                    autoClose: 6000,
                }
            )
            return
        }

        if (change.org && typeof change.org === "string") {
            change.org = this.state.orgs.find((org) => org._id === change.org)
        }

        if (change.org && typeof change.org !== "string") {
            change.org = change.org._id
        }

        const preChange = { ...this.state.missions[changedIndex] }

        let missionData = { ...this.state.missions[changedIndex], ...change }

        if (missionData?.isTemplate && !change.hasOwnProperty("isTemplateSaved")) {
            change.isTemplateSaved = false
            missionData.isTemplateSaved = false
        }

        if (missionData.planEndDate < missionData.planStartDate) {
            missionData.planEndDate = getTimeUtc(endOfISOWeek(toZonedTime(missionData.planStartDate), 1))
            change.planEndDate = getTimeUtc(endOfISOWeek(toZonedTime(change.planStartDate), 1))
        }

        this.missionUpdateState(id, change, () => {
            if (cb) cb(this.state.missions[changedIndex])

            if (change.planStartDate || change.planEndDate) {
                this.missionUpdatePinnedItems(missionData, preChange.planStartDate, preChange.planEndDate)
            }
        })

        return apiMission.updateMission(id, change).catch((err) => {
            this.silentFail("lkdweu848jjj911-" + (err?.message + " - " + err?.toString()))
        })
    }

    missionUpdateSectionTagDependents(tag, change, mission) {
        if (!change.from && !change.to) {
            return
        }

        let actionChanges = []
        let allActionItemChanges = []

        const actions = getAllActions(mission).filter(
            (a) =>
                a.startDate &&
                a.endDate &&
                a.actionItems &&
                a.actionItems[0] &&
                a.actionItems[0].sectionTagId === tag._id
        )

        let updateActions = actions.filter((ac) => {
            if (
                ac.status !== "done" &&
                ac.actionItems[0] &&
                ac.actionItems[0].status !== "done" &&
                ac.startDate &&
                ac.endDate &&
                (isBefore(ac.endDate, change.from) || isBefore(change.to, ac.startDate))
            ) {
                return true
            }

            return false
        })

        if (mission.projectType === "kanban") {
            //Items in prigress leave alone

            updateActions = updateActions.filter((a) => a.actionItems[0].col === "todo")
        } else if (mission.projectType === "simple") {
            //Items in progress leave alone

            updateActions = updateActions.filter((a) => {
                let ok = true

                const ai = a.actionItems[0]

                if (
                    isThisWeek(toZonedTime(ai.startDate)) &&
                    isBefore(toZonedTime(ai.startDate), new Date()) &&
                    ai.people.length > 0
                ) {
                    ok = false
                }

                return ok
            })
        }

        updateActions.forEach((action, i) => {
            let aEndDate = endOfISOWeek(change.from)

            if (isAfter(aEndDate, toZonedTime(mission.planEndDate))) {
                aEndDate = toZonedTime(mission.planEndDate)
            }

            const actionEndDate =
                toZonedTime(change.to) && isAfter(aEndDate, change.to) ? change.to : getTimeUtc(aEndDate)

            actionChanges.push({
                _id: action._id,
                startDate: change.from,
                endDate: actionEndDate,
            })

            const ai = action.actionItems[0]
            if (ai.startDate && (ai.startDate < change.from || ai.startDate > change.to)) {
                if (mission.projectType === "kanban") {
                    allActionItemChanges.push({
                        obj: ai,
                        change: {
                            _id: ai._id,
                            startDate: change.from,
                        },
                    })
                } else if (mission.projectType === "simple") {
                    let sStartDay = toZonedTime(change.from).getDay()
                    let sStartHour = sStartDay === 0 ? 6 * 8 : (sStartDay - 1) * 8

                    let sEstimateDuration =
                        sStartHour + ai.estimatedDuration > 56 ? 56 - sStartHour : ai.estimatedDuration

                    allActionItemChanges.push({
                        obj: ai,
                        change: {
                            _id: ai._id,
                            startDate: change.from,
                            startHour: sStartHour,
                            estimatedDuration: sEstimateDuration,
                        },
                    })
                }
            }
        })

        if (actionChanges.length) {
            const endingS = actionChanges.length === 1 ? "" : "s"
            if (!toast.isActive("sectTagKiller"))
                toast.success(
                    actionChanges.length +
                        " task" +
                        endingS +
                        " on your work board not being worked on " +
                        (endingS === "s" ? "have been" : "has been") +
                        " moved to align with the new dates.",
                    {
                        autoClose: 7000,
                        toastId: "sectTagKiller",
                    }
                )

            this.actionUpdateBulk(mission._id, actionChanges, true)
        }
        if (allActionItemChanges.length) {
            this.actionItemUpdateBulk(mission._id, allActionItemChanges)
        }
    }
    missionUpdatePerson(missionId, missionPersonId, changeObj, cb) {
        return apiMission
            .updateMissionPeople(missionId, missionPersonId, changeObj)
            .then((res) => {
                if (cb) cb(res)
            })
            .catch((err) => {
                toast.error(`🤔 Something went wrong.... ${err ? err.message : ""}`)
                if (cb) cb()
            })
    }
    orgUpdatePerson(orgId, orgPersonId, changeObj, cb) {
        apiOrg
            .updateOrgPeople(orgId, orgPersonId, changeObj)
            .then((res) => {
                if (cb) {
                    cb(res)
                }

                let newOrg = this.state.orgs.find((o) => getObjectId(o) === orgId)
                let personIndex = newOrg.people.findIndex((p) => getRefId(p) === getRefId(res))
                const ref = newOrg.people[personIndex].ref

                if (personIndex === -1) return

                let newPeople = newOrg.people.slice()

                newPeople[personIndex] = { ...res, ref }

                this.orgUpdateState(newOrg, { people: newPeople })
            })
            .catch((err) => {
                toast.error(`On no. Something went wrong updating the team... ${err ? err.message : ""}`)
            })
    }
    ganttGetEndDate(ai, newStartDate) {
        const dur = ai.estimatedDuration / 8

        let theEnd = endOfDay(addDays(newStartDate, dur - 1))

        if (ai.includeWeekends) {
            return theEnd
        } else {
            if (isSaturday(newStartDate)) {
                theEnd = addDays(theEnd, 2)
            } else if (isSunday(newStartDate)) {
                theEnd = addDays(theEnd, 1)
            }

            if (isSaturday(theEnd)) {
                theEnd = nextMonday(theEnd)
            } else if (isSunday(theEnd)) {
                theEnd = nextTuesday(theEnd)
            }

            return theEnd
        }
    }
    missionPlanDates(id) {
        let mission

        if (typeof id === "string") {
            mission = this.state.missions.find((m) => m._id === id)
        } else {
            mission = this.state.missions.find((m) => m._id === id._id)
        }

        if (!mission) return

        const myPerm = getMissionPerson(mission, this.state.person._id)

        if (myPerm && myPerm.permission !== 1 && myPerm.permission !== 2) {
            return
        }

        if (mission.projectType === "power") {
            if (mission.planItems.filter((p) => p.type === "phase").length === 0) {
                return
            }

            const planItems = mission.planItems.filter((p) => p.type === "phase")

            if (planItems.length) {
                const planStartDate = planItems.sort((a, b) => a.startDate - b.startDate)[0].startDate
                const planEndDate = planItems.sort((a, b) => a.endDate - b.endDate).reverse()[0].endDate

                this.missionUpdate(mission._id, {
                    planStartDate: planStartDate,
                    planEndDate: planEndDate,
                })
            }
        }
    }
    missionUpdatePinnedItems(mission, beforeStartDate, beforeEndDate) {
        if (typeof mission === "string") {
            mission = this.state.missions.find((m) => m._id === mission)
        }

        let changeArray = []

        if (!mission.planStartDate || !mission.planEndDate) {
            return
        }

        mission.planItems
            .filter((pi) => (pi.pinnedTo === "entireMission" || pi.pinnedTo === "startend") && !pi.personLeftRole)
            .forEach((item, i) => {
                changeArray.push({
                    obj: item,
                    change: {
                        _id: item._id,
                        startDate: mission.planStartDate,
                        endDate: mission.planEndDate,
                    },
                })
            })

        mission.planItems
            .filter((pi) => pi.pinnedTo === "endOfMission" && pi.pinnedTo === "end" && !pi.personLeftRole)
            .forEach((item, i) => {
                const zePlanStartDate = item.isForecast ? mission.forecastStartDate : mission.planStartDate
                const zePlanEndDate = item.isForecast ? mission.forecastEndDate : mission.planEndDate

                let newStartDate = item.startDate

                //Project hasn't started yet, Lets change the start date as well
                if (isAfter(toZonedTime(zePlanStartDate), new Date()) && isBefore(item.startDate, zePlanStartDate)) {
                    newStartDate = zePlanStartDate
                }

                changeArray.push({
                    obj: item,
                    change: {
                        _id: item._id,
                        startDate: newStartDate,
                        endDate: zePlanEndDate,
                    },
                })
            })

        mission.planItems
            .filter((pi) => pi.pinnedTo === "start" && !pi.personLeftRole)
            .forEach((item, i) => {
                let newEndDate = item.endDate

                const uStd = toZonedTime(item.startDate)
                const uEnd = toZonedTime(item.endDate)

                if (mission.planStartDate > item.endDate) {
                    const shift = eachDayOfInterval({
                        start: uStd,
                        end: uEnd,
                    }).length

                    newEndDate = endOfDay(addDays(toZonedTime(mission.planStartDate), shift - 1))

                    newEndDate = getTimeUtc(newEndDate)
                }

                changeArray.push({
                    obj: item,
                    change: {
                        _id: item._id,
                        startDate: mission.planStartDate,
                        endDate: newEndDate,
                    },
                })
            })

        mission.planItems
            .filter((pi) => pi.type === "cost")
            .forEach((pi, i) => {
                let newEndDate, newStartDate

                const isRecurring = pi.rateTime !== "One time"

                const zePlanStartDate = pi.isForecast ? mission.forecastStartDate : mission.planStartDate
                const zePlanEndDate = pi.isForecast ? mission.forecastEndDate : mission.planEndDate

                const amountChangeOfStartDate = beforeStartDate - zePlanStartDate
                const amountChangeOfEndDate = zePlanEndDate - beforeEndDate

                let numMonths = 0

                try {
                    numMonths = eachMonthOfInterval({
                        start: toZonedTime(pi.startDate),
                        end: toZonedTime(pi.endDate),
                    }).length
                } catch (e) {}

                if (pi.startDate < zePlanStartDate || pi.startDate > zePlanEndDate) {
                    //Dates are fucked just fix them for now

                    newStartDate = zePlanStartDate

                    if (pi.rateTime === "One time") {
                        newEndDate = getTimeUtc(endOfMonth(toZonedTime(newStartDate)))
                    } else if (isRecurring) {
                        if (numMonths) {
                            newEndDate = getTimeUtc(endOfMonth(toZonedTime(newStartDate)))
                        } else {
                            newEndDate = getTimeUtc(addMonths(toZonedTime(newStartDate), numMonths))
                        }
                    }
                } else if (amountChangeOfStartDate > 0 && amountChangeOfEndDate === 0) {
                    //Moved only start date back

                    if (pi.rateTime === "One time") {
                        //Shift back amount
                        newStartDate = getTimeUtc(
                            startOfMonth(toZonedTime(pi.startDate - Math.abs(amountChangeOfStartDate)))
                        )
                        newEndDate = getTimeUtc(endOfMonth(newStartDate))
                    } else if (isRecurring) {
                        //Just pull the start date back

                        newStartDate = getTimeUtc(
                            startOfMonth(toZonedTime(pi.startDate - Math.abs(amountChangeOfStartDate)))
                        )

                        //If after mission end date will get caught in fuck ups
                        if (numMonths) {
                            newEndDate = getTimeUtc(endOfMonth(toZonedTime(newStartDate)))
                        } else {
                            newEndDate = getTimeUtc(addMonths(toZonedTime(newStartDate), numMonths))
                        }
                    }
                } else if (amountChangeOfStartDate < 0 && amountChangeOfEndDate === 0) {
                    //Start date moved forward
                    if (pi.rateTime === "One time") {
                        if (pi.startDate < zePlanStartDate) {
                            newStartDate = zePlanStartDate
                            newEndDate = getTimeUtc(endOfMonth(toZonedTime(newStartDate)))
                        }
                    } else if (isRecurring) {
                        if (pi.startDate < zePlanStartDate) {
                            newStartDate = zePlanStartDate

                            if (numMonths) {
                                newEndDate = getTimeUtc(endOfMonth(toZonedTime(newStartDate)))
                            } else {
                                newEndDate = getTimeUtc(addMonths(toZonedTime(newStartDate), numMonths))
                            }
                        }
                    }
                } else if (amountChangeOfEndDate < 0 && amountChangeOfStartDate === 0) {
                    //Moved only end date backward

                    if (pi.rateTime === "One time") {
                        if (pi.endDate > zePlanEndDate) {
                            newEndDate = zePlanEndDate
                            newStartDate = getTimeUtc(startOfMonth(toZonedTime(newEndDate)))
                        } else if (isSameMonth(toZonedTime(pi.endDate), toZonedTime(zePlanEndDate))) {
                            newStartDate = pi.startDate
                            newEndDate = zePlanEndDate
                        }
                    } else if (isRecurring) {
                        if (pi.endDate > zePlanEndDate) {
                            newEndDate = zePlanEndDate

                            if (pi.startDate > newEndDate) {
                                newStartDate = getTimeUtc(startOfMonth(toZonedTime(newEndDate)))
                            } else {
                                newStartDate = pi.startDate
                            }
                        }
                    }
                } else if (amountChangeOfEndDate > 0 && amountChangeOfStartDate === 0) {
                    //Moved only end date forward

                    if (isSameMonth(toZonedTime(pi.endDate), toZonedTime(zePlanEndDate))) {
                        newStartDate = pi.startDate
                        newEndDate = zePlanEndDate
                    }
                }

                //Catch more fuck ups
                if (newStartDate < zePlanStartDate) {
                    newStartDate = zePlanStartDate
                }
                if (newEndDate > zePlanEndDate) {
                    newEndDate = zePlanEndDate
                }

                if (newStartDate && newEndDate)
                    changeArray.push({
                        obj: pi,
                        change: {
                            _id: pi._id,
                            startDate: newStartDate,
                            endDate: newEndDate,
                        },
                    })
            })

        mission.planItems
            .filter((pi) => pi.type === "phase")
            .forEach((pi, i) => {
                mission.planItems
                    .filter(
                        (n) => (n.type === "person" || n.type === "cost") && !n.personLeftRole && n.pinnedTo === pi._id
                    )
                    .forEach((n, i) => {
                        if (n.startDate !== pi.startDate || n.endDate !== pi.endDate) {
                            changeArray.push({
                                obj: n,
                                change: {
                                    _id: n._id,
                                    startDate: pi.startDate,
                                    endDate: pi.endDate,
                                },
                            })
                        }
                    })
            })

        if (changeArray.length) {
            this.planItemUpdateBulk(mission._id, changeArray)
        }
    }
    missionCleanUpDeleted(id, personId, cb) {
        let q = getQ()

        const isMissionOpen = q.mission && q.mission === id

        const paramArray = []

        if (q["mission-create"]) {
            paramArray.push("mission-create")
        }
        if (q["mc"]) {
            paramArray.push("mc")
        }

        if (isMissionOpen) {
            paramArray.push("mission")
            paramArray.push("mission-tab")
            paramArray.push("make-week")
            paramArray.push("week")

            if (personId && personId !== this.state.personId)
                toast("Sorry this project is no longer accessible", {
                    autoClose: 6000,
                })
        }

        const missionIndex = this.state.missions.findIndex((m) => m._id === id)

        if (missionIndex === -1) {
            window.location.href = window.location.href
            return
        }

        removeParams(paramArray.join(","))

        const missionData = this.state.missions[missionIndex]

        const orgOfMission = missionData?.org
            ? this.state.orgs.find((o) => getObjectId(o) === getObjectId(missionData.org))
            : null

        const meInOrg = orgOfMission ? orgOfMission?.people?.find((p) => getRefId(p) === this.state.person._id) : null

        let passThrough = false

        if (meInOrg?.permission === 1 || meInOrg?.permission === 2) {
            if (!meInOrg.accessibleDepartments?.length) {
                //Can see it at  org level
                passThrough = true
            } else if (!missionData.departments?.length) {
                //free for all
                passThrough = true
            } else if (missionData.departments?.filter((value) => meInOrg.accessibleDepartments.includes(value))) {
                //has access to the dept
                passThrough = true
            }
        }

        let newMissions = this.state.missions.slice()

        if (passThrough && personId) {
            const meInMissionIndex = newMissions[missionIndex].people.findIndex(
                (p) => getRefId(p) === this.state.person._id
            )

            if (meInMissionIndex !== -1) {
                newMissions[missionIndex].people[meInMissionIndex].permission = 4
            }
        } else {
            newMissions.splice(missionIndex, 1)
        }

        this.setState(
            {
                missions: newMissions.slice(),
            },
            () => {
                this.setState(
                    {
                        missionChange: {
                            for: "mission",
                            change: "people",
                            timestamp: uniqid(),
                            missionId: id,
                        },
                    },
                    () => {
                        if (cb) cb()
                    }
                )
            }
        )

        //test
    }

    orgRemoveMissionFromDeadline(mission, org) {
        if (!org) return

        let newProposalDeadlines = (org.proposalDeadlines || []).slice()
        let newProposalDeadlinesIndex = newProposalDeadlines.findIndex((t) => t.missionId === mission._id)

        if (newProposalDeadlinesIndex === -1) {
            return
        }

        newProposalDeadlines[newProposalDeadlinesIndex] = {
            ...newProposalDeadlines[newProposalDeadlinesIndex],
            ...{
                missionId: null,
            },
        }

        newProposalDeadlines.forEach((item, i) => {
            item.y = item.y || 0
            delete item._id
        })

        this.orgUpdate(org._id, {
            proposalDeadlines: newProposalDeadlines,
        })
    }
    missionArchive(id, cb) {
        //  this.block("Removing project ☠️")

        const me = this
        const mission = this.state.missions.find((m) => m._id === id)

        //Delete a proposal with linked project
        if (mission.linkedProject) {
            const linkedMission = this.state.missions.find((m) => m._id === mission.linkedProject)

            apiMission
                .deleteMission(id)
                .then((res) => {
                    me.missionCleanUpDeleted(id, null, () => {
                        toast("Deleted " + mission.title + ". help@missionx.ai.", {
                            autoClose: 3000,
                        })
                    })

                    if (linkedMission) {
                        apiMission
                            .deleteMission(linkedMission._id)
                            .then((res) => {
                                me.missionCleanUpDeleted(linkedMission._id)
                            })
                            .catch((err) => {
                                toast.error("🤔 " + err?.message)
                            })
                    }
                })
                .catch((err) => {
                    this.silentFail("kvnjerhfk44---" + (err?.message + " - " + err?.toString()))

                    toast.error("🤔 " + err?.message)
                })
        } else if (mission.isTemplate && mission.linkedTemplate) {
            this.block("One sec...")

            apiTemplate
                .deleteTemplateBulk([mission.linkedTemplate])
                .then(() => {
                    apiMission
                        .deleteMission(id)
                        .then((res2) => {
                            this.unBlock()
                            me.missionCleanUpDeleted(id, null, () => {
                                toast("Ok done help@missionx.ai", {
                                    autoClose: 3000,
                                })
                                if (cb) cb()
                            })
                        })
                        .catch((err) => {
                            toast.error("🤔 " + err?.message)
                        })
                })
                .catch((err) => {
                    this.silentFail("we3333rredfcerewr-" + (err?.message + " - " + err?.toString()))
                    toast.error("🤔 " + err?.message)
                    this.unBlock()
                })
        } else {
            apiMission
                .deleteMission(id)
                .then((res) => {
                    me.missionCleanUpDeleted(id, null, () => {
                        toast("Deleted " + mission.title + ". help@missionx.ai.", {
                            autoClose: 3000,
                        })
                    })
                })
                .catch((err) => {
                    this.silentFail("ewty56uhfdrtr---" + (err?.message + " - " + err?.toString()))
                    //toast.error("🤔 " + err?.message)
                })
        }
    }
    orgInvitePerson(orgid, inviteeEmail, details, cb, noTip) {
        apiOrg
            .createInvite(orgid, inviteeEmail, details)
            .then((res) => {
                if (!noTip) toast.success("Great! Invitation sent to " + inviteeEmail + ".")

                if (cb) cb(res)
            })
            .catch((err) => {
                try {
                    toast.error("Oh oh! Issue: " + err.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    })
                    if (cb) cb()
                } catch (err) {
                    if (cb) cb()
                    toast.error("The system presented an issue. We'll investigate right away.")
                    throw err
                }
            })
    }
    missionInvitePerson(missionId, inviteeEmail, deets, cb) {
        let details = { ...deets, ...{ invitee: null } }

        const myMission = this.state.missions.find((m) => m._id === missionId)

        apiMission
            .createInvite(missionId, inviteeEmail, details)
            .then((res) => {
                toast.success("Mission invite sent to " + inviteeEmail, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                })

                if (cb) cb({ ...res, ...{ inviteDetails: deets } })

                if (details.autoCreateRole) {
                    this.simpleMissionsAddRoleForNewPerson({ ...deets, ...res }, myMission)
                }

                if (myMission.autoAssign) {
                    this.missionUpdate(missionId, {
                        autoAssign: false,
                    })
                }
            })
            .catch((err) => {
                if (err.code && err.code.replaceAll(" ", "") === "invite/already_invited") {
                    //Makis

                    const personsOnMission = myMission.people.find((p) => getRefId(p) === err.data.personId)

                    if (cb) cb(personsOnMission)

                    if (!personsOnMission) return

                    if (personsOnMission.permission <= 3) {
                        return
                    }

                    this.missionUpdatePerson(
                        missionId,
                        personsOnMission._id,
                        { permission: details.config.permission, isProjectManager: details.config.isProjectManager },
                        null
                    )

                    if (details.autoCreateRole) {
                        this.simpleMissionsAddRoleForNewPerson({ ...deets, ...personsOnMission }, myMission)
                    }

                    toast.success("Mission invite for " + inviteeEmail, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    })

                    return
                }

                try {
                    this.silentFail("welkj983k03---3" + (err?.message + " - " + err?.toString()))

                    if (cb) cb(false)
                    toast.error("Sorry. " + err.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                        hideProgressBar: true,
                    })
                } catch (err) {
                    this.silentFail("welkj983k03---47498" + (err?.message + " - " + err?.toString()))

                    toast.error("Sorry an issue occured. We are investigating. Please try again.")
                }
            })
    }

    missionDeletePerson({ mission, person, noToast }) {
        const missionPerson = mission.people.find((p) => getRefId(p) === getRefId(person)) || person

        if (!missionPerson) {
            throw Error("missionDeletePerson no person")
        }

        return apiMission
            .deleteMissionPeople(mission._id, missionPerson._id)
            .then((res) => {
                if (!noToast)
                    toast(
                        `${
                            getFullName(missionPerson?.ref?.firstName, missionPerson?.ref?.lastName) || "Person"
                        } has been removed.`
                    )
            })
            .catch((err) => {
                this.silentFail("wewww3333qeq-w8" + (err?.message + " - " + err?.toString()))
                throw err
            })
    }
    orgDeletePerson(orgId, refId, ref, options = {}, cb) {
        const org = this.state.orgs.find((o) => o._id === orgId)

        if (!org) {
            window.location.href = "/home"
        }

        const meInOrg = org.people?.find((p) => getRefId(p) === this.state.person._id)
        const isMeBeingRemoved = refId === this.state.person._id
        const numberOfAdmins = org.people?.filter((p) => p.permission === 2).length
        const iAmAnAdmin = meInOrg?.permission === 2

        //Deleteing myselef scenarios

        if (isMeBeingRemoved && numberOfAdmins === 1 && iAmAnAdmin && org.people.length > 1) {
            this.confirm({
                severe: true,
                comp: (
                    <div>
                        <p>🖐️ Warning 🖐️</p>
                        <p>
                            You are the only admin for this organization. If you leave it without making someone else an
                            admin it will be deleted along with all projects.
                        </p>
                        <p>Are you 100% sure you want to delete your organization?</p>
                    </div>
                ),
                onYes: () => {
                    this.orgDelete(orgId)
                },
            })

            return
        }
        if (isMeBeingRemoved && org.people?.length === 1) {
            this.confirm({
                severe: true,
                comp: (
                    <div>
                        <p>🖐️ Warning 🖐️</p>
                        <p>
                            Leaving this org along with all its projects will delete it since you are the only person in
                            it!
                        </p>
                        <p>Are you 100% sure you want to delete this organization?</p>
                    </div>
                ),
                onYes: () => {
                    this.orgDelete(orgId)
                },
            })

            return
        }

        if (isMeBeingRemoved) {
            /*this.confirm({
                    comp: (
                        <div>
                            <p>Hello! You will need to please ask an organization manager or admin to remove you </p>
                        </div>
                    ),
                    yesText: "Ok",
                    singleClick: true,
                    onYes: () => {},
                })*/

            this.confirm({
                severe: true,
                comp: (
                    <div>
                        <p>😢 Leave this organization and all projects?</p>
                    </div>
                ),

                onYes: () => {
                    apiOrg
                        .deleteOrgPeople(org._id, refId, { removeFromMissions: true })
                        .then(() => {
                            toast.success(
                                "Ok! You no longer have access to the organization. Why not start your own? 🦁",
                                {
                                    autoClose: 5000,
                                }
                            )

                            if (cb) cb()

                            this.orgCleanUpDeleted(org._id)
                        })
                        .catch((err) => {
                            toast.error(err.message)
                        })
                },
            })

            return
        }

        //Its not me anymore....

        const personBeingRemoved = org.people.find((p) => getRefId(p) === refId)

        this.confirm({
            severe: true,
            comp: (
                <div>
                    <p>
                        Remove{" "}
                        {getFullName(personBeingRemoved.ref.firstName, personBeingRemoved.ref.lastName, "this person")}?
                        This will remove them from the organization and all projects they are on!
                    </p>
                </div>
            ),
            onYes: () => {
                apiOrg
                    .deleteOrgPeople(org._id, refId, { removeFromMissions: true })
                    .then(() => {
                        toast(`${getFullName(ref.firstName, ref.lastName) || "Person"} has been removed.`)
                        if (cb) cb()
                        this.updateStateForPersonRemovedOrg(refId, orgId, true)
                    })
                    .catch((err) => {
                        toast.warning(err.message, {
                            autoClose: 6000,
                        })
                        this.silentFail("2iu3sss2i3" + (err?.message + " - " + err?.toString()))
                    })
            },
        })
    }
    actionResetItems(action) {
        let myActionItemsAffected = []

        action.actionItems
            .filter((localAi) => localAi.startDate && localAi.status !== "done")
            .forEach((affectedActionItem) => {
                //Reset all the action items
                myActionItemsAffected.push({
                    obj: affectedActionItem,
                    change: {
                        _id: affectedActionItem._id,
                        startDate: null,
                        endDate: null,
                        y: null,
                        estimatedDuration: null,
                        estimatedEndDate: null,
                        startHour: null,
                    },
                })
            })

        if (myActionItemsAffected.length) {
            clearTimeout(this.yourChangesMsgTO)

            this.yourChangesMsgTO = setTimeout(() => {
                if (!toast.isActive("actionKiller"))
                    toast(
                        "🚧  Hi!. " +
                            myActionItemsAffected.length +
                            " action item" +
                            (myActionItemsAffected.length === 1 ? "" : "s") +
                            " " +
                            (myActionItemsAffected.length === 1 ? "was" : "were") +
                            " moved. This affected your weekly plan.",
                        {
                            autoClose: 9000,
                            toastId: "actionKiller",
                        }
                    )
            }, 1000)

            this.actionItemUpdateBulk(action.missionId, myActionItemsAffected)
        }
    }
    actionUpdate(obj, change, ignoreState, cancelReset, cb) {
        if (!ignoreState) this.updateStateForActionUpdate(obj, change, ignoreState)

        if (!cancelReset && change.startDate && change.startDate !== obj.startDate) {
            this.actionResetItems(obj)
        }

        return apiAction.updateAction(obj._id, change).then((res) => {
            if (cb) cb(res)

            const myMission = this.state.missions.find((m) => m._id === obj.missionId)

            if (myMission?.isTemplate) {
                setTimeout(() => {
                    this.missionUpdate(myMission._id, {
                        isTemplateSaved: false,
                    })
                }, 200)
            }
        })
    }
    actionUpdateX(obj, change, ignoreState, cb, stateCb) {
        if (!ignoreState) this.updateStateForActionUpdate(obj, change, false, stateCb)

        return apiAction
            .updateAction(obj._id, change)
            .then((res) => {
                if (cb) cb(res)
                const myMission = this.state.missions.find((m) => m._id === obj.missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionUpdateBulkX(missionId, changes, cb, stateCb) {
        this.updateStateForActionUpdateBulk(missionId, changes, stateCb)

        apiAction
            .updateActionBulk(missionId, changes)
            .then((res) => {
                const myMission = this.state.missions.find((m) => m._id === missionId)
                if (cb) {
                    cb(myMission)
                }

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionUpdateBulk(missionId, actionChanges, updateState, cb) {
        const me = this

        if (actionChanges.length === 0) return

        if (updateState) {
            this.updateStateForActionUpdateBulk(missionId, actionChanges)
        }

        apiAction
            .updateActionBulk(missionId, actionChanges)
            .then((res) => {
                const myMission = this.state.missions.find((m) => m._id === missionId)
                const allActions = getAllActions(myMission)
                actionChanges.forEach((change) => {
                    const theAction = allActions.find((a) => a._id === change._id)

                    if (
                        change.startDate &&
                        this.state.missions.find((m) => m._id === missionId).projectType === "power"
                    ) {
                        this.actionResetItems(theAction)
                    }
                })

                if (cb) {
                    cb(res)
                }

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionCreateX(action, cb, skipState, options) {
        if (
            (action.startDate && !action.endDate) ||
            (!action.startDate && action.endDate) ||
            (action.startDate && action.endDate && action.startDate === action.endDate) ||
            action.startDate > action.endDate
        ) {
            if (!toast.isActive("app-action-create"))
                toast("Sorry was not able to create. Please refresh and try again?", {
                    toastId: "app-action-create",
                })
            return
        }

        if (!action.actionItems) {
            action.actionItems = []
        }

        apiAction
            .createAction(action.planItemId, action, options)
            .then((res) => {
                if (!skipState) {
                    this.updateStateForActionCreate(res)
                }
                if (cb) cb(res)

                const myMission = this.state.missions.find((m) => m._id === res.missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionCreate(action, cb, tip, skipStateUpdate) {
        const me = this

        if (
            (action.startDate && !action.endDate) ||
            (!action.startDate && action.endDate) ||
            (action.startDate && action.endDate && action.startDate === action.endDate) ||
            action.startDate > action.endDate
        ) {
            this.silentFail("ewrkoi48i3e-238383" + action._id)
            toast("Sorry was not able to create. Please try again?")
            return
        }

        if (!action.actionItems) {
            action.actionItems = []
        }
        return apiAction
            .createAction(action.planItemId, action)
            .then((res) => {
                if (tip !== "NONE" && !toast.isActive("activityCreateApp"))
                    toast(tip ? tip : "Successfully created ⚡", {
                        toastId: "activityCreateApp",
                    })

                if (!skipStateUpdate) me.updateStateForActionCreate(res)

                if (cb) {
                    cb(res)
                }

                const myMission = this.state.missions.find((m) => m._id === res.missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionDelete(action, skipState, noToast, cb) {
        apiAction
            .deleteAction(action._id)
            .then((res) => {
                if (!this.skipState) {
                    this.updateStateForActionDelete(action)
                    if (!noToast)
                        toast.success((action.title || "item") + " was deleted", {
                            autoClose: 1000,
                        })
                }

                this.actionRemoveDependenciesAfterDelete(action)

                this.actionReplaceParentIdsAfterDelete(action)

                if (cb) cb(res, action)
            })
            .catch((err) => {
                throw err
            })
    }
    actionRemoveDependenciesAfterDelete(depAction) {
        const mission = this.state.missions.find((m) => m._id === depAction.missionId)

        getAllActions(mission)?.forEach((action, i) => {
            const foundMatch = action.dependencies?.find((d) => d.action._id === depAction._id)

            if (foundMatch) {
                apiAction.removeActionDependency(action._id, depAction._id).catch((err) => {
                    throw err
                })

                let dependencies = action.dependencies || []

                let myIndex = dependencies.find((d) => d.action._id === depAction._id)

                if (myIndex !== -1) {
                    dependencies.splice(myIndex, 1)
                }

                this.updateStateForActionUpdate(action, { dependencies: dependencies })
            }
        })
    }
    actionReplaceParentIdsAfterDelete(action, cb) {
        //Remove all parentId instances

        const myMission = this.state.missions.find((m) => m._id === action.missionId)
        const allActions = getAllActions(myMission)

        const myKids = allActions.filter((a) => a.parentId === action._id)

        let changesArray = []

        myKids.forEach((a, i) => {
            changesArray.push({
                _id: a._id,
                parentId: action.parentId || null,
            })
        })

        if (changesArray.length) {
            this.actionUpdateBulkX(myMission._id, changesArray, null, (res) => {
                if (cb) cb(res)
            })
        }
    }
    actionDeleteX(action, cb) {
        action = { ...action }

        apiAction
            .deleteAction(action._id)
            .then(() => {
                this.actionRemoveDependenciesAfterDelete(action)
                this.updateStateForActionDelete(action, (res) => {
                    if (cb) cb(res, action)
                })
            })
            .catch((err) => {
                throw err
            })
    }
    actionAddDependencyX({ action, depAction, mission, cb }) {
        apiAction
            .addActionDependency(action._id, depAction._id)
            .catch((err) => {
                throw err
            })
            .then((res) => {
                if (cb) {
                    cb(res)
                }
            })

        let dependencies = action.dependencies || []

        let newDep = {
            _id: depAction._id,
            title: depAction.title,
            missionId: depAction.missionId,
            planItemId: depAction.planItemId,
        }
        let newMiss = {
            _id: mission._id,
            title: mission.title,
        }
        let mathcingPi = mission.planItems.find((p) => p._id === depAction.missionPlanId)
        let newPi = {
            _id: mathcingPi._id,
            title: mathcingPi.title,
        }

        dependencies.push({
            action: newDep,
            missionPlan: newPi,
            mission: newMiss,
        })

        this.updateStateForActionUpdate(action, { dependencies: dependencies })
    }
    actionRemoveDependencyX({ action, depAction, mission, cb }) {
        apiAction
            .removeActionDependency(action._id, depAction._id)
            .catch((err) => {
                throw err
            })
            .then((res) => {
                if (cb) {
                    cb(res)
                }
            })

        let dependencies = action.dependencies || []

        let myIndex = dependencies.find((d) => d.action._id === depAction._id)

        if (myIndex !== -1) {
            dependencies.splice(myIndex, 1)
        }

        this.updateStateForActionUpdate(action, { dependencies: dependencies })
    }
    actionAddDependency(actionId, dependencyActionId, action, dependencies) {
        if (!actionId || !dependencyActionId) {
            console.error("id missing")
        }

        apiAction.addActionDependency(actionId, dependencyActionId).catch((err) => {
            throw err
        })

        this.updateStateForActionUpdate(action, { dependencies: dependencies })
    }
    actionRemoveDependency(actionId, dependencyActionId, action, dependencies) {
        if (!actionId || !dependencyActionId) {
            console.error("id missing")
        }

        apiAction.removeActionDependency(actionId, dependencyActionId).catch((err) => {
            throw err
        })

        this.updateStateForActionUpdate(action, { dependencies: dependencies })
    }
    actionItemAddDependency(ai, dependencyAi, linkType = "dependsOn", cb) {
        if (!ai || !dependencyAi) return

        if (!linkType) {
            this.silentFail("we3e9999--" + ai._id)
            toast.warning("Oops. We spotted an issue and the team has been notfied. We will respond asap! ")
            return
        }

        if (ai._id === dependencyAi._id) {
            this.silentFail("wewe727272--" + ai._id)
            return
        }
        return apiActionItem.addDependency(ai._id, dependencyAi._id, linkType).then((res) => {
            const mission = this.state.missions.find((m) => m._id === ai.missionId)
            const allActionItems = getAllActionItems(mission)

            const depAi = allActionItems.find((a) => a._id === dependencyAi._id)

            let newDeps = (depAi.dependencies || []).slice()

            newDeps.push({
                linkFrom: {
                    ...dependencyAi,
                },
                linkTo: {
                    ...ai,
                },
                linkToMission: mission,
                linkType: "dependsOnMe",
            })

            this.updateStateForActionItemUpdate(depAi, {
                dependencies: newDeps,
            })

            this.updateStateForActionItemUpdate(
                ai,
                {
                    dependencies: res,
                },
                false,
                (obj, newMission) => {
                    if (cb) cb(obj, newMission)
                }
            )
        })
    }
    actionItemRemoveDependency(ai, dependencyItem, cb) {
        if (!ai || !dependencyItem) return

        if (ai._id === dependencyItem._id) {
            toast.error("Circular dependency")
            return
        }

        const removeDepIndex = ai.dependencies.findIndex((l) => l.linkTo._id === dependencyItem._id)
        const dependencyToKill = ai.dependencies[removeDepIndex]

        if (dependencyToKill) {
            ai.dependencies.splice(removeDepIndex, 1)
            this.updateStateForActionItemUpdate(
                ai,
                {
                    dependencies: ai.dependencies,
                },
                false,
                () => {
                    if (cb) cb(ai.dependencies)
                }
            )
        }

        if (dependencyItem.dependencies) {
            const removeDepIndexOther = dependencyItem.dependencies.findIndex((l) => l.linkTo._id === ai._id)
            if (removeDepIndexOther !== -1) {
                const dependencyToKillOther = dependencyItem.dependencies[removeDepIndexOther]
                if (dependencyToKillOther) {
                    dependencyItem.dependencies.splice(removeDepIndexOther, 1)
                    this.updateStateForActionItemUpdate(dependencyItem, {
                        dependencies: dependencyItem.dependencies,
                    })
                }
            }
        }

        return apiActionItem.removeDependency(ai._id, getObjectId(dependencyToKill))
    }
    actionItemCreateX(actionId, data, cb) {
        if (!data.seen) {
            data.seen = {
                by: this.state.person._id,
                when: getTimeUtc(new Date()),
            }
        }

        apiActionItem.createActionItem(actionId, data).then((res) => {
            this.updateStateForActionItemCreate(res, () => {
                if (cb) cb(res)
            })
        })
    }
    actionItemCreate(actionId, data, cb, deferUpdate) {
        const me = this

        if (!data.seen) {
            data.seen = {
                by: this.state.person._id,
                when: getTimeUtc(new Date()),
            }
        }

        return apiActionItem
            .createActionItem(actionId, data)
            .then((res) => {
                if (!deferUpdate)
                    me.updateStateForActionItemCreate(res, () => {
                        if (cb) cb(res)
                    })
            })
            .catch((err) => {
                throw err
            })
    }

    checklistItemCreateX({ ai, options, cb }) {
        const myIndex = (ai.checklist || []).sort((a, b) => b?.sortIndex - a?.sortIndex)[0]?.sortIndex || 0

        const saveObj = { ...options, sortIndex: myIndex > 0 ? myIndex + 1 : 0 }

        apiActionItem.addChecklistItem(ai._id, saveObj).then((res) => {
            //obj, change, dontUpdateView, cb, fromSocket
            this.updateStateForActionItemUpdate(
                ai,
                res,
                false,
                () => {
                    if (cb) cb(res)
                },
                true
            )
        })
    }
    checklistUpdateState(ai, res, cb) {
        this.updateStateForActionItemUpdate(
            ai,
            { checklist: res.checklist },
            null,
            () => {
                if (cb) cb()
            },
            true
        )
    }
    checklistItemsUpdateBulk(ai, changeList) {
        apiActionItem
            .updateChecklistItemBulk(ai._id, changeList)
            .then((res) => {
                this.checklistUpdateState(ai, res)
            })
            .catch((err) => {
                throw err.message
            })
    }
    checklistItemUpdateX(ai, cl, change, cb) {
        //Always an Optimist

        if (change.hasOwnProperty("person") && change.person) {
            this.actionItemAddPerson(ai, change.person)
        }

        if (change.hasOwnProperty("done")) {
            change.completedOn = getTimeUtc(new Date())
            change.completedBy = this.state.person._id
        }

        apiActionItem
            .updateChecklistItem(ai._id, cl._id, change)
            .then((res) => {
                this.checklistUpdateState(ai, res, cb)
            })
            .catch((err) => {
                throw err
            })
    }
    checklistItemDeleteX(ai, cl, cb) {
        apiActionItem
            .deleteChecklistItem(ai._id, cl._id)
            .then((res) => {
                this.checklistUpdateState(ai, res, cb)
            })
            .catch((err) => {
                throw err
            })
    }

    actionItemDuplicateX({ ai, options, cb, newMission }) {
        let mission = this.state.missions.find((m) => m._id === (newMission?._id || ai.missionId))

        const aiStartDate = toZonedTime(ai.startDate)
        const aiWeek = toZonedTime(ai.week)
        const missionStartDate = toZonedTime(mission.planStartDate)
        const missionEndDate = toZonedTime(mission.planEndDate)

        let actionId

        if (mission.projectType === "simple" || mission.projectType === "kanban") {
            actionId = getSimpleAction(mission)?._id
        } else {
            actionId = ai.actionId
        }

        if (!actionId) {
            toast.error("Please have an admin or porject manager visit that project before copying ")
            throw Error("actionItemDuplicateX no action")
        }

        let newStartDate = aiStartDate
        let newAiWeek = aiWeek
        let newEndDate = ai.endDate ? toZonedTime(ai.endDate) : undefined
        let newEstimatedDuration = ai.estimatedDuration
        let newStartHour = ai.startHour

        if (aiStartDate && isBefore(aiStartDate, missionStartDate)) {
            newStartDate = toZonedTime(mission.planEndDate)
            newStartHour = getStartHour(newStartDate)
        }

        if (aiWeek && isBefore(aiWeek, startOfISOWeek(missionStartDate))) {
            newAiWeek = startOfISOWeek(missionStartDate)
        }

        if (aiStartDate && isAfter(aiStartDate, missionEndDate)) {
            newStartDate = toZonedTime(mission.planEndDate)
            newEstimatedDuration = 8
            newEndDate = endOfDay(newStartDate)
            newStartHour = getStartHour(newStartDate)
        }

        let cleanCheckList = []

        ai.checklist?.forEach((cl, i) => {
            cleanCheckList.push({
                title: cl.title,
                sortIndex: cl.sortIndex,
                people: newMission ? [] : cl.people,
            })
        })

        const aiObj = {
            week: getTimeUtc(newAiWeek),
            startDate: getTimeUtc(newStartDate),
            startHour: newStartHour,
            endDate: newEndDate ? getTimeUtc(newEndDate) : null,
            people: ai.people,
            title: !newMission && ai.title ? ai.title + " (Copy)" : ai.title,
            description: ai.description,
            sectionTagId: !newMission ? ai.sectionTagId : undefined,
            col: newMission ? "todo" : ai.col,
            estimatedDuration: newEstimatedDuration,
            y: ai.y || 0,
            sortIndex: ai.y || 0,
            tags: newMission ? ai.tags.filter((t) => !isMongoId(t)) : ai.tags || [],
            complexityMultiplier: ai.complexityMultiplier,
            checklist: cleanCheckList,
            billRate: ai.billRate,
            billUnit: ai.billUnit,
            percentComplete: ai.percentComplete,
            templateId: ai.templateId,
            ...options,
        }

        this.actionItemCreateX(actionId, aiObj, (res) => {
            removeParam("action-item")
            toast.success("Created")

            if (ai.files?.length) {
                apiActionItem.copyFiles(ai._id, res._id)
            }

            this.unBlock()
        })
    }

    actionItemUpdateX(ai, change, cb, fromSocket, fromTask) {
        if (change.startDate && !change.initialStartDate) {
            if (!ai.initialStartDate || change.startDate < ai.initialStartDate) {
                change.initialStartDate = change.startDate
            }
        }

        if (change.status === "done") {
            change.completedOn = getTimeUtc(new Date())
            change.completedBy = this.state.person._id
        } else if (change.status) {
            change.completedOn = null
            change.completedBy = null
        }

        this.updateStateForActionItemUpdate(ai, change, false, () => {}, fromSocket)

        apiActionItem
            .updateActionItem(ai._id, change)
            .then((res) => {
                const myMission = this.state.missions.find((m) => m._id === ai.missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }

                if (change.hasOwnProperty("col")) {
                    const myColIndex = myMission.cols.findIndex((c) => c.id === res.col)
                    const myCol = myMission.cols[myColIndex]
                    if (myColIndex !== 0 && myColIndex !== myMission.cols.length - 1) {
                        if (myCol.people?.length) {
                            myCol.people.forEach((newPerson, i) => {
                                const missionPerson = myMission.people.find(
                                    (ppp) => getRefId(ppp) === getRefId(newPerson)
                                )

                                if (
                                    missionPerson &&
                                    missionPerson.permission < 3 &&
                                    !ai.people.find((pp) => getRefId(pp) === getRefId(missionPerson))
                                ) {
                                    this.actionItemAddPerson(ai, getRefId(missionPerson))
                                }
                            })
                        }
                    }
                }

                if (cb) cb(res)
            })
            .catch((err) => {
                this.silentFail("dewkour903iow" + (err?.message + " - " + err?.toString()) + " : " + err)

                toast.error(
                    "So sorry, but we may have encountered an issue. Our team has been notified. Reason:" + err.message
                )
            })
    }

    actionItemUpdate(id, change, obj, cb, skipOpenDoneLogic) {
        const me = this

        if (!obj) {
            console.error("Sorry you did not pass an Object to actionItemUpdate")
            return
        }

        //Catch initial back date for item so we know what timesheets it can appear on
        if (change.startDate && !change.initialStartDate) {
            if (!obj.initialStartDate || change.startDate < obj.initialStartDate) {
                change.initialStartDate = change.startDate
            }
        }

        const myMission = this.state.missions.find((m) => m._id === obj.missionId)

        //Check if being completed
        if (change.status === "done" && obj.status !== "done") {
            if (!change.completedOn) {
                change = {
                    ...change,

                    completedOn: getTimeUtc(new Date().getTime()),
                }
            }
        }
        if (change.status === "done") {
            change = { ...change, completedBy: this.state.person._id }
        } else if (change.status && change.status !== "done") {
            change = { ...change, completedBy: null }
        }

        if (change.status === "open" && obj.status === "done") {
            if (myMission && myMission.projectType === "power" && obj.startDate) {
                change = {
                    ...change,
                    completedBy: null,
                    completedOn: null,
                    startDate: null,
                    estimatedDuration: null,
                    y: null,
                    startHour: null,
                }
            } else {
                change = { ...change, completedBy: null, completedOn: null }
            }
        }

        if (!obj || !change) {
            console.log("Please pass an object.")
            this.silentFail("Please pass an object.")
        } else {
            me.updateStateForActionItemUpdate(obj, change) //just in case
        }

        delete change.people

        if (change.description) {
            change.descriptionUpdateById = this.state.person._id
            change.descriptionUpdate = getTimeUtc(new Date())
        }

        if ((this.state.showDetailsData || this.stateshowDocData) && this.state.showDetailsData._id === obj._id) {
            if (this.state.showDetailsData) {
                this.setState({
                    showDetailsData: update(this.state.showDetailsData, { $merge: change }),
                })
            }

            if (this.state.showDocData) {
                this.setState({
                    showDocData: update(this.state.showDocData, { $merge: change }),
                })
            }
        }

        return apiActionItem
            .updateActionItem(id, change)
            .then((res) => {
                //Should not populate people @mike

                res.people.forEach((p, i) => {
                    res.people[i] = p._id
                })

                if (cb) cb(res)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionItemUpdateBulk(missionId, data, skipState, cb) {
        if (!skipState) {
            this.updateStateForActionItemUpdateBulk(missionId, data, cb)
        }

        //Check initial start date so we know what weeks this applies to for time tracking

        const changeArray = data.map((obj) => {
            const ai = obj.obj
            let change = obj.change || obj.changes

            if (change.startDate && !change.initialStartDate) {
                if (!ai.initialStartDate || change.startDate < ai.initialStartDate) {
                    change.initialStartDate = change.startDate
                }
            }

            if (!change._id) {
                change._id = ai._id
            }

            return change
        })

        return apiActionItem
            .updateActionItemBulk(missionId, changeArray)
            .then((res) => {
                const myMission = this.state.missions.find((m) => m._id === missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                this.silentFail("29837jnd" + (err.message || err))
                throw err
            })
    }
    showActionItemDetails(ai, options) {
        if (typeof ai === "string") {
            ai = flatMap(flatMap(flatMap(this.state.missions, "planItems"), "actions"), "actionItems").find(
                (a) => a._id === ai
            )
        }

        if (!ai || typeof ai === "string") {
            toast.warning("🥑 Sorry this task is no longer available.")
            removeParam("action-item")
            return
        }

        if (!new URLSearchParams(window.location.search).get("action-item")) {
            addParam("action-item", ai._id)
        }

        this.setState({
            showDetailsData: ai,
            showDetailsDataOptions: options,
            timeTracking: this.state.timeTracking,
        })
    }

    async actionItemDeleteBulk(actionItems, cb) {
        this.block("Deleting...")

        const actionItemResults = await Promise.allSettled(
            actionItems.map((ai, i) => {
                return apiActionItem.deleteActionItem(ai._id)
            })
        )

        let deletedAis = actionItemResults.length
            ? actionItemResults.filter((result) => result.status === "fulfilled")
            : []

        if (deletedAis.length > 0) this.updateStateForActionItemDeleteBulk(actionItems)

        this.unBlock()

        if (cb) cb()
    }
    updateStateForActionDeleteBulk(actions, cb) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === actions[0].missionId)

        const mission = this.state.missions[missionIndex]

        let newMissions = this.state.missions.slice()

        actions.forEach((ai, i) => {
            const indexes = getActionItemIndexes(ai, this.state)

            if (!indexes) {
                console.error("indexes issue")
                return
            }

            try {
                newMissions[indexes.missionIndex].planItems[indexes.missionPhaseIndex]?.actions.splice(
                    indexes.missionActionIndex,
                    1
                )
            } catch (err) {
                console.error(err)
            }
        })

        if (cb) {
            cb(newMissions[missionIndex])
        }

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "action",
                        change: "delete buld",
                        timestamp: uniqid(),
                        items: actions,
                        missionId: mission._id,
                    },
                })
            }
        )
    }
    updateStateForActionItemDeleteBulk(actionItems) {
        const mission = this.state.missions.find((m) => m._id === actionItems[0].missionId)

        let newMissions = this.state.missions.slice()

        actionItems.forEach((ai, i) => {
            const indexes = getActionItemIndexes(ai, this.state)

            if (!indexes) {
                console.error("indexes issue")
                return
            }

            try {
                newMissions[indexes.missionIndex].planItems[indexes.missionPhaseIndex]?.actions[
                    indexes.missionActionIndex
                ]?.actionItems?.splice(indexes.missionActionItemIndex, 1)
            } catch (err) {
                console.error(err)
            }
        })

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "actionItems",
                        change: "delete",
                        timestamp: uniqid(),
                        items: [actionItems],
                        missionId: mission._id,
                    },
                })
            }
        )
    }
    actionItemDeleteX(ai, mission, cb) {
        this.updateStateForActionItemDelete(ai, cb)

        apiActionItem
            .deleteActionItem(ai._id)
            .then((res) => {
                if (this.state.showDetailsData?._id === ai._id) {
                    this.setState({
                        showDetailsData: null,
                        showDetailsDataOptions: null,
                    })
                }
                if (mission.isTemplate) {
                    this.missionUpdate(mission._id, {
                        isTemplateSaved: false,
                    })
                }
                toast.success("Done", { autoClose: 500 })

                if (mission.isProcess || mission.projectType === "mx-gantt") {
                    let pisNeedUpdating = mission.planItems.filter((p) =>
                        p.raciOnTasks?.find((r) => r.taskId === ai._id)
                    )
                    let changesArray = []
                    pisNeedUpdating.forEach((pi, i) => {
                        let newRaciOnTasks = pi.raciOnTasks.slice().filter((r) => r.taskId !== ai._id)

                        changesArray.push({
                            obj: pi,
                            change: {
                                _id: pi._id,
                                raciOnTasks: newRaciOnTasks,
                            },
                        })

                        if (changesArray.length) {
                            this.planItemUpdateBulk(mission._id, changesArray)
                        }
                    })
                }
            })
            .catch((err) => {
                throw err
            })
    }
    actionItemDelete(obj, skipState) {
        const myMission = this.state.missions.find((m) => m._id === obj.missionId)

        if (!skipState) this.updateStateForActionItemDelete(obj)

        apiActionItem.deleteActionItem(obj._id).then((res) => {
            if (myMission?.isTemplate) {
                setTimeout(() => {
                    this.missionUpdate(myMission._id, {
                        isTemplateSaved: false,
                    })
                }, 200)
            }
        })
    }
    actionItemAddPerson(actionItem, personId) {

        debugger
        const id = actionItem?._id

        if (actionItem.people?.find((p) => getRefId(p) === personId)) {
            return
        }

        apiActionItem
            .addActionItemPeople(id, personId)
            .then((res) => {
                const alt = {
                    people: res.people,
                    peopleAttached: res.peopleAttached,
                }

                if (this.state.showDetailsData && this.state.showDetailsData._id === res._id) {
                    this.setState({
                        showDetailsData: { ...this.state.showDetailsData, ...alt },
                    })
                }
                this.updateStateForActionItemUpdate(res, alt)
            })
            .catch((err) => {
                this.silentFail("wejwie++we" + (err?.message + " - " + err?.toString()))
            })
    }
    actionItemRemovePerson(actionItem, personId, cb) {
        const id = actionItem?._id || actionItem
        apiActionItem
            .deleteActionItemPeople(id, personId)
            .then((res) => {
                this.updateStateForActionItemUpdate(res, { people: res.people }, false, cb)
            })

            .catch((err) => {
                this.silentFail("actionItemRemovePerson-3u983" + (err?.message + " - " + err?.toString()))
            })
    }
    actionItemRemovePersonAll(id, cb) {
        return apiActionItem
            .deleteActionItemPeopleAll(id)
            .then((res) => {
                this.updateStateForActionItemUpdate(res, { people: res.people }, null, () => {
                    if (cb) {
                        cb()
                    }
                })
            })
            .catch((err) => {
                throw err
            })
    }
    planItemCreateX(missionId, obj, cb) {
        return apiPlanItems.createPlanItem(missionId, obj).then((res) => {
            this.updateStateForPlanItemCreate(res)

            if (cb) cb(res)

            const myMission = this.state.missions.find((m) => m._id === missionId)

            if (myMission?.isTemplate) {
                setTimeout(() => {
                    this.missionUpdate(myMission._id, {
                        isTemplateSaved: false,
                    })
                }, 200)
            }
        })
    }
    planItemCreate(missionId, obj, cb, skipDateMatch) {
        const miss = this.state.missions.find((m) => m._id === missionId)

        return apiPlanItems
            .createPlanItem(missionId, obj)
            .then((res) => {
                this.updateStateForPlanItemCreate(res)

                if (!skipDateMatch) {
                    this.missionPlanDates(missionId)
                    this.missionUpdatePinnedItems(missionId)
                }

                if (cb) {
                    cb(res)
                }

                if (miss?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(miss._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    async planItemCreateBulk(data) {
        /*this.block("One moment. Creating entries...")

        const planItemResults = await Promise.allSettled(
            data.map((planItemConfig) => {
                if (!start || !end) return null

                return apiPlanItems.createPlanItem(mission._id, planItemConfig)
            })
        )

        let planItemsCreated = []

        planItemResults
            .filter((result) => result.status === "fulfilled")
            .forEach((result, i) => {
                if (result.value) planItemsCreated.push(result.value)
            })

        if (planItemsCreated.length) this.updateStateForPlanItemCreateBulk(mission._id, planItemsCreated)*/
    }
    planItemAddDependency(planItem, dependencyItem, linkType = "dependsOn") {
        return apiPlanItems.addDependency(planItem._id, dependencyItem._id, linkType).catch((err) => {
            throw err
        })
        const myMission = this.state.missions.find((m) => m._id === planItem.missionId)

        if (myMission?.isTemplate) {
            setTimeout(() => {
                this.missionUpdate(myMission._id, {
                    isTemplateSaved: false,
                })
            }, 200)
        }
    }
    planItemRemoveDependency(planItem, dependencyItem) {
        if (!planItem || !dependencyItem) return
        planItem = { ...planItem }

        const mission = this.state.missions.find((m) => m._id === planItem.missionId)
        if (typeof dependencyItem === "string") {
            dependencyItem = mission.planItems.find((p) => p._id === dependencyItem)
        }

        const removeDepIndex = planItem.dependencies.findIndex((l) => l.linkTo._id === dependencyItem._id)
        const dependencyToKill = planItem.dependencies[removeDepIndex]

        if (dependencyToKill) {
            planItem.dependencies.splice(removeDepIndex, 1)
            this.updateStateForPlanItemUpdate(planItem, {
                dependencies: planItem.dependencies,
            })
        }

        const removeDepIndexOther = dependencyItem.dependencies.findIndex((l) => l.linkTo._id === planItem._id)
        const dependencyToKillOther = dependencyItem.dependencies[removeDepIndexOther]

        if (dependencyToKillOther) {
            dependencyItem.dependencies.splice(removeDepIndexOther, 1)
            this.updateStateForPlanItemUpdate(dependencyItem, {
                dependencies: dependencyItem.dependencies,
            })
        }

        if (mission?.isTemplate) {
            setTimeout(() => {
                this.missionUpdate(mission._id, {
                    isTemplateSaved: false,
                })
            }, 200)
        }

        return apiPlanItems.removeDependency(planItem._id, dependencyToKill._id).catch((err) => {
            throw err
        })
    }
    planItemUpdateX(pi, change, cb) {
        if (!dateValidationPass({ obj: pi, ...change })) {
            throw new Error("Invalid date values for planItemUpdateX")
        }

        this.updateStateForPlanItemUpdate(pi, change)

        return apiPlanItems
            .updatePlanItem(pi.missionId, pi._id, change)
            .then((res) => {
                if (cb) cb(res)

                const myMission = this.state.missions.find((m) => m._id === pi.missionId)

                if (myMission?.isTemplate) {
                    setTimeout(() => {
                        this.missionUpdate(myMission._id, {
                            isTemplateSaved: false,
                        })
                    }, 200)
                }
            })
            .catch((err) => {
                throw err
            })
    }
    planItemRemoveRole(pi) {
        const startDateAfterToday = isAfter(toZonedTime(pi.startDate), new Date())
        const todayAfterEndDate = isAfter(new Date(), toZonedTime(pi.endDate))

        const whatIsTheEndDate = startDateAfterToday
            ? endOfDay(toZonedTime(pi.startDate))
            : todayAfterEndDate
            ? toZonedTime(pi.endDate)
            : endOfDay(new Date())

        const change = {
            person: null,
            endDate: getTimeUtc(whatIsTheEndDate),
            pinnedTo: null,
            personLeftRole: {
                when: getTimeUtc(new Date()),
                personId: pi.person,
                whoKilledTheRole: this.state.person._id,
            },
        }

        this.updateStateForPlanItemUpdate(pi, change)

        return apiPlanItems.updatePlanItem(pi.missionId, pi._id, change).then((res) => {
            toast("The project role was removed.")

            const myMission = this.state.missions.find((m) => m._id === res.missionId)

            if (myMission?.isTemplate) {
                setTimeout(() => {
                    this.missionUpdate(myMission._id, {
                        isTemplateSaved: false,
                    })
                }, 200)
            }
        })
    }
    planItemUpdate(obj, changeObj, skipMin, skipDateMatch) {
        if (!dateValidationPass({ obj, ...changeObj })) {
            throw new Error("Invalid date values for planItemUpdateX")
        }

        if (!obj || !changeObj) return

        obj = { ...obj }
        changeObj = { ...changeObj }

        if (obj.type === "milestone") {
            skipMin = true
            skipDateMatch = true
        }

        if (typeof obj.startDate === "object") obj.startDate = getTimeUtc(obj.startDate)

        if (typeof obj.endDate === "object") obj.endDate = getTimeUtc(obj.endDate)

        if (typeof changeObj.startDate === "object") changeObj.startDate = getTimeUtc(changeObj.startDate)

        if (typeof changeObj.endDate === "object") changeObj.endDate = getTimeUtc(changeObj.endDate)

        if (changeObj.endDate || changeObj.startDate) {
            const zeStartDate = changeObj.startDate || obj.startDate
            const zeEndDate = changeObj.endDate || obj.endDate

            if (changeObj.endDate && changeObj.endDate <= zeStartDate) {
                changeObj.endDate = getTimeUtc(addDays(toZonedTime(zeStartDate), 5))
            }
            if (changeObj.startDate && changeObj.startDate >= zeEndDate) {
                changeObj.endDate = getTimeUtc(addDays(toZonedTime(changeObj.startDate), 5))
            }
        }

        this.updateStateForPlanItemUpdate(obj, changeObj)

        if (!skipDateMatch && (changeObj.startDate || changeObj.endDate)) {
            setTimeout(() => {
                this.missionPlanDates(obj.missionId)
            }, 10)
            setTimeout(() => {
                this.missionUpdatePinnedItems(obj.missionId)
            }, 20)
        }

        const myMission = this.state.missions.find((m) => m._id === obj.missionId)

        if (myMission?.isTemplate) {
            setTimeout(() => {
                this.missionUpdate(myMission._id, {
                    isTemplateSaved: false,
                })
            }, 200)
        }

        return apiPlanItems.updatePlanItem(obj.missionId, obj._id, changeObj)
    }
    planItemUpdateBulk(missionId, changes, noStateChange) {
        let sendOff = []

        if (!noStateChange) {
            this.updateStateForPlanItemUpdateBulk(changes)

            changes.forEach((pi) => {
                sendOff.push({
                    ...pi.change,
                    _id: pi.obj._id,
                })
            })

            if (sendOff.length) return apiPlanItems.updatePlanItemBulk(missionId, sendOff)
        } else {
            if (changes.length) return apiPlanItems.updatePlanItemBulk(missionId, changes)
        }

        const myMission = this.state.missions.find((m) => m._id === missionId)

        if (myMission?.isTemplate) {
            setTimeout(() => {
                this.missionUpdate(myMission._id, {
                    isTemplateSaved: false,
                })
            }, 200)
        }
    }
    planItemRemoveX(pi) {
        this.updateStateForPlanItemRemove(pi)
        const myMission = this.state.missions.find((m) => m._id === pi.missionId)
        if (myMission?.isTemplate) {
            setTimeout(() => {
                this.missionUpdate(myMission._id, {
                    isTemplateSaved: false,
                })
            }, 200)
        }

        return apiPlanItems.deletePlanItem(pi._id)
    }
    planItemRemove(mission, obj, skipDates) {
        if (!mission || typeof mission === "string") {
            mission = this.state.missions.find((m) => m._id === obj.missionId)
        }
        if (!mission) return

        const removeLogic = () => {
            this.updateStateForPlanItemRemove(obj)

            if (!skipDates && obj.type !== "cost") {
                setTimeout(() => {
                    this.planItemCleanUpPinnedTo(mission, obj)
                }, 300)

                setTimeout(() => {
                    this.planItemCleanUpDependencies(obj, mission)
                }, 100)

                setTimeout(() => {
                    this.missionPlanDates(mission._id)
                }, 150)
            }

            const myMission = this.state.missions.find((m) => m._id === mission._id)

            if (myMission?.isTemplate) {
                setTimeout(() => {
                    this.missionUpdate(myMission._id, {
                        isTemplateSaved: false,
                    })
                }, 200)
            }

            return apiPlanItems.deletePlanItem(obj._id)
        }

        return removeLogic()
    }
    planItemCleanUpPinnedTo(mission, planItem) {
        let changeArray = []

        mission.planItems
            .filter((pi) => pi.pinnedTo === planItem._id)
            .forEach((pi, i) => {
                changeArray.push({
                    obj: pi,
                    change: {
                        _id: pi._id,
                        pinnedTo: null,
                    },
                })
            })

        if (changeArray.length) {
            this.planItemUpdateBulk(mission._id, changeArray)
        }
    }
    planItemCleanUpDependencies(planItem, mission) {
        let changesArray = []
        let newPhases = mission.planItems.slice()

        newPhases = newPhases.filter((p) => {
            return (
                p.myDependencies &&
                p.dependencyOnMe &&
                (p.myDependencies.indexOf(planItem._id) !== -1 || p.dependencyOnMe.indexOf(planItem._id) !== -1)
            )
        })

        newPhases.forEach((pi, i) => {
            const index1 = pi.myDependencies.findIndex((d) => d === planItem._id)

            if (index1 !== -1) {
                pi.myDependencies.splice(index1, 1)
                changesArray.push({
                    obj: pi,
                    change: {
                        _id: pi._id,
                        myDependencies: pi.myDependencies,
                    },
                })
            }
            const index2 = pi.dependencyOnMe.findIndex((d) => d === planItem._id)
            if (index2 !== -1) {
                pi.dependencyOnMe.splice(index2, 1)
                changesArray.push({
                    obj: pi,
                    change: {
                        _id: pi._id,
                        dependencyOnMe: pi.dependencyOnMe,
                    },
                })
            }
        })

        if (changesArray.length) {
            this.planItemUpdateBulk(planItem.missionId, changesArray)
        }
    }

    orgCreate(data, logo, cb) {
        apiOrg
            .createOrg(data)
            .then((newOrg) => {
                if (logo) {
                    apiOrg.uploadLogo(newOrg._id, logo)
                }

                const meInOrgIndex = newOrg.people.findIndex((p) => getRefId(p) === this.state.person._id)

                newOrg.people[meInOrgIndex].ref = {
                    _id: this.state.person._id,
                    firstName: this.state.person.firstName,
                    lastName: this.state.person.lastName,
                    avatar: this.state.person.avatar,
                }

                this.setState(
                    {
                        orgs: update(this.state.orgs, { $push: [newOrg] }),
                    },
                    () => {
                        if (cb) cb(newOrg)

                        if (getQ()["create-proposal"]) {
                            return
                        }

                        removeParam("hq")

                        if (!getQ().mc) {
                            addParams([
                                { param: "org", value: newOrg._id },
                                { param: "org-cfg", value: "open" },
                            ])
                        } else {
                            addParam("mc-org", newOrg._id)
                        }
                    }
                )

                toast.success("Success 👍")
            })
            .catch((err) => {
                throw err
            })
    }
    orgDelete(id) {
        removeParams("org,hq,mc-org")

        this.orgCleanUpDeleted(id)

        apiOrg
            .deleteOrg(id)
            .then((res) => {
                toast.success("Org deleted as requested. help@missionx.ai")
                this.updateTimeTracking()
            })
            .catch((err) => {
                throw err
                //toast.error("I'm sorry but I was not able to delete this organization. help@missionx.ai")
            })
    }
    orgUpdate(id, change, cb) {
        return apiOrg
            .updateOrg(id, change)
            .then((res) => {
                this.orgUpdateState(res, change)

                if (cb) {
                    cb(res)
                }
            })
            .catch((err) => {
                switch (err.status) {
                    case 400:
                        return toast.error("A bad request received. Try again and if the problem persists constact us.")
                    case 401:
                        return toast.error("Make sure you have the right permissions to perform this action.")
                    case 402:
                        return toast.error("Requires an organization license.")
                    default:
                        throw err
                }
            })
    }
    orgUpdateState(obj, change, cb) {
        const orgIndex = this.state.orgs.findIndex((o) => o._id === obj._id)

        if (orgIndex !== -1) {
            this.setState(
                {
                    orgs: update(this.state.orgs, {
                        [orgIndex]: { $merge: change },
                    }),
                },
                () => {
                    this.setState(
                        {
                            orgChange: {
                                _id: obj._id,
                                timestamp: uniqid(),
                                obj: obj,
                            },
                        },
                        () => {
                            if (cb) {
                                cb(this.state.orgs[orgIndex])
                            }
                        }
                    )
                }
            )
        }
    }
    orgClientUpdate(org, client, change, cb) {
        apiOrg
            .updateOrgClient(org._id, client._id, change)
            .then((res) => {
                const orgIndex = this.state.orgs.findIndex((o) => o._id === org._id)
                const clientIndex = this.state.orgs[orgIndex].clients.findIndex((o) => o._id === client._id)

                if (orgIndex !== -1 && clientIndex !== -1) {
                    const newOrgs = update(this.state.orgs, {
                        [orgIndex]: {
                            clients: { [clientIndex]: { $merge: change } },
                        },
                    })

                    this.setState(
                        {
                            orgs: newOrgs,
                        },
                        () => {
                            if (cb) cb(res)
                        }
                    )
                }
            })
            .catch((err) => {
                throw err
            })
    }
    orgClientCreate(org, data, cb) {
        apiOrg
            .createOrgClient(org._id, data)
            .then((res) => {
                const orgIndex = this.state.orgs.findIndex((o) => o._id === org._id)

                if (orgIndex !== -1) {
                    const newOrgs = update(this.state.orgs, {
                        [orgIndex]: {
                            clients: { $push: [res] },
                        },
                    })

                    this.setState(
                        {
                            orgs: newOrgs,
                        },
                        () => {
                            if (cb) cb(res)
                        }
                    )
                }
            })
            .catch((err) => {
                throw err
            })
    }
    orgClientRemove(org, client, cb) {
        apiOrg.deleteOrgClient(org._id, client._id).then((res) => {
            const orgIndex = this.state.orgs.findIndex((o) => o._id === org._id)
            const clientIndex = this.state.orgs[orgIndex].clients.findIndex((o) => o._id === client._id)

            if (orgIndex !== -1 && clientIndex !== -1) {
                const newOrgs = update(this.state.orgs, {
                    [orgIndex]: {
                        clients: { $splice: [[clientIndex, 1]] },
                    },
                })
                this.setState(
                    {
                        orgs: newOrgs,
                    },
                    () => {
                        if (cb) cb(res)
                    }
                )
            }
        })
        // TODO: Handle this
    }

    orgCleanUpDeleted(id) {
        const me = this

        const orgIndex = this.state.orgs.findIndex((o) => o._id === id)

        let newMissions = this.state.missions.slice()

        newMissions = newMissions.filter((m) => getObjectId(m.org) !== id)

        if (orgIndex !== -1)
            me.setState(
                {
                    missions: newMissions,
                    orgs: update(this.state.orgs, { $splice: [[orgIndex, 1]] }),
                },
                () => {}
            )
    }
    updateStateForGoalChange(org, goal) {
        const orgIndex = this.state.orgs.findIndex((o) => o._id === org._id)

        const fgIndex = org.goals.findIndex((g) => g._id === goal._id)

        const upOrg = update(this.state.orgs, {
            [orgIndex]: {
                goals: {
                    [fgIndex]: { $set: goal },
                },
            },
        })

        const orgGoalState = upOrg

        this.setState({
            orgs: orgGoalState,
        })
    }
    updateStateForGoalDelete(orgId, goalId) {
        const orgIndex = this.state.orgs.findIndex((o) => o._id === orgId)

        const fgIndex = this.state.orgs[orgIndex].goals.findIndex((g) => g._id === goalId)

        const upOrg = update(this.state.orgs, {
            [orgIndex]: {
                goals: { $splice: [[fgIndex, 1]] },
            },
        })

        const orgGoalState = upOrg

        this.setState({
            orgs: orgGoalState,
        })
    }
    updateStateForGoalCreate(org, goal) {
        const orgIndex = this.state.orgs.findIndex((o) => o._id === org._id)

        const upOrg = update(this.state.orgs, {
            [orgIndex]: {
                goals: { $push: [goal] },
            },
        })

        const orgGoalState = upOrg

        this.setState({
            orgs: orgGoalState,
        })
    }
    updateStateForPersonRemoved(personId, missionId) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === missionId)
        const mission = this.state.missions[missionIndex]

        if (!mission) {
            return
        }

        let actions = getAllActions(mission).slice()

        ;(actions || []).forEach((a) => {
            a.actionItems.forEach((ai, i) => {
                if (!ai.people) return

                let pIndex = ai.people.findIndex((obj) => obj._id === personId)
                a.actionItems[i].people.splice(pIndex, 1)
            })
        })

        if (missionIndex === -1) return

        const missionPeopleIndex = this.state.missions[missionIndex].people.findIndex((mp) => {
            if (mp.ref) {
                return getRefId(mp) === personId
            } else {
                return false
            }
        })

        if (missionPeopleIndex === -1) return

        this.setState(
            {
                missions: update(this.state.missions, {
                    [missionIndex]: {
                        people: { $splice: [[missionPeopleIndex, 1]] },
                    },
                }),
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "mission",
                        change: "people",
                        timestamp: uniqid(),
                        missionId: this.state.missions[missionIndex]._id,
                    },
                })
            }
        )
    }
    updateStateForPersonRemovedOrg(refId, orgId) {
        const orgIndex = this.state.orgs.findIndex((m) => m._id === orgId)
        const personIndex = this.state.orgs[orgIndex]?.people?.findIndex((mp) => getRefId(mp) === refId)

        let newOrgs = this.state.orgs.slice()

        if (personIndex !== -1) {
            newOrgs = update(this.state.orgs, {
                [orgIndex]: {
                    people: { $splice: [[personIndex, 1]] },
                },
            })
        } else {
        }

        this.setState(
            {
                orgs: newOrgs,
            },
            () => {
                this.state.missions
                    .filter((m) => getObjectId(m.org) === orgId)
                    .forEach((m) => {
                        const missionPerson = (m.people || []).find((mPerson) => getRefId(mPerson) === refId)

                        if (missionPerson) {
                            this.updateStateForPersonRemoved(missionPerson._id, m._id)
                        }
                    })
            }
        )
    }
    updateStateForPersonAvatar(obj, isAvatarChange) {
        let newMissions = this.state.missions.slice()
        let newOrgs = this.state.orgs.slice()

        newMissions.forEach((m, ind) => {
            let i = m.people.findIndex((p) => getRefId(p) === obj._id)
            if (i !== -1 && typeof newMissions[ind].people[i].ref === "object") {
                newMissions[ind].people[i].ref.avatar = obj.avatar
            }
        })

        newOrgs.forEach((o, ind) => {
            if (o.people) {
                let i = o.people.findIndex((p) => getRefId(p) === obj._id)
                if (i !== -1 && typeof newOrgs[ind].people[i].ref === "object") {
                    newOrgs[ind].people[i].ref.avatar = obj.avatar
                }
            }
        })

        this.setState(
            {
                missions: newMissions,
                orgs: newOrgs,

                avKey: isAvatarChange ? uniqid() : this.state.avKey,
            },
            () => {
                if (isAvatarChange) this.forceUpdate()
            }
        )
    }
    updateStateForActionItemCreateBulk(actionItems, mission, fastCallBack) {
        let missions = this.state.missions.slice()
        const mIndex = missions.findIndex((m) => m._id === mission._id)

        actionItems.forEach((obj, i) => {
            let indexes = getActionItemIndexes(obj, this.state)

            missions = update(missions, {
                [indexes.missionIndex]: {
                    planItems: {
                        [indexes.missionPhaseIndex]: {
                            actions: {
                                [indexes.missionActionIndex]: {
                                    actionItems: { $push: [obj] },
                                },
                            },
                        },
                    },
                },
            })
        })

        if (fastCallBack) {
            fastCallBack(missions[mIndex])
        }

        this.setState(
            {
                missions: missions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "actionItem",
                        change: "create-bulk",
                        changes: actionItems,
                        timestamp: uniqid(),
                        missionId: mission?._id,
                    },
                })
            }
        )
    }
    updateStateForActionUpdateBulk(missionId, changes, cb) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === missionId)

        const mission = this.state.missions[missionIndex]

        const planItemIndex = mission.planItems.findIndex((p) => p.type === "phase")

        const planItem = mission.planItems[planItemIndex]

        let newActions = planItem.actions.slice()

        changes.forEach((change, i) => {
            let ind = newActions.findIndex((a) => a._id === change._id)

            newActions[ind] = { ...newActions[ind], ...change }
        })

        this.setState(
            {
                missions: update(this.state.missions, {
                    [missionIndex]: {
                        planItems: {
                            [planItemIndex]: {
                                actions: { $set: newActions },
                            },
                        },
                    },
                }),
            },
            () => {
                this.setState(
                    {
                        missionChange: {
                            for: "action",
                            change: "bulk",
                            timestamp: uniqid(),
                            changes: changes,
                            missionId: missionId,
                        },
                    },
                    () => {
                        const m = this.state.missions[missionIndex]

                        if (cb) cb(m)
                    }
                )
            }
        )
    }
    updateStateForActionCreateBulk(actions) {
        //Supports simple and kanban and gantt only
        const action = actions[0]

        const myMissionIndex = this.state.missions.findIndex((m) => m._id === action.missionId)

        if (myMissionIndex === -1) return null

        const myPhaseInMissionIndex = this.state.missions[myMissionIndex].planItems.findIndex(
            (p) => p._id === action.missionPlanId
        )
        if (myPhaseInMissionIndex === -1) return null

        let newMissions = this.state.missions.slice()

        const newActionsForMission = uniqBy(
            [...newMissions[myMissionIndex].planItems[myPhaseInMissionIndex].actions, ...actions],
            "_id"
        )

        newMissions[myMissionIndex].planItems[myPhaseInMissionIndex].actions = newActionsForMission

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "action",
                        change: "create-bulk",
                        changes: actions,
                        timestamp: uniqid(),
                        missionId: this.state.missions[myMissionIndex]._id,
                    },
                })
            }
        )
    }
    updateStateForActionCreate(obj) {
        let missionIndex = this.state.missions.findIndex((m) => m._id === obj.missionId)

        if (missionIndex === -1) {
            window.location.href = window.location.href
            return
        }

        const missionPhaseIndex = this.state.missions[missionIndex].planItems.findIndex(
            (m) => m._id === obj.missionPlanId
        )

        this.setState(
            {
                missions: update(this.state.missions, {
                    [missionIndex]: {
                        planItems: {
                            [missionPhaseIndex]: { actions: { $push: [obj] } },
                        },
                    },
                }),
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "action",
                        change: "new",
                        timestamp: uniqid(),
                        obj: obj,
                        missionId: this.state.missions[missionIndex]._id,
                    },
                })
            }
        )
    }
    updateStateForActionUpdate(obj, change, waitToUpdate, cb) {
        let indexes = getActionItemIndexes(obj, this.state)

        if (!indexes) {
            return
        }

        const newMissions = this.state.missions.slice()

        newMissions[indexes.missionIndex].planItems[indexes.missionPhaseIndex].actions[indexes.missionActionIndex] = {
            ...obj,
            ...change,
        }

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                if (cb) {
                    cb(this.state.missions[indexes.missionIndex], {
                        ...obj,
                        ...change,
                    })
                }
                if (!waitToUpdate)
                    this.setState({
                        missionChange: {
                            for: "action",
                            change: change,
                            timestamp: uniqid(),
                            obj: obj,
                            missionId: obj.missionId,
                        },
                    })
            }
        )
    }
    updateStateForActionDelete(obj, cb) {
        let indexes = getActionItemIndexes(obj, this.state)

        this.setState(
            {
                missions: update(this.state.missions, {
                    [indexes.missionIndex]: {
                        planItems: {
                            [indexes.missionPhaseIndex]: {
                                actions: {
                                    $splice: [[indexes.missionActionIndex, 1]],
                                },
                            },
                        },
                    },
                }),
            },
            () => {
                if (cb) {
                    cb(this.state.missions[indexes.missionIndex])
                }
                this.setState({
                    missionChange: {
                        for: "action",
                        change: "delete",
                        obj: obj,
                        timestamp: uniqid(),
                        missionId: obj.missionId,
                    },
                })
            }
        )
    }
    updateStateForActionItemCreate(obj, cb) {
        const indexes = getActionItemIndexes(obj, this.state)

        if (!indexes) {
            console.error("indexs issue")
            return
        }

        this.setState(
            {
                missions: update(this.state.missions, {
                    [indexes.missionIndex]: {
                        planItems: {
                            [indexes.missionPhaseIndex]: {
                                actions: {
                                    [indexes.missionActionIndex]: {
                                        actionItems: { $push: [obj] },
                                    },
                                },
                            },
                        },
                    },
                }),
            },
            () => {
                this.setState(
                    {
                        missionChange: {
                            for: "actionItem",
                            change: "create",
                            timestamp: uniqid(),
                            obj: obj,
                            missionId: obj.missionId,
                        },
                    },
                    () => {
                        if (cb) cb()

                        const m = this.state.missions[indexes.missionIndex]

                        if (m.isProcess && m.isTemplate) {
                            setTimeout(() => {
                                //syncProcessFlow({ mission: m, app: this })
                            }, 500)
                        }
                    }
                )
            }
        )
    }

    updateStateForActionItemUpdateBulk(mission, changes, cb) {
        if (changes.length === 0 || !mission) return

        if (typeof mission === "string") {
            mission = this.state.missions.find((m) => m._id === mission)
        }
        if (!mission) {
            console.error("Bulk AI update requires mission")
            return
        }

        let newMissions = this.state.missions.slice()
        let newShowDetailsData

        changes.forEach((change, i) => {
            let c = change.change || change.changes

            const indexes = getActionItemIndexes(change.obj, this.state)

            if (this.state.showDetailsData?._id === change.obj._id) {
                newShowDetailsData = { ...newShowDetailsData, ...c }
            }

            if (c.actionId && c.actionId !== change.obj.actionId) {
                //Move to another action

                //Remove
                newMissions = update(newMissions, {
                    [indexes.missionIndex]: {
                        planItems: {
                            [indexes.missionPhaseIndex]: {
                                actions: {
                                    [indexes.missionActionIndex]: {
                                        actionItems: { $splice: [[indexes.missionActionItemIndex, 1]] },
                                    },
                                },
                            },
                        },
                    },
                })

                //Move to new action

                newMissions = update(newMissions, {
                    [indexes.missionIndex]: {
                        planItems: {
                            [indexes.missionPhaseIndex]: {
                                actions: {
                                    [indexes.missionActionIndex]: {
                                        actionItems: { $push: [{ ...change.obj, ...c }] },
                                    },
                                },
                            },
                        },
                    },
                })
            } else {
                newMissions = update(newMissions, {
                    [indexes.missionIndex]: {
                        planItems: {
                            [indexes.missionPhaseIndex]: {
                                actions: {
                                    [indexes.missionActionIndex]: {
                                        actionItems: {
                                            [indexes.missionActionItemIndex]: { $merge: c },
                                        },
                                    },
                                },
                            },
                        },
                    },
                })
            }
        })

        let updateObj = {
            missions: newMissions,
        }

        if (newShowDetailsData) {
            updateObj.showDetailsData = newShowDetailsData
        }

        this.setState(updateObj, () => {
            if (cb) cb(this.state.missions.find((m) => m._id === getObjectId(mission)))

            this.setState({
                missionChange: {
                    timestamp: uniqid(),
                    for: "actionItem",
                    change: "bulk",
                    changes: changes,
                    missionId: mission?._id,
                },
            })
        })
    }
    updateStateForActionItemUpdate(obj, change, dontUpdateView, cb, fromSocket) {
        if (!change) {
            console.error("change missing!")
            return
        }

        const indexes = getActionItemIndexes(obj, this.state)
        const newState = {}

        if (!indexes) {
            return
        }

        if (this.state.showDetailsData && this.state.showDetailsData._id === obj._id) {
            newState.showDetailsData = update(this.state.showDetailsData, { $merge: change })
        }

        if (indexes.missionActionItemIndex > -1) {
            newState.missions = update(this.state.missions, {
                [indexes.missionIndex]: {
                    planItems: {
                        [indexes.missionPhaseIndex]: {
                            actions: {
                                [indexes.missionActionIndex]: {
                                    actionItems: {
                                        [indexes.missionActionItemIndex]: { $merge: change },
                                    },
                                },
                            },
                        },
                    },
                },
            })
        }

        if (!Object.keys(newState).length) {
            return
        }

        this.setState(newState, () => {
            if (cb) cb({ ...obj, ...change }, newState.missions[indexes.missionIndex])

            if (!dontUpdateView)
                this.setState({
                    missionChange: {
                        for: "actionItem",
                        from: fromSocket ? "socket" : null,
                        change: change,
                        timestamp: uniqid(),
                        obj: { ...obj, ...obj.change },
                        missionId: obj.missionId,
                    },
                })
        })
    }
    updateStateForActionItemDelete(obj, cb) {
        const indexes = getActionItemIndexes(obj, this.state)

        if (!indexes) {
            console.error("indexes issue")
            return
        }

        const missions = update(this.state.missions, {
            [indexes.missionIndex]: {
                planItems: {
                    [indexes.missionPhaseIndex]: {
                        actions: {
                            [indexes.missionActionIndex]: {
                                actionItems: { $splice: [[indexes.missionActionItemIndex, 1]] },
                            },
                        },
                    },
                },
            },
        })

        let allActionItems = getAllActionItems(this.state.missions[indexes.missionIndex])

        allActionItems.splice(indexes.missionActionItemIndex, 1)

        let bulkChanges = []

        allActionItems.forEach((ai, i) => {
            const inDepIndex = ai.dependencies
                ? ai.dependencies.findIndex((d) => {
                      return d?.linkTo?._id === obj._id
                  })
                : -1

            if (inDepIndex !== -1) {
                ai.dependencies.splice(inDepIndex, 1)
                bulkChanges.push({
                    obj: ai,
                    change: {
                        _id: ai._id,
                        dependencies: ai.dependencies,
                    },
                })
            }
        })

        this.setState(
            {
                missions: missions,
            },
            () => {
                if (cb) cb(missions[indexes.missionIndex])
                this.setState(
                    {
                        missionChange: {
                            for: "actionItem",
                            change: "delete",
                            timestamp: uniqid(),
                            obj: obj,
                            missionId: obj.missionId,
                        },
                    },
                    () => {
                        if (bulkChanges.lenth) {
                            this.updateStateForActionItemUpdateBulk(missions[indexes.missionIndex]._id, bulkChanges)
                        }
                        const m = missions[indexes.missionIndex]
                        if (m.isProcess && m.isTemplate) {
                            setTimeout(() => {
                                //syncProcessFlow({ mission: m, app: this })
                            }, 500)
                        }
                    }
                )
            }
        )
    }
    updateStateForMissionPeopleChange(missionIndex, changeObj) {
        const mission = this.state.missions[missionIndex]
        let planItems = mission.planItems.slice()

        let changedPeopleArray = changeObj.change.people?.map ? changeObj.change.people : [changeObj.change.people]

        if (
            mission.projectType === "power" &&
            mission.people &&
            changeObj.doc.people &&
            mission.people.length > changeObj.doc.people.length
        ) {
            const whoGotKickedOut = differenceBy(mission.people, changeObj.doc.people, "_id")

            whoGotKickedOut.forEach((ko, i) => {
                const existsInPlanItemIndex = planItems.findIndex((pik) => pik.person === getRefId(ko))
                if (existsInPlanItemIndex !== -1) {
                    planItems[existsInPlanItemIndex] = null
                }
            })
        }

        let newMissions = update(this.state.missions, {
            [missionIndex]: {
                peopleIds: {
                    $set: changeObj.doc.peopleIds || changeObj.change.peopleIds,
                },
                planItems: {
                    $set: planItems,
                },
                people: {
                    $set: (changeObj.doc.people || changedPeopleArray).map((item) => {
                        const statePerson = (get(this.state.missions[missionIndex], "people") || []).find(
                            (person) => person._id === item._id
                        )
                        return {
                            ...item,
                            ref: get(statePerson, "ref") || item.ref,
                        }
                    }),
                },
            },
        })

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "mission",
                        change: "people",
                        timestamp: uniqid(),
                        missionId: mission._id,
                    },
                })
            }
        )
    }
    updateStateForMissionCreate(mission, cb) {
        const me = this

        let newMissions = me.state.missions.slice()
        newMissions.push(mission)

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "mission",
                        change: "create",
                        timestamp: uniqid(),
                        obj: mission,
                        missionId: mission._id,
                    },
                })

                if (cb) cb()
            }
        )
    }
    updateStateForMissionUpdate({ mission, changes }) {
        this.missionUpdateState(mission._id, changes)

        /*let newMissions = this.state.missions.slice()
        let mIndex = newMissions.findIndex((m) => m._id === mission._id)

        newMissions[mIndex] = { ...mission, ...changes }

        this.setState(
            {
                missions: newMissions,
            },
            () => {
                this.setState({
                    missionChange: {
                        for: "mission",
                        change: "update",
                        timestamp: uniqid(),
                        obj: mission,
                        missionId: mission._id,
                    },
                })
            }
        )*/
    }
    updateStateForPlanItemCreateBulk(missionId, planItems) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === missionId)

        if (missionIndex === -1) {
            return
        }

        let newPlanItems = [...this.state.missions[missionIndex].planItems, ...planItems]

        this.setState({
            missions: update(this.state.missions, {
                [missionIndex]: {
                    planItems: {
                        $set: newPlanItems,
                    },
                },
            }),
        })
    }
    updateStateForPlanItemCreate(obj, cb) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === obj.missionId)

        if (missionIndex === -1) {
            return
        }

        let newState = {
            missions: update(this.state.missions, {
                [missionIndex]: {
                    planItems: {
                        $push: [obj],
                    },
                },
            }),
        }

        //Set and do
        this.setState(newState, () => {
            this.setState({
                missionChange: {
                    for: "planItem",
                    change: "create",
                    timestamp: uniqid(),
                    obj: obj,
                    missionId: obj.missionId,
                },
            })

            if (cb) cb()
        })
    }
    updateStateForPlanItemUpdateBulk(changes, cb, fastCallBack) {
        let newStateObj = {}

        if (!changes || changes.length === 0) {
            return
        }

        const obj = changes[0].obj

        const missionIndex = this.state.missions.findIndex((m) => m._id === obj.missionId)

        if (missionIndex === -1) return

        let planItems = []

        let newMissions = this.state.missions.slice()

        this.state.missions[missionIndex].planItems.forEach((pi, i) => {
            const fp = changes.find((c) => c.obj._id === pi._id)

            if (fp) {
                planItems.push({ ...pi, ...fp.change })
            } else {
                planItems.push(pi)
            }
        })

        newMissions[missionIndex].planItems = planItems
        newStateObj.missions = newMissions

        if (fastCallBack) {
            fastCallBack(newMissions[missionIndex])
        }

        newStateObj.missionChange = {
            for: "planItem",
            planItems: planItems,
            changes: changes,
            timestamp: uniqid(),
            missionId: this.state.missions[missionIndex]._id,
        }

        this.setState(newStateObj, () => {
            if (cb) cb()
        })
    }
    updateStateForPlanItemUpdate(obj, change, cb) {
        let newStateObj = {}

        if (!obj || !change) {
            return
        }

        if (this.state.phaseObj && this.state.phaseObj._id === obj._id) {
            newStateObj.phaseObj = { ...this.state.phaseObj, ...change }
        }

        const missionIndex = this.state.missions.findIndex((m) => m._id === obj.missionId)

        if (missionIndex === -1) return

        const planItemIndex = this.state.missions[missionIndex].planItems.findIndex((p) => p._id === obj._id)

        if (planItemIndex === -1) return

        newStateObj.missions = update(this.state.missions, {
            [missionIndex]: {
                planItems: {
                    [planItemIndex]: { $merge: change },
                },
            },
        })

        this.setState(newStateObj, () => {
            if (this.state.missions[missionIndex].org) {
                this.setState({
                    missionChange: {
                        for: "planItem",
                        obj: obj,
                        change: change,
                        timestamp: uniqid(),
                        missionId: this.state.missions[missionIndex]._id,
                    },
                    orgChange: {
                        for: "mission",
                        timestamp: uniqid(),
                        missionId: this.state.missions[missionIndex]._id,
                    },
                })
            }

            if (cb) cb({ ...obj, ...change })
        })
    }
    updateStateForPlanItemRemoveRolesBulk(items) {
        let newMissions = this.state.missions.slice()
        items.forEach((pi, i) => {
            const missionIndex = this.state.missions.findIndex((m) => m._id === pi.missionId)
            if (missionIndex === -1) return
            const missionPlanIndex = this.state.missions[missionIndex].planItems.findIndex((p) => p._id === pi._id)
            if (missionPlanIndex === -1) return

            newMissions[missionIndex].planItems.splice(missionPlanIndex, 1)
        })

        this.setState({
            missions: newMissions,
        })
    }
    updateStateForPlanItemRemove(obj) {
        const missionIndex = this.state.missions.findIndex((m) => m._id === obj.missionId)

        if (missionIndex === -1) return

        const missionPlanIndex = this.state.missions[missionIndex].planItems.findIndex((m) => m._id === obj._id)

        if (missionPlanIndex === -1) return

        this.setState(
            {
                missions: update(this.state.missions, {
                    [missionIndex]: { planItems: { $splice: [[missionPlanIndex, 1]] } },
                }),
                missionChange: {
                    for: "planItem",
                    change: "delete",
                    timestamp: uniqid(),
                    obj: obj,
                    missionId: obj.missionId,
                },
            },
            () => {
                setTimeout(() => {
                    try {
                        let mission = this.state.missions[missionIndex]
                        if (mission && mission.org) {
                            this.setState({
                                orgChange: {
                                    for: "mission",
                                    timestamp: uniqid(),
                                },
                            })
                        }
                    } catch (e) {}
                }, 400)
            }
        )
    }

    async changeListener() {
        const me = this

        apiOrg.onChanges("delete", (changes) => {
            changes.forEach((changeObj) => {
                if (changeObj.byRequestId !== getUserRequestId()) {
                    this.orgCleanUpDeleted(changeObj._id)
                }
            })
        })
        apiOrg.onChanges("update", (changes) => {
            const orgs = this.state.orgs
            changes.forEach((changeObj) => {
                const changedOrgIndex = (orgs || []).findIndex((o) => o._id === changeObj._id)

                if (changedOrgIndex === -1) return

                if (changeObj.change.people) {
                    this.setState(
                        {
                            orgs: update(orgs, {
                                [changedOrgIndex]: {
                                    people: {
                                        $set: changeObj.change.people.map((item) => {
                                            const statePerson = (get(orgs[changedOrgIndex], "people") || []).find(
                                                (person) => person._id === item._id
                                            )
                                            return {
                                                ...item,
                                                ref: get(statePerson, "ref") || item.ref,
                                            }
                                        }),
                                    },
                                },
                            }),
                        },
                        () => {
                            this.setState({
                                orgChange: {
                                    for: "people",
                                    timestamp: uniqid(),
                                },
                            })
                        }
                    )
                } else if (changeObj.byRequestId !== getUserRequestId() || changeObj.change.programs) {
                    this.orgUpdateState({ _id: changeObj._id }, changeObj.change)
                }
            })
        })

        apiOrg.onPeopleChanges("create", (changeList) => {
            const orgList = this.state.orgs

            changeList.forEach((changeItem) => {
                const changedOrgIndex = (orgList || []).findIndex((org) => org._id === changeItem.orgId)

                if (changedOrgIndex === -1) {
                    return
                }

                if (!changeItem?.doc) {
                    return
                }

                try {
                    this.setState(
                        {
                            orgs: update(orgList, {
                                [changedOrgIndex]: {
                                    people: {
                                        $push: [changeItem.doc],
                                    },
                                },
                            }),
                        },
                        () => {
                            this.setState({
                                orgChange: {
                                    for: "people",
                                    timestamp: uniqid(),
                                },
                            })
                        }
                    )
                } catch (e) {
                    const y = changeItem
                } finally {
                }
            })
        })

        apiOrg.onPeopleChanges("update", (changeList) => {
            const orgList = this.state.orgs

            changeList.forEach((changeItem) => {
                const changedOrgIndex = (orgList || []).findIndex((org) => org._id === changeItem.orgId)

                if (changedOrgIndex === -1 || !changeItem.doc) {
                    return
                }

                const org = orgList[changedOrgIndex]
                const orgPersonIndex =
                    org?.people?.findIndex((person) => getObjectId(person.ref) === getObjectId(changeItem.doc?.ref)) ??
                    -1

                if (orgPersonIndex === -1) {
                    return
                }

                const newOrgData = update(orgList, {
                    [changedOrgIndex]: {
                        people: {
                            [orgPersonIndex]: {
                                $set: changeItem.doc,
                            },
                        },
                    },
                })

                this.setState(
                    {
                        orgs: newOrgData,
                    },
                    () => {
                        this.setState({
                            orgChange: {
                                for: "people",
                                timestamp: uniqid(),
                            },
                        })
                    }
                )
            })
        })

        //

        apiOrg.onPeopleChanges("delete", (changeList) => {
            const orgList = this.state.orgs

            changeList.forEach((changeItem) => {
                const changedOrgIndex = (orgList || []).findIndex((org) => org._id === changeItem.orgId)

                if (changedOrgIndex === -1) {
                    return
                }

                if (changeItem.ref === this.state.person._id) {
                    // I got kicked out of this org

                    const missionsToClean = this.state.missions.filter((m) => getObjectId(m.org) === changeItem.orgId)

                    missionsToClean.forEach((m) => {
                        this.missionCleanUpDeleted(m._id, this.state.person._id)
                    })

                    this.orgCleanUpDeleted(changeItem.orgId)
                } else {
                    const org = orgList[changedOrgIndex]
                    this.setState(
                        {
                            orgs: update(orgList, {
                                [changedOrgIndex]: {
                                    people: {
                                        $set:
                                            org.people?.filter(
                                                (person) => getObjectId(person.ref) !== changeItem._id
                                            ) ?? [],
                                    },
                                },
                            }),
                        },
                        () => {
                            this.setState({
                                orgChange: {
                                    for: "people",
                                    timestamp: uniqid(),
                                },
                            })
                        }
                    )
                }
            })
        })

        apiMission.onChanges("rebuild", (changes) => {
            this.missionGetTree(changes.map((item) => item._id)).catch((err) => {
                if (!toast.isActive("rebildKiller"))
                    toast.warn(`New updates detected. Please refresh for latest changes.`, {
                        toastId: "rebildKiller",
                    })
            })
        })

        apiMission.onChanges("update", (changes) => {
            changes.forEach((changeObj) => {
                if (changeObj.change.people) {
                    if (changeObj.change.people && changeObj.change.people.length === 0) {
                        me.missionCleanUpDeleted(changeObj._id, changeObj.by)
                        return
                    }

                    const missionIndex = this.state.missions.findIndex((m) => m._id === changeObj._id)

                    if (missionIndex === -1) return

                    this.updateStateForMissionPeopleChange(missionIndex, changeObj)
                } else if (changeObj.byRequestId !== getUserRequestId() || changeObj.change.files) {
                    const missionIndex2 = me.state.missions.findIndex((m) => m._id === changeObj.doc._id)

                    if (missionIndex2 === -1) return

                    this.updateStateForMissionUpdate({
                        mission: this.state.missions[missionIndex2],
                        changes: changeObj.change,
                    })
                }
            })
        })

        apiMission.onChanges("delete", (changes) => {
            changes.forEach((changeObj) => {
                // TODO: @makis Remove from all phases of all missions, all the dependencies that have linkToMission._id === changeObj._id

                if (changeObj.byRequestId !== getUserRequestId()) {
                    const stateMission = this.state.missions.find((m) => m._id === changeObj.doc?._id)

                    if (stateMission?.people.find((p) => getRefId(p) === this.state.person._id)?.permission < 3) {
                        toast(
                            "FYI. Project: " +
                                changeObj.doc.title +
                                " was just decommissioned. So much for that I guess.",
                            {
                                autoClose: 6000,
                                position: toast.POSITION.BOTTOM_RIGHT,
                            }
                        )
                    }

                    me.missionCleanUpDeleted(changeObj._id, changeObj.by)
                }
            })
        })
        apiMission.onChanges("create", (changes) => {
            changes.forEach((changeObj) => {
                if (changeObj.byRequestId !== getUserRequestId()) {
                    me.updateStateForMissionCreate(changeObj.doc)
                }
            })
        })

        apiPlanItems.onChanges("update", (changes) => {
            changes.forEach((change) => {
                const hasDependencyChanges = !!get(change, "change.dependencies")
                let doc = change.doc

                if (!change.doc) {
                    return // FIXME:
                }

                if (
                    hasDependencyChanges ||
                    change.byRequestId !== getUserRequestId() ||
                    (change?.change?.personLeftRole && change?.change?.person === null)
                ) {
                    me.updateStateForPlanItemUpdate(doc, change.change)
                }
            })
        })

        apiPlanItems.onChanges("create", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    me.updateStateForPlanItemCreate(change.doc)
                }
            })
        })
        apiPlanItems.onChanges("delete", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    me.updateStateForPlanItemRemove(change.doc)
                }
            })
        })

        apiAction.onChanges("create", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    me.updateStateForActionCreate(change.doc)
                }
            })
        })

        apiAction.onChanges("update", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    if (change.doc.actionItems)
                        //@mike weird bug first time sends the doc, second sends a short version
                        me.updateStateForActionUpdate(change.doc, change.change)
                }
            })
        })

        apiAction.onChanges("delete", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId() && change.doc) {
                    me.updateStateForActionDelete(change.doc)
                }
            })
        })

        //Action Items

        apiActionItem.onChanges("create", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    this.updateStateForActionItemCreate(change.doc)
                }
            })
        })

        apiActionItem.onChanges("update", (changes) => {
            changes.forEach((change) => {
                if (!change.doc) {
                    return
                }

                if (change?.change?.dependencies || change.dependencies) {
                    me.updateStateForActionItemUpdate(change.doc, change.change, false, null, true)
                } else if (change.byRequestId !== getUserRequestId() || change.change.dependencies) {
                    me.updateStateForActionItemUpdate(change.doc, change.change, false, null, true)
                }
            })
        })
        apiActionItem.onChanges("delete", (changes) => {
            changes.forEach((change) => {
                if (change.byRequestId !== getUserRequestId()) {
                    me.updateStateForActionItemDelete(change.doc)
                }
            })
        })
        apiNotification.onChanges("create", (changes) => {
            // this.loadLatestNotifications();

            const notificationChanges = changes
                .filter((item) => !this.state.notificationList.some((ni) => ni._id === item._id))
                .map((item) => item.doc)

            try {
                setTimeout(() => {
                    me?.msgBeep?.current?.play()
                }, 1500)
            } catch (e) {
                me.silentFail("me.msgBeep.current")
            }

            me.setState({
                notificationUnread: this.state.notificationUnread + notificationChanges.length,
                notificationList: [...this.state.notificationList, ...notificationChanges],
            })
        })
        apiNotification.onChanges("update", (changes) => {
            const notificationList = this.state.notificationList.slice()
            const updatedNotificationList = notificationList.map((item) => {
                let changedItem = changes.find((change) => item._id === change._id) || {}
                return {
                    ...item,
                    ...pick(changedItem.change, ["isRead", "isDisabled"]),
                }
            })
            const existsUnread = updatedNotificationList.some((item) => item.isRead === false)

            if (!existsUnread) {
                apiNotification.setNotificationUserLastRead().catch(() => {})
            }

            this.setState({
                notificationUnread: existsUnread ? this.state.notificationUnread : 0,
                notificationList: updatedNotificationList,
            })
        })
        apiNotification.onChanges("delete", (changes) => {
            const notificationList = this.state.notificationList.slice()
            const updatedNotificationList = notificationList.filter(
                (item) => !changes.some((change) => item._id === change._id)
            )
            const existsUnread = updatedNotificationList.some((item) => item.isRead === false)

            if (notificationList.length === updatedNotificationList.length) {
                return
            }

            if (!existsUnread) {
                apiNotification.setNotificationUserLastRead().catch(() => {})
            }

            this.setState({
                notificationUnread: existsUnread ? this.state.notificationUnread : 0,
                notificationList: updatedNotificationList,
            })
        })
    }

    onFileDelete(file) {
        if (!file || !file.attachedTo || !file.attachedToId) {
            return
        }

        apiFile
            .deleteFile(file._id)
            .then((res) => {
                if (file.attachedTo === "action.items") {
                    let actionItem = this.state.showDetailsData

                    if (actionItem) {
                        const fileIndex = actionItem.files.findIndex((item) => getObjectId(item) === file._id)

                        if (fileIndex > -1) {
                            let clonedFiles = actionItem.files.slice()
                            clonedFiles.splice(fileIndex, 1)

                            this.updateStateForActionItemUpdate(actionItem, {
                                files: clonedFiles,
                            })
                        }
                    }
                } else {
                    let mission = (this.state.missions || []).find((mi) =>
                        (mi.files || []).some((item) => getObjectId(item) === file._id)
                    )

                    if (mission) {
                        const fileIndex = mission.files.findIndex((item) => getObjectId(item) === file._id)

                        if (fileIndex > -1) {
                            let clonedFiles = mission.files.slice()
                            clonedFiles.splice(fileIndex, 1)

                            this.missionUpdateState(mission._id, {
                                files: clonedFiles,
                            })
                        }
                    }
                }
            })
            .catch((err) => {
                toast.error(err.message)
                this.silentFail("x-23ds4422-" + (err?.message + " - " + err?.toString()))
            })
    }
    showLeaveTracking() {
        if (this.state.orgs.length === 0) return false
        let show = false
        this.state.orgs.forEach((o, i) => {
            if (o.isLicensedActive) {
                show = true
            }
        })

        return show
    }
    showExpenseTracking() {
        if (this.state.orgs.length === 0) return false
        let show = false
        this.state.orgs.forEach((o, i) => {
            if (o.isLicensedActive) {
                show = true
            }
        })

        return show
    }
    getActiveMission(activeMission) {
        let missionTag

        if (!activeMission) return null

        if (activeMission && activeMission.whoCanInvite === undefined) {
            this.missionGetTree(activeMission._id)
            return (
                <div className="dna-col" style={{ paddingTop: 150 }}>
                    <p className="dna-label">Receiving mission data</p>
                    <br />
                    <br />
                    <BarLoader loading color={"#b3f542"} />
                </div>
            )
        }

        const commonPropsToPass = {
            key: activeMission._id,
            app: this,
            mission: activeMission,
            stateChange: this.state.missionChange,
        }

        if (activeMission.projectType === "simple" || activeMission.projectType === "kanban") {
            missionTag = (
                <ErrorBoundary app={this}>
                    <SimplyX {...commonPropsToPass} />
                </ErrorBoundary>
            )
        } else if (activeMission.projectType === "power") {
            missionTag = (
                <ErrorBoundary app={this}>
                    <Power {...commonPropsToPass} tabId={this.state.powerTab} />
                </ErrorBoundary>
            )
        } else if (
            activeMission.projectType === "mx-gantt" ||
            activeMission.projectType === "classic-gantt" ||
            activeMission.projectType === "gantt"
        ) {
            missionTag = (
                <ErrorBoundary app={this}>
                    <MxGanttMission {...commonPropsToPass} />
                </ErrorBoundary>
            )
        }

        return missionTag
    }
    confirm({
        comp,
        onYes,
        onNo,
        onCancel,
        noCancel,
        width,
        yesText,
        yesColor,
        singleClick,
        noText,
        noMint,
        severe,
        className,
    }) {
        //props = {comp, onYes, onNo, onCancel}

        const onCancelMe = () => {
            this.setState({
                confirm: null,
            })

            if (onCancel) {
                onCancel()
            }
        }
        const onYesMe = () => {
            if (onYes) {
                let ok = onYes()

                if (ok !== false)
                    this.setState({
                        confirm: null,
                    })
            }
        }
        const onNoMe = () => {
            this.setState({
                confirm: null,
            })
            if (onNo) {
                onNo()
            }
        }

        this.setState({
            confirm: {
                onCancel: onCancelMe,
                onYes: onYesMe,
                noCancel,
                width: width,
                onNo: onNo ? onNoMe : undefined,
                comp: comp,
                yesText: yesText,
                yesColor: yesColor,
                singleClick: singleClick,
                severe: severe,
                noText: noText,
                className,
                noMint,
            },
        })
    }
    msg(msg) {
        this.setState({
            msg: msg,
        })
    }

    render() {
        const theEaglesHaveLanded =
            !this.state.person?._id ||
            (this.state.theMissionsHaveLanded && this.state.person?._id && this.state.theNotificationsHaveLanded)

        if (!theEaglesHaveLanded) {
            return (
                <div className="dna-col dna-main-loading-data">
                    <BarLoader color={"#b3f542"} loading={true} />
                </div>
            )
        }

        const state = this.state

        let activeMission = state.missions.find((m) => m._id === state.mission)

        if (state.missionIndex >= 0 && !activeMission) {
            activeMission = this.state.missions?.find((m) => m?._id === state.mission)

            if (!activeMission) {
                const u = new URLSearchParams(window.location.search)
                if (u.has("mission")) {
                    removeParam("mission")
                    toast.warning(
                        "Hi, I did not find a project that was requested. Perhaps it is no longer active or please try refreshing the browser and contact us at help@missionx.ai",
                        {
                            autoClose: 8000,
                        }
                    )
                }
            }
        }

        const cn = cx("dna", {
            "dna-show-home": this.state.home,
            "dna-show-board": this.state.board,
            "dna-not-base": this.state.mission || this.state.phase || this.state.org,
            "dna-no-me-avatar":
                this.state.myspace ||
                this.state.login ||
                this.state.orgCrud ||
                this.state.missionCrud ||
                this.state.createProposal ||
                this.state.createMission ||
                this.state.action,

            "dna-no-ai": this.state.myspace,
            "dna-tone-down": this.state.waiting,
            "dna-mute-phase": this.state.action,
            "dna-mission-landing": this.state.theMissionsHaveLanded,
            "dna-show-control-panel": this.state.controlPanel,
            "dna-show-notifications-panel": this.state.notificationOpen,
            "dna-show-msg": this.state.msg,
        })

        const orgDataToShow = Boolean(this.state.orgIndex !== undefined && this.state.org)
            ? this.state.orgs.find((o) => getObjectId(o) === this.state.org)
            : null

        return (
            <>
                <div key={this.state.avKey} className={cn}>
                    <ActionItem
                        ai={this.state.showDetailsData}
                        options={this.state.showDetailsDataOptions}
                        app={this}
                        onClose={() => {
                            removeParam("action-item")
                        }}
                        open={Boolean(this.state.showDetailsData)}
                        stateChange={this.state.missionChange}
                    />

                    {/* ++++++++ FILE VIEWER */}
                    {!!state.fileSelected && (
                        <Drive
                            onClose={() =>
                                this.setState({
                                    fileSelected: null,
                                    fileSelectedList: null,
                                })
                            }
                            onFileDelete={(file) => this.onFileDelete(file)}
                            file={state.fileSelected}
                            fileList={state.fileSelectedList}
                            app={this}
                        />
                    )}

                    {/* +++++++++ LOGIN */}
                    <FullScreen
                        param="login"
                        className="dna-login-screen"
                        closeClass="dna-dark"
                        style={{ zIndex: 9 }}
                        open={state.login}
                        onClose={() => {
                            setTimeout(() => {
                                if (!this.state.person._id) {
                                    window.location.href = "https://www.missionx.ai/" + window.location.search
                                }
                            }, 0)
                        }}
                    >
                        <Login app={this} reset={state.resetLoginStates} />
                    </FullScreen>
                    {(this.state.showMySpace || this.state.showAll) && (
                        <FullScreen
                            param="myspace"
                            style={{ zIndex: this.state.myspace ? 99 : 5 }}
                            className="myspace-fs"
                            open={state.myspace}
                        >
                            <ErrorBoundary app={this}>
                                <MySpace
                                    app={this}
                                    person={this.state.person}
                                    profileUpdateState={(change) => this.profileUpdateState(change)}
                                />
                            </ErrorBoundary>
                        </FullScreen>
                    )}
                    {/*  ++++++++ HOME & BOARD */}
                    {!this.state.mission &&
                        this.state.person?._id &&
                        !this.state.org &&
                        !this.state.myspace &&
                        this.state.person.firstName &&
                        this.state.person.lastName && (
                            <ErrorBoundary app={this}>
                                <Home
                                    notificationList={this.state.notificationList}
                                    app={this}
                                    theMissionsHaveLanded={this.state.theMissionsHaveLanded}
                                    missionChange={this.state.missionChange}
                                />
                            </ErrorBoundary>
                        )}

                    {this.state.board && !this.state.org && !this.state.mission && (
                        <ErrorBoundary app={this}>
                            <Board app={this} stateChange={this.state.missionChange} />
                        </ErrorBoundary>
                    )}

                    {/*  ++++++++ CRUD MISSION */}
                    {this.state.showAll && this.state.theMissionsHaveLanded && (
                        <>
                            <FullScreen
                                key="p-crud"
                                param="create-proposal"
                                style={{ zIndex: 8 }}
                                open={state.createProposal}
                            >
                                {state.createProposal && (
                                    <ErrorBoundary app={this}>
                                        <CreateBid
                                            app={this}
                                            onClose={() => {
                                                this.setState({
                                                    proposalCreate: false,
                                                })
                                            }}
                                        />
                                    </ErrorBoundary>
                                )}
                            </FullScreen>
                            <FullScreen
                                param="mc,mc-org,mc-program,create-mission,mc-org-goal,mc-prop,create-proposal,mc-departments,mc-offices,mc-client"
                                style={{ zIndex: 120 }}
                                open={state.createMission}
                            >
                                {state.createMission && (
                                    <ErrorBoundary app={this}>
                                        <CreateMission
                                            app={this}
                                            onClose={() => {
                                                this.setState({
                                                    createMission: false,
                                                })
                                            }}
                                        />
                                    </ErrorBoundary>
                                )}
                            </FullScreen>
                            <FullScreen
                                style={{ zIndex: 999999 }}
                                open={state.createTemplate}
                                onClose={() => {
                                    this.setState({
                                        createTemplate: false,
                                    })
                                }}
                            >
                                {state.createTemplate && (
                                    <ErrorBoundary app={this}>
                                        <CreateTemplate
                                            app={this}
                                            onClose={() => {
                                                this.setState({
                                                    createTemplate: false,
                                                })
                                            }}
                                        />
                                    </ErrorBoundary>
                                )}
                            </FullScreen>
                            <FullScreen key="crud" param="mc,mc-org" style={{ zIndex: 8 }} open={state.missionCrud}>
                                <ErrorBoundary app={this}>
                                    <CrudMission
                                        app={this}
                                        data={
                                            activeMission ||
                                            this.state.missions.find(
                                                (m) => m._id === new URLSearchParams(window.location.search).get("mc")
                                            )
                                        }
                                        missionUpdateState={this.missionUpdateState.bind(this)}
                                        onClose={() => {
                                            this.setState({
                                                missionCrud: false,
                                            })
                                        }}
                                    />
                                </ErrorBoundary>
                            </FullScreen>
                        </>
                    )}
                    {/*  ++++++++ ACTION */}
                    {this.state.showAll && (
                        <FullScreen
                            param="action,action-item,actionObj"
                            open={state.action}
                            className="no-blur"
                            style={{
                                zIndex: 21,
                            }}
                        >
                            {state.action && state.person._id && (
                                <ErrorBoundary app={this}>
                                    <Action
                                        app={this}
                                        mission={activeMission}
                                        action={this.state.actionObj}
                                        stateChange={this.state.missionChange}
                                    />
                                </ErrorBoundary>
                            )}
                        </FullScreen>
                    )}
                    {/*  ++++++++ PHASE */}
                    {this.state.showAll && (
                        <FullScreen
                            param="phase,phaseObj"
                            style={{ zIndex: 7 }}
                            className="fast-transition"
                            open={state.phaseObj}
                            portal
                        >
                            <ErrorBoundary app={this}>
                                {this.state.phase && activeMission && (
                                    <Phase
                                        app={this}
                                        phase={activeMission?.planItems.find((p) => p._id === this.state.phase)}
                                        mission={activeMission}
                                        stateChange={this.state.missionChange}
                                    />
                                )}
                            </ErrorBoundary>
                        </FullScreen>
                    )}
                    {/*  ++++++++ MISSION */}
                    <FullScreen
                        param="mission,mission-tab,make-week,make-phase,action,phase,mission-timeline,budget-bar,powerTab,make-week"
                        style={{ zIndex: 6 }}
                        open={activeMission}
                    >
                        <React.Fragment key={activeMission?._id}>
                            {activeMission && this.getActiveMission(activeMission)}
                        </React.Fragment>
                    </FullScreen>
                    {activeMission && <LicenseChecker mission={activeMission} app={this} />}
                    {
                        <Card
                            closeButton
                            className="dna-center-card"
                            modal
                            modalIndex={998}
                            style={{ zIndex: 9999, maxWidth: 600 }}
                            open={Boolean(this.state.missionSetupBudget)}
                            onClose={() => {
                                this.setState({
                                    missionSetupBudget: null,
                                })
                            }}
                        >
                            {Boolean(this.state.missionSetupBudget) && (
                                <BudgetSetup {...this.state.missionSetupBudget} app={this} />
                            )}
                        </Card>
                    }
                    {/*  ++++++++ ORG */}
                    {(this.state.showAll || this.state.org) && (
                        <FullScreen
                            param="org,org-tab,org-goal,mc-prop,org-cfg,oc-domains,invoice,appsumo-email,otPeriodEnd,otPeriodStart,otPeriodType"
                            style={{ zIndex: 3 }}
                            open={Boolean(orgDataToShow)}
                            closeClass="org-close-x"
                            closeTip={"Exit organization"}
                        >
                            {theEaglesHaveLanded && !this.state.mission && (
                                <ErrorBoundary app={this}>
                                    <Org
                                        key={orgDataToShow?._id}
                                        orgUpdateState={this.orgUpdateState.bind(this)}
                                        missionChange={this.state.missionChange}
                                        orgData={orgDataToShow}
                                        orgChange={this.state.orgChange}
                                        app={this}
                                    />
                                </ErrorBoundary>
                            )}
                        </FullScreen>
                    )}
                    {/* +++++++++ CRUD ORG */}
                    <FullScreen param="hq" style={{ zIndex: 10 }} open={this.state.orgCrud}>
                        {this.state.orgCrud && (
                            <ErrorBoundary app={this}>
                                <CrudOrg
                                    app={this}
                                    data={state.orgs[state.orgIndex]}
                                    onClose={() => {
                                        this.setState({
                                            orgCrud: false,
                                        })
                                    }}
                                />
                            </ErrorBoundary>
                        )}
                    </FullScreen>
                    <BudgetPro
                        app={this}
                        flashRowId={this.state.budgetProMission?.flashRowId}
                        dontShowAppToolsOnExit={this.state.budgetProMission?.dontShowAppToolsOnExit}
                        open={Boolean(this.state.budgetProMission)}
                        mission={
                            this.state.budgetProMission
                                ? this.state.missions.find((m) => m._id === this.state.budgetProMission?.mission?._id)
                                : undefined
                        }
                        timesheets={this.state.budgetProMission?.timesheets?.filter(
                            (t) => t.snapshot?.mission?._id === this.state.budgetProMission?.mission?._id
                        )}
                        startTab={this.state.budgetProMission?.budgetProStartTab}
                        stateChange={this.state.missionChange}
                        onClose={() => {
                            if (this.state.budgetProMission?.onClose) this.state.budgetProMission.onClose()
                            this.setState({
                                budgetProMission: undefined,
                            })
                        }}
                        fetchTimesheets={true}
                    />
                    {/* ====== Promos ============= */}
                    <Card
                        onClose={() => {
                            removeParams("from,oobCode,appsumo-email")
                            this.setState({
                                appsumo: false,
                                appSumoOobCode: null,
                            })
                        }}
                        closeButton
                        modal
                        className="dna-center-card"
                        style={{ zIndex: 9999999, maxWidth: this.state.orgs.length > 0 ? 620 : 550 }}
                        open={Boolean(this.state.appsumo && !this.state.myspace)}
                    >
                        {this.state.appsumo && (
                            <ErrorBoundary app={this}>
                                <AppSumoFlow
                                    app={this}
                                    planDetails={this.state.appsumo}
                                    oobCode={this.state.appSumoOobCode}
                                    onClose={() => {
                                        this.setState({
                                            appsumo: null,
                                            appSumoOobCode: null,
                                        })
                                        removeParams("from,oobCode,appsumo-email,email")
                                    }}
                                />
                            </ErrorBoundary>
                        )}
                    </Card>
                    <ToastContainer
                        closeButton={CloseToast}
                        autoClose={3000}
                        className="toast-the-dna no-blur"
                        position={toast.POSITION.TOP_CENTER}
                    />
                    <Confirm open={this.state.confirm} {...this.state.confirm} />

                    {theEaglesHaveLanded && (
                        <ErrorBoundary app={this}>
                            <PanelNotification
                                open={this.state.notificationOpen && this.state.person && this.state.person._id}
                                onClose={() => {
                                    this.setState({
                                        notificationOpen: false,
                                    })
                                }}
                                app={this}
                                notifications={this.state.notificationList}
                            />
                        </ErrorBoundary>
                    )}

                    {/*Knobs*/}
                    {this.state.person._id &&
                        this.state.person.avatar &&
                        this.state.person.firstName &&
                        this.state.theMissionsHaveLanded &&
                        !this.state.missionCrud &&
                        !this.state.createProposal &&
                        !this.state.createMission &&
                        !this.state.orgCrud &&
                        !this.state.hideDamKnob && (
                            <>
                                <Navigator app={this} open={this.state.controlPanel} activeMission={activeMission} />
                            </>
                        )}

                    <span
                        className={cx({
                            "dna-dark":
                                darkThemes.includes(this.state.person.theme) &&
                                this.state.home &&
                                !this.state.timeTracking &&
                                !this.state.mission &&
                                !this.state.org &&
                                !this.state.myspace,
                        })}
                    >
                        {/* ++++++++ MY AVATAR */}
                        {state.person?._id && (
                            <>
                                {this.state.theMissionsHaveLanded &&
                                    !this.state.missionCrud &&
                                    !this.state.createProposal &&
                                    !this.state.createMission &&
                                    !this.state.orgCrud &&
                                    !this.state.homeCalendar &&
                                    !this.state.timeTracking &&
                                    !this.state.mission &&
                                    !this.state.action &&
                                    !this.state.hideExtras && (
                                        <>
                                            <div
                                                className="dna-cal-trigger"
                                                onClick={() => {
                                                    this.setState({
                                                        homeCalendar: true,
                                                    })
                                                }}
                                            >
                                                <Tip title="Your assignment calendar">
                                                    <BsCalendar4Week fontSize={19} />
                                                </Tip>
                                            </div>
                                        </>
                                    )}

                                {!this.state.homeCalendar && !this.state.timeTracking && !this.state.hideAvatar && (
                                    <>
                                        <div
                                            className="dna-me-avatar"
                                            onMouseOver={() => MySpace.preload()}
                                            onClick={() => {
                                                removeParams("phase,search-mode")
                                                addParam("myspace", "open")
                                            }}
                                            style={{ backgroundImage: `url(${this.state.person.avatar})` }}
                                        />
                                    </>
                                )}

                                {(this.state.person?.email === "mike@missionx.ai" ||
                                    this.state.person?.email === "makis@missionx.ai") &&
                                    false && (
                                        <FullScreen
                                            style={{ zIndex: 99999 }}
                                            open={this.state.mxAdmin}
                                            onClose={() =>
                                                this.setState({
                                                    mxAdmin: false,
                                                })
                                            }
                                        >
                                            <MxAdmin />
                                        </FullScreen>
                                    )}

                                {this.state.theMissionsHaveLanded &&
                                    !this.state.homeCalendar &&
                                    !this.state.hideExtras &&
                                    !this.state.hideAdminTools && (
                                        <Tip
                                            className="dna-speed-trigger"
                                            position="left"
                                            interactive
                                            html={
                                                <>
                                                    <p>My timesheets 🍸</p>
                                                    {!this.state.hasTimeTracking && (
                                                        <>
                                                            <p>
                                                                Requires an{" "}
                                                                <span
                                                                    className="dna-cursor dna-orange-text"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/pricing/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    Unlimited subscription
                                                                </span>{" "}
                                                                for one of your organizations
                                                            </p>
                                                            <p>
                                                                Learn more about{" "}
                                                                <span
                                                                    className="dna-cursor dna-mint"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/guide/time-tracking/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    time tracking
                                                                </span>
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <div
                                                key={this.state.missingTimesheets?.length}
                                                onClick={() => {
                                                    if (this.state.hasTimeTracking) {
                                                        addParams([
                                                            { param: "time-tracking", value: "open" },
                                                            {
                                                                param: "week",
                                                                value: startOfISOWeek(new Date()).getTime(),
                                                            },
                                                        ])
                                                    } else {
                                                        toast(
                                                            "There are no eligible organizations for Time Tracking. 🍸",
                                                            {
                                                                autoClose: 10000,
                                                            }
                                                        )
                                                    }
                                                }}
                                            >
                                                <Badge
                                                    badgeContent={this.state.missingTimesheets || null}
                                                    color="secondary"
                                                >
                                                    <IconSpeed />
                                                </Badge>
                                            </div>
                                        </Tip>
                                    )}
                            </>
                        )}

                        {this.state.theMissionsHaveLanded &&
                            !this.state.timeTracking &&
                            !this.state.homeCalendar &&
                            !this.state.hideExtras &&
                            !this.state.hideAdminTools && (
                                <>
                                    <div
                                        className="dna-leave-trigger"
                                        onClick={() => {
                                            if (!this.showLeaveTracking()) {
                                                toast(
                                                    "Requires an organization with an Unlimited subscription. Start a free trial to test it out 🌻",
                                                    {
                                                        autoClose: 10000,
                                                    }
                                                )
                                                return
                                            }
                                            this.setState({
                                                showLeaveTracker: true,
                                            })
                                        }}
                                    >
                                        <Tip
                                            position="left"
                                            interactive
                                            html={
                                                <>
                                                    <p>My leave requests 🌻</p>
                                                    {!this.showLeaveTracking() && (
                                                        <>
                                                            <p>
                                                                Requires an{" "}
                                                                <span
                                                                    className="dna-cursor dna-orange-text"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/pricing/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    Unlimited subscription
                                                                </span>{" "}
                                                                for one of your organizations
                                                            </p>
                                                            <p>
                                                                Learn more about{" "}
                                                                <span
                                                                    className="dna-cursor dna-mint"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/guide/leave-tracking/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    leave tracking
                                                                </span>
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <IconLeave width={25} />
                                        </Tip>
                                    </div>
                                </>
                            )}

                        {this.state.theMissionsHaveLanded &&
                            !this.state.timeTracking &&
                            !this.state.homeCalendar &&
                            !this.state.hideExtras &&
                            !this.state.hideAdminTools && (
                                <>
                                    <div
                                        className="x-expense-trigger"
                                        onClick={() => {
                                            if (!this.showExpenseTracking()) {
                                                toast(
                                                    "Requires an organization with an Unlimited subscription. Start a free trial to test it out 🌻",
                                                    {
                                                        autoClose: 10000,
                                                    }
                                                )
                                                return
                                            }
                                            this.hideAppElements()
                                            this.setState({
                                                showExpenses: true,
                                            })
                                        }}
                                    >
                                        <Tip
                                            interactive
                                            position="left"
                                            html={
                                                <>
                                                    <p>My expenses. Ka-ching 💰</p>
                                                    {!this.showExpenseTracking() && (
                                                        <>
                                                            <p>
                                                                Requires an{" "}
                                                                <span
                                                                    className="dna-cursor dna-orange-text"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/pricing/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    Unlimited subscription
                                                                </span>{" "}
                                                                for one of your organizations
                                                            </p>
                                                            <p>
                                                                Learn more about{" "}
                                                                <span
                                                                    className="dna-cursor dna-mint"
                                                                    onClick={() => {
                                                                        window.open(
                                                                            "https://missionx.ai/guide/expenses-online/",
                                                                            "_blank"
                                                                        )
                                                                    }}
                                                                >
                                                                    submitting expenses
                                                                </span>
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            <BsReceipt />
                                        </Tip>
                                    </div>
                                    {this.state.orgs.find((o) => o._id === "63735f0e6494000d61444b4e") && (
                                        <>
                                            <div
                                                className="x-wfh-trigger"
                                                onClick={() => {
                                                    this.setState({
                                                        showWfh: true,
                                                    })
                                                }}
                                            >
                                                <Tip
                                                    interactive
                                                    position="left"
                                                    html={
                                                        <>
                                                            <p>Manage my WFH bookings</p>
                                                        </>
                                                    }
                                                >
                                                    <PiHouseLight width={28} />
                                                </Tip>
                                            </div>

                                            <Card
                                                closeButton
                                                modal
                                                padded
                                                portal
                                                center
                                                style={{ width: "90vw", zIndex: 9999 }}
                                                onClose={() => {
                                                    this.setState({
                                                        showWfh: false,
                                                    })
                                                }}
                                                open={this.state.showWfh}
                                            >
                                                <ErrorBoundary app={this}>
                                                    {this.state.showWfh && <Wfh app={this} />}
                                                </ErrorBoundary>
                                            </Card>
                                        </>
                                    )}
                                </>
                            )}
                    </span>

                    {state.person._id && (
                        <>
                            {this.state.theMissionsHaveLanded && (
                                <FullScreen
                                    param="time-tracking,week"
                                    style={{ zIndex: 1 }}
                                    closeStyle={{ zIndex: 25 }}
                                    open={this.state.timeTracking}
                                >
                                    {this.state.timeTracking && (
                                        <ErrorBoundary app={this}>
                                            <TimeTracking
                                                app={this}
                                                missionChange={this.state.missionChange}
                                                onClose={() => {
                                                    removeParams("time-tracking,week")
                                                }}
                                            />
                                        </ErrorBoundary>
                                    )}
                                </FullScreen>
                            )}

                            <Card
                                closeButton
                                modal
                                style={{ minWidth: 1150, width: 1150, zIndex: 9999 }}
                                onClose={() => {
                                    this.setState({
                                        showLeaveTracker: false,
                                    })
                                }}
                                open={this.state.showLeaveTracker}
                                className="dna-right-card x-lt-card"
                            >
                                <ErrorBoundary app={this}>
                                    {this.state.showLeaveTracker && <LeaveTracker orgs={this.state.orgs} app={this} />}
                                </ErrorBoundary>
                            </Card>

                            <Card
                                closeButton
                                modal
                                modalIndex={99}
                                className="dna-center-card x-expense-tracker-card"
                                onClose={() => {
                                    this.setState({
                                        showExpenses: false,
                                    })
                                    this.showAppElements()
                                }}
                                open={this.state.showExpenses}
                            >
                                <ErrorBoundary app={this}>
                                    {this.state.showExpenses && (
                                        <ExpenseTracker hideExtras={true} app={this} context="personal" />
                                    )}
                                </ErrorBoundary>
                            </Card>
                        </>
                    )}

                    {this.state.syncBlocker && (
                        <div
                            className="dna-dark dna-center courier dna-modal "
                            style={{ pointerEvents: "all", zIndex: 99999999 }}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            Synchronizing your session... one moment please
                        </div>
                    )}
                    <AnimatePresence>
                        {this.state.loadingBlocker && (
                            <motion.div
                                transition={{ duration: 0.2 }}
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="dna-center courier dna-modal dna-main-model"
                                style={{ pointerEvents: "all", zIndex: 99999999 }}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <div className="dna-col">
                                    {typeof this.state.loadingBlockerMessage?.msg === "string" ? (
                                        <p style={{ maxWidth: 800 }} className="dna-sticker-label">
                                            {this.state.loadingBlockerMessage}
                                        </p>
                                    ) : (
                                        this.state.loadingBlockerMessage
                                    )}
                                    <div style={{ marginTop: 10 }}></div>
                                    <BarLoader loading color={"#b3f542"} />
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {/*----- Search ---------- */}
                    <FullScreen
                        open={this.state.homeCalendar && !this.state.mission}
                        closeClass="dna-dark"
                        style={{ zIndex: 2 }}
                        closeStyle={{ right: 30 }}
                        onClose={() => {
                            this.setState({
                                homeCalendar: false,
                            })
                        }}
                    >
                        {this.state.homeCalendar && !this.state.mission && (
                            <ErrorBoundary app={this}>
                                <Calendar app={this} />
                            </ErrorBoundary>
                        )}
                    </FullScreen>

                    <div className="dna-app-msg">{this.state.msg}</div>
                </div>
                {/*
            <ActionItem open={this.state.showDetails} ai={this.state.showDetailsData} app={this} onClose={this.closeDetailsCard.bind(this)} />*/}

                <Card
                    closeButton
                    style={{
                        width: 500,
                        zIndex: 99999,
                        top: "98vh",
                        transform: "translateY(-100%)",
                        right: 20,
                    }}
                    onClose={() => {
                        removeParam("diagnostic")
                    }}
                    open={this.state.error}
                    className="x-error-card dna-dark dna-dark-bg"
                >
                    {this.state.error && (
                        <BlurHandler
                            onBlur={() => {
                                removeParam("diagnostic")
                            }}
                        >
                            <div className="dna-flex">
                                <div className="w-200">
                                    <PiTriangleDuotone color="gold" style={{ transform: "translateY(3px)" }} /> System
                                    issue detected <br />
                                    <img src="/img/astro-error.png" />
                                </div>

                                <div className="w-300">
                                    <p className="dna-smaller-text">
                                        An urgent support ticket has been opened and sent to our engineers address this
                                        issue.
                                    </p>
                                    <p className="dna-smaller-text">
                                        You will be personally updated shortly on progress.
                                    </p>
                                </div>
                            </div>
                        </BlurHandler>
                    )}
                </Card>
                <audio ref={this.msgBeep} style={{ display: "none" }}>
                    <source src="/video/message.mp3" type="audio/mpeg" />
                </audio>
            </>
        )
    }
}

const getActionItemIndexes = (obj, state) => {
    if (!obj) {
        return false
    }

    const isActionItem = obj.actionId
    const theid = isActionItem ? obj.actionId : obj._id

    let missionActionItemIndex

    const missionIndex = state.missions.findIndex((a) => a._id === obj.missionId)

    if (missionIndex === -1) {
        return false
    }

    const missionPhaseIndex = state.missions[missionIndex].planItems.findIndex((a) => a._id === obj.missionPlanId)

    if (missionPhaseIndex === -1) {
        return false
    }
    const missionActionIndex = state.missions[missionIndex].planItems[missionPhaseIndex].actions.findIndex(
        (a) => a._id === theid
    )

    if (missionActionIndex === -1) {
        return false
    }

    if (isActionItem) {
        missionActionItemIndex = state.missions[missionIndex].planItems[missionPhaseIndex].actions[
            missionActionIndex
        ].actionItems.findIndex((a) => a._id === obj._id)
    }

    return {
        missionIndex: missionIndex,
        missionPhaseIndex: missionPhaseIndex,
        missionActionIndex: missionActionIndex,
        missionActionItemIndex: missionActionItemIndex,
    }
}

const CloseToast = ({ closeToast }) => (
    <span className="cursor" onClick={closeToast}>
        <IoClose color="gray" />
    </span>
)

const setCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=missionx.ai;path=/"
}

const ErrorComponent = ({ app }) => {
    throw new Error("error thrown test")
}

App.displayName = "App"

export default App
